import { basicConfig } from '@/configs';

export const MoralisApi = {
  MULTITOKENPRICE: 'https://deep-index.moralis.io/api/v2/erc20/prices',
  //chain=bsc&include=percent_change
};

export const coingecko = {
  STABLECOIN: 'https://api.coingecko.com/api/v3/coins/',
  //
  // STABLECOINLIST: 'https://api.coingecko.com/api/v3/coins/', //
  STABLECOINLIST: 'https://api.coingecko.com/api/v3/coins/list?include_platform=true',
  //
};

export const stableapi = {
  HOST: 'https://stablecoinstats.com/api/',
};

export const icon_api = {
  url: 'https://coinicons-api.vercel.app/',
  list: [
    '$pac',
    '0xbtc',
    '2give',
    '_no_image_',
    'aave',
    'abt',
    'act',
    'actn',
    'ada',
    'add',
    'adx',
    'ae',
    'aeon',
    'aeur',
    'agi',
    'agrs',
    'aion',
    'akro',
    'akt',
    'alend',
    'algo',
    'alink',
    'alpha',
    'amb',
    'amp',
    'ampl',
    'ankr',
    'ant',
    'any',
    'apex',
    'apm',
    'appc',
    'ar',
    'ardr',
    'arg',
    'ark',
    'arn',
    'ary',
    'ast',
    'atm',
    'atom',
    'auc',
    'audio',
    'audr',
    'auto',
    'avax',
    'aywa',
    'bab',
    'bal',
    'band',
    'bat',
    'bay',
    'bcbc',
    'bcc',
    'bcd',
    'bch',
    'bcio',
    'bcn',
    'bco',
    'bcpt',
    'bdl',
    'beam',
    'bel',
    'bela',
    'bitcny',
    'bix',
    'blcn',
    'blk',
    'block',
    'blz',
    'bnb',
    'bns',
    'bnt',
    'bnty',
    'boob',
    'booty',
    'bos',
    'bpt',
    'bq',
    'brain',
    'brd',
    'bsd',
    'bsv',
    'btc++',
    'btc',
    'btcd',
    'btch',
    'btcp',
    'btcz',
    'btdx',
    'btg',
    'btm',
    'bts',
    'btt',
    'btx',
    'burst',
    'bze',
    'bzrx',
    'call',
    'cbat',
    'cc',
    'ccxx',
    'cdai',
    'cdn',
    'cdt',
    'cel',
    'celo',
    'cenz',
    'ceth',
    'chads',
    'chain',
    'chat',
    'chips',
    'chonk',
    'chsb',
    'chz',
    'cix',
    'ck',
    'ckb',
    'clam',
    'cln',
    'cloak',
    'cmm',
    'cmt',
    'cnd',
    'cnx',
    'cny',
    'cob',
    'colx',
    'comp',
    'coqui',
    'core',
    'cow',
    'cream',
    'cred',
    'crep',
    'crgo',
    'crpt',
    'crv',
    'crw',
    'cs',
    'csai',
    'ctr',
    'ctxc',
    'cusdc',
    'cusdt',
    'cvc',
    'cvt',
    'cwbtc',
    'czrx',
    'd',
    'dad',
    'dai',
    'dam',
    'dandy',
    'dash',
    'dat',
    'data',
    'dbc',
    'dcn',
    'dcr',
    'deez',
    'dego',
    'dent',
    'dew',
    'df',
    'dfi',
    'dgb',
    'dgd',
    'dia',
    'divi',
    'dlt',
    'dmg',
    'dnt',
    'dock',
    'doge',
    'dot',
    'dough',
    'dpi',
    'drgn',
    'drop',
    'dta',
    'dth',
    'dtr',
    'dx',
    'dxd',
    'easy',
    'ebst',
    'eca',
    'edg',
    'edo',
    'edoge',
    'egld',
    'ela',
    'elec',
    'elf',
    'elix',
    'ella',
    'emc',
    'emc2',
    'emn',
    'eng',
    'enj',
    'ens',
    'entrp',
    'eon',
    'eop',
    'eos',
    'eosdt',
    'eqli',
    'equa',
    'etc',
    'eth',
    'ethos',
    'etn',
    'etp',
    'eur',
    'eurs',
    'evx',
    'ewt',
    'exmo',
    'exp',
    'fair',
    'farm',
    'fct',
    'fet',
    'fil',
    'fin',
    'fjc',
    'fldc',
    'flm',
    'flo',
    'fls',
    'for',
    'frm',
    'front',
    'fsn',
    'ftc',
    'ftm',
    'ftt',
    'fuel',
    'fun',
    'fxc',
    'game',
    'gas',
    'gbp',
    'gbx',
    'gbyte',
    'gego',
    'gem',
    'generic',
    'gin',
    'glxt',
    'gmr',
    'gno',
    'gnt',
    'gold',
    'grc',
    'grin',
    'grs',
    'gsc',
    'gt',
    'gto',
    'gup',
    'gusd',
    'gvt',
    'gxc',
    'gxs',
    'gzr',
    'hbar',
    'hc',
    'hedg',
    'hex',
    'hget',
    'hight',
    'hive',
    'hns',
    'hnt',
    'hodl',
    'hot',
    'hpb',
    'hsr',
    'ht',
    'html',
    'huc',
    'husd',
    'hush',
    'hxro',
    'hyn',
    'icn',
    'icx',
    'ignis',
    'ilk',
    'index',
    'inj',
    'ink',
    'ins',
    'inxt',
    'ion',
    'iop',
    'iost',
    'iotx',
    'iq',
    'iris',
    'itc',
    'jnt',
    'jpy',
    'jrt',
    'jst',
    'kava',
    'kcs',
    'kin',
    'klown',
    'kmd',
    'knc',
    'krb',
    'ksm',
    'lbc',
    'lend',
    'leo',
    'lid',
    'lien',
    'link',
    'lkk',
    'loom',
    'lpt',
    'lrc',
    'lrn',
    'lsk',
    'ltc',
    'lun',
    'luna',
    'maid',
    'mana',
    'matic',
    'mbc',
    'mcap',
    'mco',
    'mda',
    'mds',
    'med',
    'meetone',
    'meme',
    'met',
    'mft',
    'miota',
    'mith',
    'mkr',
    'mln',
    'mnx',
    'mnz',
    'moac',
    'mod',
    'mona',
    'msr',
    'mta',
    'mth',
    'mtl',
    'mtrg',
    'musd',
    'music',
    'mx',
    'mxc',
    'mzc',
    'nano',
    'nas',
    'nav',
    'ncash',
    'ndz',
    'near',
    'nebl',
    'neo',
    'neos',
    'neu',
    'nex',
    'nexo',
    'nft',
    'ngc',
    'nio',
    'nlc2',
    'nlg',
    'nmc',
    'nmr',
    'npxs',
    'nrg',
    'nuls',
    'nxm',
    'nxs',
    'nxt',
    'oax',
    'ocean',
    'ok',
    'okb',
    'omg',
    'omni',
    'one',
    'ong',
    'ont',
    'oot',
    'opq',
    'orbs',
    'orn',
    'ost',
    'ox',
    'oxt',
    'part',
    'pasc',
    'pasl',
    'pax',
    'paxg',
    'pay',
    'payx',
    'pbtc',
    'pearl',
    'pink',
    'pirl',
    'pivx',
    'plr',
    'plt',
    'pnk',
    'pnt',
    'poa',
    'poe',
    'polis',
    'poly',
    'pot',
    'powr',
    'ppc',
    'ppp',
    'ppt',
    'pre',
    'prl',
    'pungo',
    'pura',
    'qash',
    'qiwi',
    'qlc',
    'qrl',
    'qsp',
    'qtum',
    'r',
    'rads',
    'ramp',
    'rap',
    'rcn',
    'rdd',
    'rdn',
    'ren',
    'renbtc',
    'rep',
    'req',
    'rev',
    'rhoc',
    'ric',
    'rif',
    'rise',
    'rlc',
    'rot',
    'rpx',
    'rsr',
    'rub',
    'rune',
    'rvn',
    'ryo',
    'sada',
    'safe',
    'salt',
    'san',
    'sbch',
    'sbd',
    'sberbank',
    'sbnb',
    'sbtc',
    'sc',
    'scex',
    'sdefi',
    'sero',
    'seth',
    'shift',
    'shr',
    'sib',
    'sin',
    'sky',
    'slp',
    'slr',
    'sls',
    'smart',
    'sngls',
    'snm',
    'snt',
    'snx',
    'soc',
    'sol',
    'solve',
    'spank',
    'sphtx',
    'spore',
    'srm',
    'srn',
    'sss',
    'stak',
    'stake',
    'start',
    'stbz',
    'steem',
    'storj',
    'storm',
    'stq',
    'strat',
    'strx',
    'stx',
    'sub',
    'suku',
    'sumo',
    'sun',
    'susd',
    'sushi',
    'swftc',
    'swrv',
    'swth',
    'sxau',
    'sxmr',
    'sxp',
    'sxtz',
    'sys',
    'taas',
    'tau',
    'tbx',
    'tel',
    'ten',
    'tens',
    'tern',
    'tfuel',
    'tgch',
    'theta',
    'tix',
    'tkn',
    'tks',
    'tmtg',
    'tnb',
    'tnc',
    'tnt',
    'tomo',
    'tpay',
    'trb',
    'trig',
    'trtl',
    'trx',
    'tt',
    'tusd',
    'twt',
    'tzc',
    'ubq',
    'ubt',
    'ufr',
    'uma',
    'uni',
    'unity',
    'uos',
    'uqc',
    'usd',
    'usdc',
    'usdt',
    'utk',
    'value',
    'veri',
    'vet',
    'vgx',
    'via',
    'vib',
    'vibe',
    'vivo',
    'vlx',
    'vrc',
    'vrsc',
    'vsys',
    'vtc',
    'vtho',
    'wabi',
    'waif',
    'wan',
    'waves',
    'wax',
    'waxp',
    'wbtc',
    'weth',
    'wgr',
    'wgrt',
    'whale',
    'wicc',
    'win',
    'wings',
    'winr',
    'wpr',
    'wtc',
    'wxt',
    'x',
    'xas',
    'xbase',
    'xbc',
    'xbp',
    'xby',
    'xcp',
    'xdn',
    'xem',
    'xin',
    'xlm',
    'xmcc',
    'xmg',
    'xmo',
    'xmr',
    'xmy',
    'xp',
    'xpa',
    'xpm',
    'xrp',
    'xsg',
    'xtz',
    'xuc',
    'xvc',
    'xvg',
    'xzc',
    'yam',
    'yfi',
    'yfii',
    'yfv',
    'yoyow',
    'zap',
    'zb',
    'zcl',
    'zcn',
    'zec',
    'zel',
    'zen',
    'zest',
    'zil',
    'zilla',
    'zrx',
  ],
};

export const protocol_api = {
  // url: 'https://defi-protocol.fly.dev/api',
  // url: 'http://127.0.0.1:5000/api' ,
  url: process.env.NEXT_PUBLIC_DEFI_PROTOCOLS_API_URL ?? 'https://defi-protocols-api-q67p7dk34q-uc.a.run.app/api',
};

export const opensea_api = {
  api_key: process.env.NEXT_PUBLIC_OPENSEA_API_KEY,
  retrieve_nft_by_wallet: `https://api.opensea.io/v2/chain/{chain}/account/{address}/nfts`,
  retrieve_nft_by_contract: `https://api.opensea.io/api/v2/chain/{chain}/contract/{address}/nfts/{identifier}`,
};

//PRODUCTION
// export const host_url = 'https://web3-wallet-dashboard-api-q67p7dk34q-uc.a.run.app';
//UAT
// export const host_url = 'https://uat---web3-wallet-dashboard-api-q67p7dk34q-uc.a.run.app/';
export const host_url = basicConfig.bluwhale.walletAPIUrl;
export const blue_whale_api = {
  host_url,
  simalar_wallets: `${host_url}/wallets/get_similar_wallets/`,
  pnl: `${host_url}/wallets/get_pnl/`,
  attributes: `${host_url}/wallets/get_attributes/`,
  protocols: `${host_url}/wallets/protocols/`,
};
