import { useEffect, useRef, useState } from 'react';
import { Status } from '../interfaces/types';
import { useHandleStatus } from './useHandleStatus';
import { useGetNativeBalanceOut } from './useGetNativeBalance';
import { useGetTokenBalancesNOut } from './useGetTokenBalancesN';
import { useGetNftDataOut } from './useGetNftData';
import { add } from 'cypress/types/lodash';
import { getNumber } from '../utils/helper';
import { infoParams } from '../elements/PlatformInfo';
import { info } from '../configs/info';
import { Transaction } from './useGetTransactions';
import { calcTotalAsset } from '../utils';
import { ProtocolData } from '@/types/bluwhaleDataApi';

export type useGenerateUIInfosParams = {
  nativeDto?: {
    status: Status;
    dto?: useGetNativeBalanceOut;
  };
  tokenDto?: { status: Status; dto?: useGetTokenBalancesNOut };
  nftDto?: { status: Status; dto?: useGetNftDataOut };
  protocolDto?: { status: Status; dto?: any[] };
  protocolDtoV2?: { status: Status; dto?: ProtocolData };
};

export function useGenerateUIInfos(address?: string, params?: useGenerateUIInfosParams) {
  const infoDafault = [
    { title: info.networth.title, balance: 0, status: 'loading' as Status, tooltip: info.networth.tooltip },
    { title: info.totalAssets.title, balance: 0, status: 'loading' as Status, tooltip: info.totalAssets.tooltip },
    { title: info.debts.title, balance: 0, status: 'loading' as Status, tooltip: info.debts.tooltip },
    { title: info.nftValue.title, balance: 0, status: 'loading' as Status, tooltip: info.nftValue.tooltip },
  ];

  const [infosDto, setInfosDto] = useState<infoParams[]>(infoDafault);
  const [status, setStatus] = useState<Status>('idle');
  const addressRef = useRef<string>();
  function init() {
    setStatus((pre) => 'loading');
    setInfosDto((pre) => infoDafault);
  }

  function calcNetWorth(totalAsset: number, rewards: number, debts: number) {
    return totalAsset + +rewards - debts;
  }
  function calcBalance(data?: ProtocolData): number {
    const amounts =
      Number(data?.totalPoolUsd ?? 0) + Number(data?.totalSupplyUsd ?? 0) + Number(data?.totalStakeUsd ?? 0);
    return amounts;
  }
  function calcRewards(data?: ProtocolData): number {
    const amounts = Number(data?.totalRewardUsd ?? 0);
    return amounts;
  }

  function excute(_params: useGenerateUIInfosParams) {
    // const rewardsBalance = calcBalance(['uniswapv3'], 'rewardsTotalAmountsUsd', _params?.protocolDto?.dto);
    const rewardsBalance = calcRewards(_params?.protocolDtoV2?.dto);
    const collectsBalance = calcBalance(_params?.protocolDtoV2?.dto);
    // const collectsBalance = calcBalance(
    //   ['compound', 'aavev3', 'uniswapv3'],
    //   'collectsTotalAmountsUsd',
    //   _params?.protocolDto?.dto,
    // );
    const debtsBalance = Number(_params?.protocolDtoV2?.dto?.totalBorrowUsd ?? 0);
    // const debtsBalance = calcBalance(['compound', 'aavev3'], 'borrowsTotalAmountsUsd', _params?.protocolDto?.dto);
    const nftBalanceUsd = getNumber(_params.nftDto?.dto?.totalChainNftBalanceUsd);
    const tokenBalanceUsd = getNumber(_params.tokenDto?.dto?.totalBalanceUsd);
    const nativeBalanceUsd = getNumber(_params.nativeDto?.dto?.totalBalanceUsd);
    const totalAssetFrom = [collectsBalance, tokenBalanceUsd, nativeBalanceUsd, nftBalanceUsd];
    const totalAsset = calcTotalAsset(totalAssetFrom);
    const netWorth = calcNetWorth(totalAsset, rewardsBalance, debtsBalance);
    const _infoList = [
      {
        title: info.networth.title,
        balance: netWorth,
        status: _params.nativeDto?.status ?? 'idle',
        tooltip: info.networth.tooltip,
      },
      {
        title: info.totalAssets.title,
        balance: totalAsset,
        status: _params.nativeDto?.status ?? 'idle',
        tooltip: info.totalAssets.tooltip,
      },
      {
        title: info.debts.title,
        balance: debtsBalance,
        status: _params.protocolDtoV2?.status ?? 'idle',
        tooltip: info.debts.tooltip,
      },
      {
        title: info.nftValue.title,
        balance: nftBalanceUsd,
        status: _params.nftDto?.status ?? 'loading',
        tooltip: info.nftValue.tooltip,
      },
    ];
    setInfosDto((pre) => _infoList);
    setStatus((pre) => 'success');
    // console.log('useGenerateUIInfos', _params.networth, _infoList);

    return _infoList;
  }

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (!address || !params) {
      setStatus((pre) => 'loading');
      // setInfosDto((pre) => infoDafault);

      return;
    }

    if (address !== addressRef.current) {
      // init();
      setStatus((pre) => 'loading');

      addressRef.current = address;
    }
    if (params.nativeDto?.status === 'loading' && params.tokenDto?.status === 'loading') {
      return;
    }

    excute(params);
  }, [address, JSON.stringify(params)]);
  return { infosDto, status, onInit: init, onExcute: excute };
}
