import {
  BluwhaleChain,
  NftTransactionData,
  TransactionData,
  TransactionResponse,
  Erc20TransactionData,
  WalletNftCollectionsResponse,
  WalletNftHoldsResponse,
} from '@/types/bluwhaleDataApi';
import axios from 'axios';

export type GetTransactionApiOptions = {
  platformId: BluwhaleChain;
  address: string;
  page: number;
  pageSize: number;
};

/**
 * Get the transaction of the wallet
 * @param _url {string} - The url of the API {basicUrl}/wallets/transactions/
 * @param body {GetTransactionApiOptions} - The options of the request
 * @returns
 * @throws
 * @example
 * ```ts
 * const url = 'https://api.bluwhale.com/wallets/transactions/';
 * const address = '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984';
 * const chain = 'ethereum';
 * const data = await getTransaction(url, { platformId: chain, address, page: 1, pageSize: 10 });
 * console.log(data);
 * ```
 */
async function getTransaction(
  _url: string,
  body: GetTransactionApiOptions,
): Promise<TransactionResponse<TransactionData>> {
  try {
    const response = await axios.post(_url, body);
    const json = response.data;
    return json;
  } catch (e) {
    console.log(e);
    throw e;
  }
}
async function getErc20Transaction(
  _url: string,
  body: GetTransactionApiOptions,
): Promise<TransactionResponse<Erc20TransactionData>> {
  try {
    const response = await axios.post(_url, body);
    const json = response.data;
    return json;
  } catch (e) {
    console.log(e);
    throw e;
  }
}
async function getNftTransaction(
  _url: string,
  body: GetTransactionApiOptions,
): Promise<TransactionResponse<NftTransactionData>> {
  try {
    const response = await axios.post(_url, body);
    const json = response.data;
    return json;
  } catch (e) {
    console.log(e);
    throw e;
  }
}

/**
 * Get the aggregate transaction of the wallet
 * @param _url {string} - The url of the API {basicUrl}/wallets/transactions/
 * @param body {GetTransactionApiOptions} - The options of the request
 * @returns
 * @throws
 * @example
 * ```ts
 * const url = 'https://api.bluwhale.com/wallets/transactions/aggregate/';
 * const address = '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984';
 * const chain = 'ethereum';
 * const data = await getTransaction(url, { platformId: chain, address, page: 1, pageSize: 10 });
 * console.log(data);
 * ```
 */
async function getTransactionAggregate(
  _url: string,
  body: GetTransactionApiOptions,
): Promise<TransactionResponse<TransactionData>> {
  try {
    const response = await axios.post(_url, body);
    const json = response.data;
    return json;
  } catch (e) {
    console.log(e);
    throw e;
  }
}

export { getTransaction, getErc20Transaction, getNftTransaction, getTransactionAggregate };
