import {
  BluwhaleChain,
  Erc20PriceRequest,
  Erc20PriceResponse,
  WalletTokenBalanceResponse,
} from '@/types/bluwhaleDataApi';
import axios from 'axios';

/**
 * Get the price of the ERC20 token
 * @param _url {string} - The url of the API {basicUrl}/wallets/price/erc20/
 * @param _tokenAddressList {Array<string>} - The list of the ERC20 token address
 * @param _chain {BluwhaleChain} - The chain of the token
 * @returns
 * @throws
 * @example
 * ```ts
 * const url = 'https://api.bluwhale.com/wallets/price/erc20/';
 * const tokenAddressList = ['0x1f9840a85d5af5bf1d1762f925bdaddc4201f984', '0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9'];
 * const chain = 'ethereum';
 * const data = await getErc20Price(url, tokenAddressList, chain);
 * console.log(data);
 * ```
 */
async function getErc20Price(
  _url: string,
  _tokenAddressList: Array<string>,
  _chain: BluwhaleChain,
): Promise<Erc20PriceResponse> {
  try {
    const body: Erc20PriceRequest = [];
    _tokenAddressList.forEach((item) => {
      body.push({ contractAddress: item, platformId: _chain });
    });

    const response = await axios.post(_url, body);
    const _data = response.data as Erc20PriceResponse;
    return _data;
  } catch (e) {
    console.log(e);
    throw e;
  }
}

/**
 * Get the balance of the ERC20 token
 * @param _url {string} - The url of the API {basicUrl}/wallets/balance/erc20/
 * @param _address
 * @param _chain
 * @returns
 * @throws
 * @example
 * ```ts
 * const url = 'https://api.bluwhale.com/wallets/balance/erc20/';
 * const address = '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984';
 * const chain = 'ethereum';
 * const data = await getErc20Balance(url, address, chain);
 * console.log(data);
 * ```
 */
async function getErc20Balance(
  _url: string,
  _address: string,
  _chain: BluwhaleChain,
): Promise<WalletTokenBalanceResponse> {
  try {
    const body = {
      address: _address,
      platformId: _chain,
    };
    const response = await axios.post(_url, body);
    const _data = response.data;
    return _data;
  } catch (e) {
    console.log(e);
    throw e;
  }
}

export { getErc20Price, getErc20Balance };
