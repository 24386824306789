import { useEffect, useRef, useState } from 'react';
import { calcPercent, getNumber } from '../utils/helper';
import { ChartRadarParams, _ChartRadarParams } from '../elements/ChartRadar';
import { radarData, score } from '../configs/radar';
import { NftData } from './useGetNftData';
import { Transaction } from './useGetTransactions';
import { useGetTokenBalancesNOut } from './useGetTokenBalancesN';
import { ChartTokenAlloctionParams } from '../elements/ChartTokenAlloction';
import { useGetNativeBalanceOut } from './useGetNativeBalance';
import { Status } from '../interfaces/types';
import { useDashBoard } from '../contexts/DashBoardContext';
import { ProtocolData } from '@/types/bluwhaleDataApi';

export type useGenerateUIRadarChartParams = {
  nativeDto?: useGetNativeBalanceOut;
  tokenDto?: useGetTokenBalancesNOut;
  nftDto?: NftData[];
  tsxDto?: Transaction[];
  tokenAlloction?: ChartTokenAlloctionParams;
  protocolDto?: Array<any>;
  protocolDtoV2?: ProtocolData;
};
export function useGenerateUIChartRadar(params?: useGenerateUIRadarChartParams) {
  //     體驗 :
  // 1. NFT 依照持有價值USD 0~50+萬 (score 0~10)// 07/21修改為數量0~100
  // 2. Stable coin 依照持有價值USD 0~50+萬 (score 0~10)
  // 3. Token ERC-20 + Native 依照持有的token的類型 1~10+ (score 0~10)
  // 4. Frequency 依照30日tansection 次數 0~100+次 (score 0~10)
  // 5. Chain  依照Native Chain Balance的 1~6 (score 0~6)
  // 6. Assets 依照total assets價值USD 0~200+萬 (score 0~10)

  const { walletAddress: address } = useDashBoard();

  const addressRef = useRef<string>();

  const [status, setStatus] = useState<Status>('idle');

  const [radarDto, setRadarDto] = useState<ChartRadarParams>();

  function setData(numArray: number[]) {
    return radarData.map((data, index) => {
      return {
        label: data.label,
        amount: calcPercent(numArray[index], data.validateAmount, score),
      };
    });
    //#region TEST DATA
    // const pecentNft = calcPercent(10,500000,score)
    // const pecentStable = calcPercent(10,500000,score)
    // const pecentToken = calcPercent(10,10,score)
    // const pecentChain = calcPercent(1,6,score)
    // const pecentAssets = calcPercent(20000,2000000,score)
    // const pecentFrequency = calcPercent(200,100,score)
    //#endregion
  }
  function init() {
    setStatus((pre) => 'loading');
    setRadarDto((pre) => undefined);
  }
  function filterProtocol(targets: Array<string>, _datas?: Array<any>) {
    return _datas?.filter((data) => targets.includes(data.name));
  }
  function calcBalance(targets: Array<string>, key: string, _datas?: Array<any>) {
    const _filterDatas = filterProtocol(targets, _datas);
    const _sum = _filterDatas?.reduce((sum, data) => {
      sum += getNumber(data[key]);
      return sum;
    }, 0);
    return _sum;
  }
  function excute(
    nativeDto?: useGetNativeBalanceOut,
    tokenDto?: useGetTokenBalancesNOut,
    nftDto?: NftData[],
    tsxDto?: Transaction[],
    tokenAlloction?: ChartTokenAlloctionParams,
  ) {
    const ob = {
      nft: nftDto?.length ?? 0,
      stable: tokenDto?.balanceSumByStableCoin?.totalBalanceUsd ?? 0,
      // tokenCounts: getNumber(tokenAlloction?.datas?.list?.length),
      // frequency: tsxDto?.length ?? 0,
      // chain: getNumber(nativeDto?.totalChainCounts),
      // assets: getNumber(nativeDto?.totalBalanceUsd),
      // pool: calcBalance(['uniswapv3'], 'collectsTotalAmountsUsd', params?.protocolDto),
      pool: Number(params?.protocolDtoV2?.totalPoolUsd ?? 0),
      // staking: 0,
      // staking: calcBalance(['compound', 'aavev3', 'uniswapv3'], 'collectsTotalAmountsUsd', params?.protocolDto),
      staking: Number(params?.protocolDtoV2?.totalStakeUsd ?? 0),
      // borrow: calcBalance(['compound', 'aavev3'], 'borrowsTotalAmountsUsd', params?.protocolDto),
      borrow: Number(params?.protocolDtoV2?.totalBorrowUsd ?? 0),
      // collect: calcBalance(['compound', 'aavev3'], 'collectsTotalAmountsUsd', params?.protocolDto),
      collect: Number(params?.protocolDtoV2?.totalPoolUsd ?? 0) + Number(params?.protocolDtoV2?.totalSupplyUsd ?? 0),
    };
    const dto = setData(Object.values(ob));
    setRadarDto((pre) => dto);
    setStatus((pre) => 'success');
  }

  useEffect(() => {
    if (address !== addressRef.current || !params) {
      addressRef.current = address;
      setStatus((pre) => 'loading');
      setRadarDto((pre) => undefined);
      return;
    }
    if (!params.tokenDto || !params.nativeDto) {
      return;
    }
    excute(params.nativeDto, params.tokenDto, params.nftDto, params.tsxDto, params.tokenAlloction);
  }, [JSON.stringify(params), address]);

  return { radarDto, status, onInit: init, onExcute: excute };
}
