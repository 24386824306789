/* eslint-disable complexity */
import React from 'react';
import { BlockChart } from '../common';
import { Radar } from 'react-chartjs-2';
import { multilineLabelPlugin } from '../utils';
import { lables, radarData } from '../configs/radar';
import { Status } from '../interfaces/types';
import Loading from './Loading';
import { useDashBoard } from '../contexts/DashBoardContext';
import { mockRadar } from '@/mocks/mockData';
import { Chart, Interaction } from 'chart.js';
import { getRelativePosition } from 'chart.js/helpers';

export type ChartRadarParams = Array<_ChartRadarParams>;

export type _ChartRadarParams = {
  label: string | string[];
  amount: number;
};
export const chartRadarTestData = [
  { label: radarData[0].label, amount: 100 },
  // { label: 'Stable\nCoin', amount: 90 },
  { label: radarData[1].label, amount: 90 },
  { label: radarData[2].label, amount: 90 },
  { label: radarData[3].label, amount: 19 },
  { label: radarData[4].label, amount: 20 },
  { label: radarData[5].label, amount: 100 },
];
export const chartRadarDefault = [
  { label: radarData[0].label, amount: 10 },
  { label: radarData[1].label, amount: 10 },
  { label: radarData[2].label, amount: 10 },
  { label: radarData[3].label, amount: 10 },
  { label: radarData[4].label, amount: 10 },
  { label: radarData[5].label, amount: 10 },
];

function ChartRadar({ params, status, isDemo }: { params?: ChartRadarParams; status: Status; isDemo: boolean }) {
  // let _params = chartRadarTestData;
  const { globalStatus } = useDashBoard();

  const isLoading = globalStatus === 'loading' || status === 'loading' || status === 'idle';
  const isComplete = globalStatus === 'idle' && status === 'success';
  const isError = globalStatus === 'idle' || status === 'error';

  const labels = lables;
  // isDemo ? mockRadar.labels : radarData.map((_data) => _data.label);

  const _params = params ?? chartRadarDefault;
  const backgroundColor = !_params && status === 'loading' ? 'rgba(0, 0, 0, 0)' : 'rgba(140, 138, 203, 0.5)';
  const borderColor = !_params ? 'rgba(0, 0, 0, 0)' : 'rgba(140, 138, 203, 0.5)';
  const lineColor = status !== 'loading' || isDemo ? '#FFFFFF' : '#575757';
  const nullData = [0, 0, 0, 0, 0, 0];
  const nullLabels = ['', '', '', '', '', ''];
  //TODO labels shoud import from outside or config
  //_params.map((param) => param.label); //['NFT', 'Stable\nCoin', 'Farm', 'Staking', 'Lend', 'Game'];
  let _labels = mockRadar.labels;
  let datas = mockRadar.data;

  if (!isDemo) {
    datas =
      status !== 'success' && status !== 'error'
        ? nullData
        : _params.map((param) => (param.amount < 1 ? param.amount : param.amount));
    _labels = status !== 'success' && status !== 'error' ? nullLabels : labels;
  }
  const pointInArea = (point: any, area: any) =>
    point.x >= area.left && point.x <= area.right && point.y >= area.top && point.y <= area.bottom;

  // @ts-ignore
  Interaction.modes.labels = (chart: any, e: any, options: any, useFinalPosition: any) => {
    const position = getRelativePosition(e, chart);
    const scale = chart.scales.r;
    const elements = [];

    let dataIndex = null;

    for (let i = 0; i < chart.data.labels.length; ++i) {
      const labelPos = scale.getPointLabelPosition(i);
      if (pointInArea(position, labelPos)) {
        dataIndex = i;
        break;
      }
    }

    if (dataIndex !== null) {
      for (let i = 0; i < chart.data.datasets.length; ++i) {
        const meta = chart.getDatasetMeta(i);
        elements.push({
          element: meta.data[dataIndex],
          datasetIndex: i,
          index: dataIndex,
        });
      }
    }

    return elements;
  };

  const data = {
    // labels,
    labels: _labels,
    datasets: [
      {
        label: '',
        // data: datas,
        data: datas.findIndex((a) => a > 0) !== -1 ? datas : nullData,
        fill: true,
        // fill: false, // Remove the area fill
        //'rgba(0, 0, 0, 0)', // Set the background color to transparent
        backgroundColor,
        // backgroundColor: 'rgba(140, 138, 203, 0.5)', //'rgba(54, 162, 235, 0.2)',
        // borderColor: 'rgba(140, 138, 203, 0.5)', //'rgb(54, 162, 235)',
        //'rgba(0, 0, 0, 0)', //'rgb(54, 162, 235)',
        borderColor,
        pointBackgroundColor: 'rgba(140, 138, 203, 0.5)',
        // pointBackgroundColor: 'rgb(54, 162, 235)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(140, 138, 203, 0.5)',
        pointStyle: false,
      },
    ],
  };
  const options = {
    maintainAspectRatio: true,
    responsive: true,
    // maintainAspectRatio: true,
    tooltips: {
      enabled: false,
    },
    plugins: {
      legend: {
        display: false,
        hidden: true,
      },
      // point tooltip
      tooltip: {
        enabled: false,
        // mode: 'y',
        mode: 'labels',
        intersect: false,
        // <-- this option disables tooltips
        external(context: any) {
          // Tooltip Element
          let tooltipEl = document.getElementById('chartjs-tooltip');

          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.innerHTML = `
              <div id="tooltip-body" class=" min-w-max bg-white bg-opacity-80 px-4 py-2 rounded-md"></div>
            `;
            document.body.appendChild(tooltipEl);
          }

          // Hide if no tooltip
          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = '0';
            // tooltipEl.style.opacity = '1';
            return;
          }

          // Set caret Position
          tooltipEl.classList.remove('above', 'below', 'no-transform');
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add('no-transform');
          }

          const getContent = (obs: Array<string>) => {
            if (!Array.isArray(obs)) {
              return [];
            }
            const title = obs.join();
            return radarData.find((_data) => {
              if (Array.isArray(_data.label)) {
                return _data.label.join('') === title.replace(',', '');
              }
              return _data.label === title;
            })?.content;
          };

          // Set Text
          if (tooltipModel.body) {
            const titleLines = tooltipModel.title || [];
            const bodyLines = tooltipModel.body.map(getContent);
            const content = getContent(titleLines);

            let innerHtml = '<div>';

            titleLines.forEach((title: string, i: any) => {
              // console.log('title', title);
              innerHtml += `
                <!--<div class="flex gap-2">
                  <div class="flex-1 font-bold text-black font-md">${title}</div>
                </div>-->
              `;
            });
            innerHtml += '</div><div class="pt-2">';

            bodyLines.forEach((body: any, i: any) => {
              // const colors = tooltipModel.labelColors[i];
              // let style = 'background:' + colors.backgroundColor;
              // style += '; border-color:' + colors.borderColor;
              // style += '; border-width: 2px';
              // const span = '<span style="' + style + '">' + body + '</span>';
              // innerHtml += '<tr><td>' + span + '</td></tr>';
              innerHtml += `
                <div class="flex justify-between gap-2 text-black max-w-sm">
                  <div class="flex-1 text-left text-sm max-w-[200px] 
                  ">${content ?? ''}</div>
                </div>
              `;
              // console.log('body', body);
            });
            innerHtml += '</div>';

            const tableRoot = tooltipEl.querySelector('#tooltip-body');
            // console.log('tableRoot', tableRoot);
            if (tableRoot === null) {
              return;
            }
            tableRoot.innerHTML = innerHtml;
          }

          // const position = tooltipModel.chart.scales.r.getPointLabelPosition(0);
          const position = context.chart.canvas.getBoundingClientRect();
          const lab_p = tooltipModel.chart.scales.r.getPointLabelPosition(tooltipModel.dataPoints[0].dataIndex);

          // Display, position, and set styles for font
          tooltipEl.style.opacity = '1';
          tooltipEl.style.transition = 'opacity 0.5s ease-in-out';
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left = `${position.left + window.pageXOffset + lab_p.left + 10}px`;
          tooltipEl.style.top = `${position.top + window.pageYOffset + lab_p.top + 13}px`;
          tooltipEl.style.padding = `${tooltipModel.padding}px ${tooltipModel.padding}px`;
          tooltipEl.style.pointerEvents = 'none';
          tooltipEl.style.zIndex = '40';
        },
      },
    },
    scales: {
      r: {
        // beginAtZero: true,
        // MIN
        min: 0,
        // max: 10, // MAX
        // max: Math.max(...datas) ?? 10, // MAX
        // max: status !== 'success' ? 10 : Math.max(...datas) ?? 10, // MAX
        // MAX
        max: datas.findIndex((a) => a > 0) !== -1 ? Math.max(...datas) : 10,
        ticks: {
          display: false,
          maxTicksLimit: 5,
          count: 4,
        },
        // display: false,
        angleLines: {
          display: true,
          color: lineColor,
        },
        align: 'center',

        pointLabels: {
          font: {
            size: 14,
          },
          // color: '#FFFFFF',
          color: lineColor,
          hover: false,
        },
        grid: {
          color: lineColor,
        },

        // suggestedMin: 50,
        // suggestedMax: 100,
      },
    },
  };

  // useEffect(()=>{},[_params])

  return (
    <BlockChart>
      {isLoading && !isDemo && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <Loading />
        </div>
      )}
      {/* @ts-ignore */}
      <Radar data={data} options={options} plugins={[multilineLabelPlugin]} />
    </BlockChart>
  );
}

export default ChartRadar;
