const radarData = [
  {
    label: 'NFT',
    validateAmount: 40,
    content: 'NFT Holdings ',
  },
  {
    label: ['Stable', ' Coin '],
    validateAmount: 50,
    content:
      'Stable Coin Valuation',
  },
  {
    label: 'Pool',
    validateAmount: 100000,
    content:
      'Pool rating represents the total value of liquidity provided by the wallet address in Uniswap V3. On a scale of 1 to 10, a higher score is assigned to a greater value.',
  },
  {
    label: 'Staking',
    validateAmount: 500000,
    content:
      'Staking rating represents the total value of assets staked by the wallet address in Curve. On a scale of 1 to 10, a higher score is assigned to a greater value.',
  },
  {
    label: 'Borrow',
    validateAmount: 500000,
    content:
      'Borrow rating represents the total amount borrowed by the wallet address in AAVE v2, AAVE v3, and Compound. On a scale of 1 to 10, a higher score is assigned to a larger amount',
  },
  {
    label: 'Supply',
    validateAmount: 500000,
    content:
      'Supply rating represents the total amount of currency supplied by the wallet address in AAVE v2, AAVE v3, and Compound. On a scale of 1 to 10, a higher score is assigned to a larger amount',
  },
];
//radar max number
const score = 10;
const lables = radarData.map((data) => data.label);

export { radarData, score, lables };
