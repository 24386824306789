import { useState } from 'react';
import { UseQueryResult, useQueries, useQuery } from 'react-query';
import { Chain } from '../configs';
import { AaveV3Protocol, CompoundProtocol, UniSwapV3Protocol } from '../protocols';
import { ProtocolModule } from '../interfaces/interface';
import { Status } from '../interfaces/types';

export type useGetProtocolDataType = {
  address: string;
  protocols?: Array<{ chain: Chain; module: ProtocolModule }>;
};
export type useGetProtocolDataOut = {
  protocolName: string;
  chain: Chain;
  collectsAmount: string;
  rewardsAmount: string;
  borrowsAmount: string;
};
// Inject by protocolModules
export default function useGetProtocolData({ address, protocols }: useGetProtocolDataType) {
  function convertStatus(_protocolQueries?: UseQueryResult<any, unknown>[]): Status {
    const containFinisheds = _protocolQueries && _protocolQueries?.findIndex((query) => query.isSuccess) !== -1;
    if (containFinisheds) {
      return 'success';
    }
    return 'loading';
  }
  function convertDataByProtocol(_datas?: Array<any>) {
    const hashMap = new Map();
    _datas?.forEach((_data) => {
      const key = _data.name;
      if (!hashMap.has(key)) {
        hashMap.set(key, [_data]);
        return;
      }
      hashMap.get(key).push(_data);
    });
    return hashMap;
  }
  function convertDataByChain(_datas?: Array<any>) {
    const hashMap = new Map();
    _datas?.forEach((_data) => {
      const key = _data.chain;
      if (!hashMap.has(key)) {
        hashMap.set(key, [_data]);
        return;
      }
      hashMap.get(key).push(_data);
    });
    return hashMap;
  }
  function calcTotalBalance(_datas?: Array<any>) {
    // console.log('calcTotalBalance', _datas);
    const balance = 0;
    // _datas?.reduce((sum,data)=>{
    //   if(data.)
    // },0)
    return balance;
  }

  const protocolQueries =
    protocols &&
    useQueries(
      protocols
        .filter((_protocol) => _protocol && _protocol.chain && _protocol.module)
        .map((_protocol) => {
          const _module = _protocol?.module;
          const _chain = _protocol?.chain;
          return {
            queryKey: [_module?.name, address, _chain],
            queryFn: async () => _module?.getData(address, _chain?.chain.apiHex),
          };
        }),
    );

  const datas =
    protocols &&
    protocolQueries?.map((query, index) =>
      protocols[index]?.module.convertData(query.data, protocols[index].chain.chain.apiHex),
    );
  const datasByProtocol = convertDataByProtocol(datas);
  const datasByChain = convertDataByChain(datas);

  const isAllFinished = protocolQueries && protocolQueries?.findIndex((query) => query.isLoading) === -1;
  const isLoadingArray = protocolQueries?.map((query) => query.isLoading);
  const containErrors = protocolQueries && protocolQueries?.findIndex((query) => query.isError) !== -1;
  const containFinisheds = protocolQueries && protocolQueries?.findIndex((query) => query.isSuccess) !== -1;

  const status = convertStatus(protocolQueries);
  return {
    datas,
    datasByProtocol,
    datasByChain,
    isAllFinished,
    containFinisheds,
    isLoadingArray,
    containErrors,
    status,
    onCalcTotalBalance: calcTotalBalance,
  };
}
