/* eslint-disable no-useless-catch */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-await-in-loop */
//@ts-nocheck

import Moralis from 'moralis';
import { EvmChain } from 'moralis/common-evm-utils';
import { useEffect, useRef, useState } from 'react';
import { BLUWHALE_CHAIN, CHAIN, Chain, chainList, supportChains } from '../configs/chain';
import { calcUsdByWei, calcWeiToEth, fetchTokenPrice } from '../utils/moralisUtils';
import { useDashBoard } from '../contexts/DashBoardContext';
import { MoralisBlock, Status } from '../interfaces/types';
import { getBlockNumberFromDate, getBlockNumberList, getDateFromToByDays } from '../utils/helper';
import { TokensInfo } from './useGetTokensInfo';
import { opensea_api } from '../configs';
import axios from 'axios';
import { MoralisNftTrade, NftModel } from '../interfaces';
import { openseaChains } from '../configs/opensea';
import { getNftHolds, getNftCollections } from '../api';
import { basicConfig } from '@/configs';
import { GetNftApiOptions } from '@/types/bluwhaleDataApi';
import { WalletNftHoldsResponse, WalletNftData, WalletNftHold } from '@/types/bluwhaleDataApi';

export type NftsDataByChain = {
  chain: Chain;
  nfts: Array<NftData> | undefined;
  totalBalanceEth?: number;
  totalBalanceUsd?: number;
};
export type NftDataByChainByKey = {
  [key in CHAIN]: NftsDataByChain;
};

export type NftData = {
  token_address: string;
  name?: string;
  symbol?: string;
  token_id?: string;
  priceWei?: string | number;
  priceEth?: string | number;
  priceUsd?: number;
  chain?: Chain;
  imageH?: {
    height?: number;
    width?: number;
    url?: string;
  };
  imageL?: {
    height?: number;
    width?: number;
    url?: string;
  };
  raw?: MoralisWalletNft & any;
};
type MoralisWalletNft = {
  // custom
  // chain?: Chain;
  // priceWei?: string | number;
  // priceEth?: string | number;
  // priceUsd?: number;
  token_address: string;
  token_id?: string;
  amount?: string;
  owner_of?: string;
  token_hash?: string;
  block_number_minted?: string;
  block_number?: string;
  contract_type?: string;
  name?: string;
  symbol?: string;
  token_uri?: string;
  metadata?: string;
  last_token_uri_sync?: string;
  last_metadata_sync?: string;
  minter_address?: string;
  media?: {
    mimetype?: string;
    parent_hash?: string;
    status?: string;
    updatedAt?: string;
    media_collection?: {
      low?: {
        height?: number;
        width?: number;
        url?: string;
      };
      medium?: {
        height?: number;
        width?: number;
        url?: string;
      };
      high?: {
        height?: number;
        width?: number;
        url?: string;
      };
    };
    original_media_url?: string;
  };
  possible_spam?: boolean;
  verified_collection?: boolean;
};

export type useGetNftDataParams = {
  address?: string;
  tokensInfo?: TokensInfo;
  target?: 'checkHasTrades' | 'list' | 'all';
  preStatus?: Status;
};
export type useGetNftDataOut = {
  nftListByChainByGenerate?: Array<NftsDataByChain>;
  nftListByChain?: Array<NftsDataByChain>;
  nftListByFlat?: Array<NftData>;
  totalChainNftBalanceUsd?: number;
  nftTrades?: MoralisNftTradeState;
  hasNftTrades?: boolean;
};
export type MoralisNftTradeState = {
  ETHEREUM?: MoralisNftTrade[];
  BSC?: MoralisNftTrade[];
  FANTOM?: MoralisNftTrade[];
  AVALANCHE?: MoralisNftTrade[];
  POLYGON?: MoralisNftTrade[];
  ARBITRUM?: MoralisNftTrade[];
};
export function useGetNftData({ address, target, tokensInfo: _tokenInfoOut, preStatus }: useGetNftDataParams) {
  const { staticBolockRef, staticNftPriceRef, tokensInfoRef, walletAddress } = useDashBoard();
  const tokensInfo = _tokenInfoOut ?? tokensInfoRef.current;
  const [statusPrice, setStatusPrice] = useState<Status>('idle');
  const [statusMeta, setStatusMeta] = useState<Status>('idle');
  const statusRef = useRef<Status>('idle');

  const [nftListByFlat, setNftListByFlat] = useState<Array<NftData>>();
  const [hasNftTrades, setHasNftTrades] = useState<boolean>();
  const [results, setResults] = useState<useGetNftDataOut>();

  const functionRecord = useRef<'idle' | 'fetchList' | 'fetchAll' | 'fecthMeta' | 'fetchPrice' | 'hasTrade'>('idle');

  useEffect(() => {
    if (!results) {
      return;
    }
    //console.log('results', functionRecord.current, results);
    if (functionRecord.current === 'fecthMeta') {
      setStatusMeta('success');
      return;
    }
    if (functionRecord.current === 'fetchPrice') {
      setStatusPrice('success');
      return;
    }
    // if (functionRecord.current === 'hasTrade') {
    //   setStatusPrice('success');
    //   return;
    // }
    if (functionRecord.current === 'fetchAll') {
      setStatusMeta('success');
      setStatusPrice('success');
    }
  }, [JSON.stringify(results)]);

  function init() {
    // setNftListByChainByGenerate((_pre) => undefined);
    // setNftListByChain((_pre) => undefined);
    // setNftListByFlat((_pre) => undefined);
    // setTotalChainNftBalanceUsd((_pre) => 0);
    // setHasNftTrades((_pre) => undefined);
    setResults((_pre) => undefined);
  }
  function initFetchMataStatus() {
    setResults((_pre) => undefined);
    setStatusMeta((pre) => 'loading');
  }
  function initAll() {
    functionRecord.current = 'idle';
    setResults((_pre) => undefined);
    setStatusMeta((pre) => 'loading');
    setStatusPrice((pre) => 'loading');
  }

  //#region NFT Trade
  type nftTrade = {
    address: string;
    chain: EvmChain;
    cursor?: string | undefined;
    allTrades?: any[];
    _toBlock?: MoralisBlock;
    _fromBlock?: MoralisBlock;
    _limit?: number;
  };

  async function _fetchNftTradeByWalletCallBack(props: nftTrade): Promise<Array<MoralisNftTrade>> {
    const limit = props._limit ?? 100;
    const format: 'decimal' | 'hex' = 'decimal';
    const direction: 'from' | 'to' | 'both' = 'both';
    const toBlock = props._toBlock?.block.toString();
    const fromBlock = props._fromBlock?.block;
    const { address, allTrades } = props;
    const _allTrades = allTrades ?? [];
    try {
      const response = await Moralis.EvmApi.nft.getWalletNFTTransfers({
        chain: props.chain.apiHex,
        limit,
        format,
        //direction,
        toBlock,
        fromBlock,
        address,
      });
      const resJson = response.toJSON();
      const results = resJson.result;
      const nextCursor = results && results.length > 0 ? resJson.cursor : null;
      // const nextCursor = null;
      if (results) {
        _allTrades.push(...results);
      }
      if (nextCursor) {
        return _fetchNftTradeByWalletCallBack({ ...props, cursor: nextCursor, allTrades: _allTrades });
      }
      return _allTrades as Array<MoralisNftTrade>;
    } catch (e) {
      console.log('fetchNftTradeByWalletCallBack', e);
      throw e;
    }
  }
  async function fetchNftTradeByWallet(props: nftTrade) {
    try {
      const limit = props._limit ?? 1;
      const format: 'decimal' | 'hex' = 'decimal';
      const direction: 'from' | 'to' | 'both' = 'both';
      const toBlock = props._toBlock?.block.toString();
      const fromBlock = props._fromBlock?.block;
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { address } = props;
      const response = await Moralis.EvmApi.nft.getWalletNFTTransfers({
        chain: props.chain.apiHex,
        limit,
        format,
        //direction,
        fromBlock,
        toBlock,
        address,
      });
      const resJson = response.toJSON();
      return { counts: resJson.result.length ?? 0, result: resJson.result, raw: { resJson, props } };
    } catch (e) {
      throw e;
    }
  }

  async function getBlockFromEachChain(_chainList: Chain[], months?: number) {
    const taskPromise = [];
    const results = [];
    for (let i = 0; i < _chainList.length; i++) {
      const chain = _chainList[i];
      const res = await getBlockNumberList(chain.chain, months, staticBolockRef.current);
      results.push({ chain, res });
    }
    return results;
  }

  // For Tag NFT Sweeper
  async function fetchNftTradesByDate(address: string, chain: Chain, days = 1) {
    //get block number
    const { currentDate, daysAgoDate } = getDateFromToByDays(days, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    const blocks = await getBlockNumberFromDate(chain.chain, new Date(daysAgoDate), staticBolockRef.current);
    const block = blocks[0];
    //interact sdk
    const props: nftTrade = { address, chain: chain.chain, _fromBlock: block };
    const trades = await _fetchNftTradeByWalletCallBack(props);
    return trades;
  }

  async function excuteFetchNftTradesByDate(address: string, days?: number) {
    const nftTrades: MoralisNftTradeState = {};
    const counts = chainList.length;
    for (let i = 0; i < counts; i++) {
      const res = await fetchNftTradesByDate(address, chainList[i], days);
      nftTrades[chainList[i].key] = res ?? ([] as MoralisNftTrade[]);
    }
    setResults((pre) => {
      return { ...pre, nftTrades };
    });
    return nftTrades;
  }
  // For Tag NFT Trader
  async function fetchNftTrades(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    address: string,
    chain: Chain,
    months?: number,
  ): Promise<
    {
      counts: number;
      result: any;
      raw: any;
    }[]
  > {
    let results = [];
    const tasks: Promise<{ counts: number; result: any; raw: any }>[] = [];
    const toBlocks = await getBlockNumberList(chain.chain, months, staticBolockRef.current);
    // const toBlocks = await getBlockNumberList(chain.chain, months, staticBolockRef.current);
    //  address = '0xab897f8A63CAd6d76D91AA799eEe5903D5367021';
    toBlocks.forEach((block) => {
      const props: nftTrade = { address, chain: supportChains.ETHEREUM.chain, _toBlock: block };
      tasks.push(fetchNftTradeByWallet(props));
    });
    results = await Promise.all(tasks);
    return results.flat();
  }
  async function _checkNftHasTrades(address: string, chainList: Chain[], months = 3) {
    const counts = chainList.length;
    let res;
    for (let i = 0; i < counts; i++) {
      const chain = chainList[i];
      res = await fetchNftTrades(address, chain, months);
      const tradeCounts = res.reduce((acc, res) => acc + Number(res.counts ?? 0), 0);
      if (tradeCounts > 0) {
        return { hasTrade: true, res };
      }
    }
    return { hasTrade: false, res };
  }
  async function checkNftHasTrades(address: string, chainList: Chain[], months = 3) {
    //console.log('results checkNftHasTrades');

    functionRecord.current = 'hasTrade';
    const { hasTrade, res } = await _checkNftHasTrades(address, chainList, months);
    setHasNftTrades(hasTrade);
    setResults((pre) => {
      return { ...pre, hasNftTrades: hasTrade };
    });
    return hasTrade;
  }

  //#endregion

  //#region Fetch Nfts by Wallet
  async function _fetchNftsByWallet(
    address: string,
    chain: EvmChain,
    cursor: string | undefined = undefined,
    allNfts: MoralisWalletNft[] = [],
  ): Promise<MoralisWalletNft[] | undefined> {
    if (address.toLocaleLowerCase() !== walletAddress.toLocaleLowerCase()) {
      return [];
    }
    const maxLimit = 100;
    //max:100
    const limit = 100;
    // const disableTotal = false;
    // Fetching images automatically from the Moralis API may incur additional costs.
    const mediaItems = true;
    try {
      const res = await Moralis.EvmApi.nft.getWalletNFTs({
        chain: chain.apiHex,
        address,
        limit,
        // disableTotal,
        cursor,
        mediaItems,
      });
      const nfts = res.toJSON();

      const results = nfts.result;
      const nextCursor = results && results.length > 0 ? nfts.cursor : null;
      // const nextCursor = null;
      if (results) {
        allNfts.push(...results);
      }

      if (nextCursor && allNfts.length < maxLimit) {
        return _fetchNftsByWallet(address, chain, nextCursor, allNfts);
      }

      // await delay(1);
      return allNfts;
    } catch (e) {
      console.error('fetchNftsByWallet error', e);
      return allNfts;
    }
  }
  async function _fetchOpenSeaNftsByWallet(
    address: string,
    chain: string,
    next: string | undefined = undefined,
    allNfts: Array<NftModel> = [],
  ): Promise<Array<NftModel> | undefined> {
    const url = opensea_api.retrieve_nft_by_wallet.replace('{chain}', chain ?? '').replace('{address}', address);
    const limit = 50;
    const _url = next ? `${url}?limit=${limit}&next=${next}` : `${url}?limit=${limit}`;
    const res = await axios
      .get(`${_url}`, {
        headers: {
          // 'Authorization': `Bearer ${token}`
          'x-api-key': `${opensea_api.api_key}`,
        },
        // params: { chain: _chain, user_addr: _address },
      })
      .then((res) => res.data as { nfts: Array<NftModel>; next: string | undefined })
      .catch((e) => {
        return { nfts: [], next: undefined };
      });
    const results = res.nfts;
    if (results) {
      allNfts.push(...results);
    }
    const nextCursor = results && results.length > 0 ? res.next : undefined;
    // const nextCursor = null;
    if (nextCursor) {
      return _fetchOpenSeaNftsByWallet(address, chain, nextCursor, allNfts);
    }
    return allNfts;
  }
  // moralis && opensea
  async function fetchNftsByWallet(address: string, chainOb: Chain): Promise<NftData[] | undefined> {
    const nftsPromise = await _fetchNftsByWallet(address, chainOb.chain);
    // add opensea api and filter
    const chain = openseaChains[chainOb.key];
    const openseaNftsPromise = chain ? await _fetchOpenSeaNftsByWallet(address, chain) : [];
    const [nfts, openseaNfts] = await Promise.all([nftsPromise, openseaNftsPromise]);
    const filterNfts = openseaNfts
      ?.map((nft: NftModel) => {
        return nfts?.find((_nft) => {
          return _nft.token_address.toLowerCase() === nft.contract.toLowerCase() && _nft.token_id === nft.identifier;
        });
      })
      .filter((nft) => nft !== undefined) as Array<MoralisWalletNft>;
    // const mappedNfts = nfts?.map((nft) => {
    const mappedNfts = filterNfts?.map((nft) => {
      return {
        token_address: nft?.token_address,
        name: nft?.name,
        symbol: nft?.symbol,
        token_id: nft?.token_id,
        priceWei: 0,
        priceEth: 0,
        priceUsd: 0,
        chain: chainOb,
        isPossibleSpam: nft?.possible_spam,
        imageH: nft?.media?.media_collection?.high,
        imageL: nft?.media?.media_collection?.low,
        raw: nft,
      };
    });

    // console.log('🚀 ~ file: useGetNftData.ts:369 ~ mappedNfts ~ before & after:', {
    //   user: address,
    //   chain: chainOb,
    //   nfts: { length: nfts?.length, nfts },
    //   openseaNfts: { length: openseaNfts?.length, openseaNfts },
    // });

    // @ts-ignore
    const filteredNfts = mappedNfts.filter((mappedNft) => !mappedNft.isPossibleSpam);
    // console.log('🚀 ~ file: useGetNftData.ts:376:', {
    //   all: mappedNfts?.length,
    //   withoutSpam: filteredNfts.length,
    // });

    return filteredNfts;
  }

  async function fetchNftLowestPrice(address: string, chain: EvmChain) {
    //90;
    const days = undefined;
    const res = await Moralis.EvmApi.nft.getNFTLowestPrice({ chain: chain.apiHex, address, days });
    const price = res?.toJSON();
    return price?.price ?? 0;
  }

  //#endregion

  //#region Fetch Nfts by Wallet Bluewhale API
  function generateBluwhaleDataToNftData(res: Array<WalletNftData<WalletNftHold>>, chainOb: Chain): Array<NftData> {
    const result = res.map((nfts) =>
      nfts.list.map((nft) => {
        const chain = chainList.find((chain) => chain.bluApiChain === chainOb.bluApiChain);
        const priceEth = nft.floorPrice !== '' ? Number(nft.floorPrice.replace('ETH', '').trim()) : 0;
        const priceUsd = Number(nft.floorPriceUsd ?? 0);
        return {
          token_address: nft.contractAddress,
          name: nft.name,
          symbol: nft.symbol,
          token_id: nft.tokenId,
          imageH: {
            url: nft.imageUrl,
          },
          priceWei: 0,
          priceEth,
          priceUsd,
          chain,
          raw: nft,
        };
      }),
    );
    return result.flat();
  }
  async function _fetchNftsByWalletV2(
    address: string,
    chainOb: Chain,
    page = 1,
    pageSize = 50,
    maxLength = 100,
  ): Promise<{
    total: number;
    list: Array<WalletNftData<WalletNftHold>>;
  }> {
    if (address.toLowerCase() !== walletAddress.toLowerCase()) {
      return [];
    }
    // const url = `${basicConfig.bluwhale.walletAPIUrl}/wallets/nft/holds/`;
    const url = `${basicConfig.bluwhale.walletAPIUrl}/wallets/nft/collections/`;

    const bluChain = chainOb.bluApiChain;
    const body = {
      platformId: bluChain,
      address,
      page,
      pageSize,
    };
    try {
      // const result = await getNftHolds(url, body);
      const result = await getNftCollections(url, body);

      // if (result && result.data.total > page * pageSize && page * pageSize < maxLength) {
      //   const nextPageResult = await _fetchNftsByWalletV2(address, chainOb, page + 1, pageSize);
      //   return [result.data, ...nextPageResult];
      // }
      return { total: result.data.total, list: [result.data] };
    } catch (e) {
      console.error(`fetchNftsByWalletV2 error : ${e}`);
      return [];
    }
  }
  async function fetchNftsByWalletV2(
    address: string,
    chainOb: Chain,
    list: Array<NftsDataByChain> = [],
    page = 1,
    pageSize = 50,
    nfts: Array<NftData> = [],
  ): Promise<{ list: Array<NftsDataByChain>; nfts: Array<NftData> }> {
    const MAX = 100;
    const overLimit = false;
    // const overLimit =  page * pageSize > MAX;
    const result = await _fetchNftsByWalletV2(address, chainOb, page, pageSize);
    const data = generateBluwhaleDataToNftData(result.list, chainOb);
    nfts.push(...data);
    // generate data first
    const totalBalanceUsd = data.reduce((acc, nft) => acc + Number(nft.priceUsd), 0);
    const totalBalanceEth = data.reduce((acc, nft) => acc + Number(nft.priceEth), 0);
    const nftsDataByChain: NftsDataByChain = { nfts, totalBalanceUsd, totalBalanceEth, chain: chainOb };
    list = hanldePushNftDataList(list, nftsDataByChain, chainOb);
    handleGenerateData(list);
    //await 1 sec let image server return image
    const TIMER = 500;
    // eslint-disable-next-line no-promise-executor-return
    await new Promise((resolve) => setTimeout(resolve, TIMER));
    if (result.total > page * pageSize && !overLimit) {
      return fetchNftsByWalletV2(address, chainOb, list, page + 1, pageSize, nfts);
    }

    return { list, nfts };
  }
  //#endregion

  //#region generate

  function generateData(nft: NftData, __tokensInfo?: TokensInfo): NftData {
    const _tokensInfo = __tokensInfo ?? tokensInfo;
    const native = _tokensInfo && nft.chain?.key && _tokensInfo[nft.chain?.key].native;
    const nativePrice = native?.usdPrice ?? 0;
    const priceWei = nft.priceWei ?? 0;
    const priceEth = nft.priceEth ?? 0;
    const priceUsd = nft.priceUsd ?? Number(priceEth) * Number(nativePrice);
    return {
      ...nft,
      priceWei,
      priceEth,
      priceUsd,
    };
  }
  function generateDatas(nft: NftsDataByChain, tokensInfo?: TokensInfo): NftData[] | undefined {
    const { nfts } = nft;
    return nfts?.map((nft) => generateData(nft, tokensInfo));
  }
  function generateDataByChain(nftByChain: NftsDataByChain, tokensInfo?: TokensInfo): NftsDataByChain {
    if (!nftByChain.nfts || nftByChain.nfts.length === 0) {
      return nftByChain;
    }
    const nfts = generateDatas(nftByChain, tokensInfo);
    const totalBalanceEth = nfts?.reduce((acc, nft) => acc + Number(nft.priceEth), 0);
    const totalBalanceUsd = nfts?.reduce((acc, nft) => acc + Number(nft.priceUsd), 0);
    return { ...nftByChain, nfts, totalBalanceEth, totalBalanceUsd };
  }
  function generateDataByChains(nftByChains: NftsDataByChain[], tokensInfo?: TokensInfo): NftsDataByChain[] {
    return nftByChains?.map((chainNft) => generateDataByChain(chainNft, tokensInfo));
  }
  function generateFlatData(nftByChains: NftsDataByChain[]): NftData[] | [] {
    return nftByChains?.flatMap((nftsOb) => nftsOb.nfts || []);
  }

  function calcTotalBalanceUsd(list: NftsDataByChain[]) {
    const totalBalancUsd = list.reduce((sum, item) => sum + Number(item.totalBalanceUsd ?? 0), 0);
    return totalBalancUsd;
  }

  function excuteGenerate(list: NftsDataByChain[], tokensInfo?: TokensInfo) {
    const nfts = generateDataByChains(list, tokensInfo);
    const totalChainBalancUsd = calcTotalBalanceUsd(nfts);
    return { nfts, totalChainBalancUsd };
  }
  //#endregion

  const nftPriceMap = new Map();

  function excuteGenerateDatas(list: NftsDataByChain[], tokensInfo?: TokensInfo) {
    const { nfts, totalChainBalancUsd } = excuteGenerate(list, tokensInfo);
    const flatData = generateFlatData(nfts);
    // setNftListByChainByGenerate((_pre) => nfts);
    // setTotalChainNftBalanceUsd((_pre) => totalChainBalancUsd);
    // setNftListByFlat((_pre) => flatData);
    const results: useGetNftDataOut = {
      nftListByChainByGenerate: nfts,
      nftListByFlat: flatData,
      totalChainNftBalanceUsd: totalChainBalancUsd,
      nftListByChain: list,
    };
    return results;
  }

  //#region fetchLastNftsTradePrice fetchLastNftTradePrice
  /**
   * @summary to get NFT last Price
   * @param address
   * @param chain
   * @returns
   */
  async function fetchLastNftTradePrice(address: string, chain: EvmChain) {
    const limit = 1;
    const res = await Moralis.EvmApi.nft.getNFTTrades({ address, chain: chain.apiHex, limit });
    const resJson = res.toJSON();
    const { result } = resJson;
    const priceWei = result && result.length > 0 ? result[0].price : 0;
    // //console.log('fetchLastNftTradePrice', chain);
    return {
      priceWei,
      raw: result,
    };
  }

  async function fetchLastNftsTradePrice(nfts: NftData[], chainOb: Chain) {
    const { chain } = chainOb;
    const results = [];

    for (let i = 0; i < nfts?.length; i++) {
      const address = nfts[i].token_address;
      //Moralis just support getting NFT price on opensea in ETH
      // const nftPriceList = [EvmChain.ETHEREUM.apiHex, EvmChain.AVALANCHE.apiHex];
      if (chain.apiHex === EvmChain.BSC.apiHex) {
        // if (nftPriceList.includes(chain.apiHex)) {
        results.push({ ...nfts[i] });
        continue;
      }
      const key = `${chain.apiHex}${address}`;

      // call fetch nft last price function

      if (!staticNftPriceRef.current.has(key)) {
        const res = await fetchLastNftTradePrice(address, chain);
        staticNftPriceRef.current.set(key, res);
      }

      const priceDto = staticNftPriceRef.current.get(key);

      const priceEth = calcWeiToEth(priceDto.priceWei, chainOb.wrappedToken.decimal);

      results.push({
        ...nfts[i],
        priceWei: priceDto.priceWei,
        //usd need to get native price,so move to generate function
        priceUsd: 0,
        priceEth,
        chain: chainOb,
        raw: { ...nfts[i].raw, nftPrice: nftPriceMap.get(address) },
      });
    }
    return results;
  }

  // call this function after generate UI dto
  /**
   *
   * @param nftDatas
   * @returns
   */
  async function excuteFetchNftsPrice(
    nftOut?: useGetNftDataOut,
    tokensInfo?: TokensInfo,
  ): Promise<useGetNftDataOut | undefined> {
    //console.log('results excuteFetchNftsPrice');
    if (!nftOut) {
      return;
    }
    const nftByChains = nftOut.nftListByChain;
    const list = [];
    if (!nftByChains) {
      return;
    }
    for (let i = 0; i < nftByChains?.length; i++) {
      const nftDatas = nftByChains[i].nfts;

      if (nftDatas) {
        if (nftByChains[i].chain.key === CHAIN.ETHEREUM) {
          const chainData = { nfts: nftDatas, totalBalanceUsd: 0, totalBalanceEth: 0, chain: nftByChains[i].chain };
          list.push(chainData);
        } else {
          const nfts = await fetchLastNftsTradePrice(nftDatas, nftByChains[i].chain);
          const chainData = { nfts, totalBalanceUsd: 0, totalBalanceEth: 0, chain: nftByChains[i].chain };
          list.push(chainData);
        }
      }
    }

    const _results = await excuteGenerateDatas(list, tokensInfo);
    const results = { ..._results, nftListByChain: list };
    functionRecord.current = 'fetchPrice';
    setResults((pre) => {
      return { ...pre, ...results };
    });
    setStatusPrice((_pre) => 'success');
    return results;
  }
  //#endregion

  //#region fetch nft meta only, no price

  async function excuteFetchNftDataOnlyByChain(address: string, chainOb: Chain): Promise<NftsDataByChain> {
    const nfts = await fetchNftsByWallet(address, chainOb);
    return { nfts, totalBalanceUsd: 0, totalBalanceEth: 0, chain: chainOb };
  }
  async function excuteFetchNftDataOnlyByChainV2(
    address: string,
    chainOb: Chain,
    list: NftsDataByChain[] = [],
  ): Promise<Array<NftsDataByChain>> {
    const nftsData = await fetchNftsByWalletV2(address, chainOb, list);
    // const totalBalanceUsd = nftsData.nfts.reduce((acc, nft) => acc + Number(nft.priceUsd), 0);
    // const totalBalanceEth = nftsData.nfts.reduce((acc, nft) => acc + Number(nft.priceEth), 0);
    // return { nfts: nftsData.nfts, totalBalanceUsd, totalBalanceEth, chain: chainOb };
    return nftsData.list;
  }
  async function handleSelectExcuteFetchNftOnlyDataFunction(
    address: string,
    _chain: Chain,
    list: NftsDataByChain[] = [],
  ) {
    if (_chain.bluApiChain === BLUWHALE_CHAIN.ETHEREUM) {
      return excuteFetchNftDataOnlyByChainV2(address, _chain, list);
    }
    const tmpList = await excuteFetchNftDataOnlyByChain(address, _chain);
    list.push(tmpList);
    handleGenerateData(list);
    return list;
  }
  async function excuteFetchNftOnlyData(address: string): Promise<useGetNftDataOut> {
    functionRecord.current = 'fecthMeta';
    //console.log('results excuteFetchNftOnlyData');

    initFetchMataStatus();
    const counts = chainList.length;
    let list: NftsDataByChain[] = [];
    for (let i = 0; i < counts; i++) {
      // const res = await excuteFetchNftDataOnlyByChain(address, chainList[i]);
      list = await handleSelectExcuteFetchNftOnlyDataFunction(address, chainList[i], list);
    }
    setHasNftTrades(true);

    return results;
  }

  //#endregion

  //#region Handle Showing NFT Meta First
  function handleGenerateData(list: NftsDataByChain[]) {
    const results = excuteGenerateDatas(list);
    setResults((pre) => {
      return { ...pre, ...results };
    });
    setNftListByFlat(results.nftListByFlat);
    if (results.nftListByFlat?.length > 0) {
      setStatusMeta((_pre) => 'success');
    }
  }
  function hanldePushNftDataList(
    list: Array<NftsDataByChain>,
    newNftsData: NftsDataByChain,
    chainOb: Chain,
  ): Array<NftsDataByChain> {
    const index = list.findIndex((nft) => nft.chain.key === chainOb.key);
    if (index === -1) {
      list.push(newNftsData);
    } else {
      list[index] = newNftsData;
    }
    return list;
  }
  //#endregion

  /**
   * @summary combine nft + nft price
   * @param address
   * @param chainOb
   * @returns
   */
  async function excuteFetchNftDataPriceByChain(address: string, chainOb: Chain) {
    const nfts = await fetchNftsByWallet(address, chainOb);
    if (!nfts) {
      return { chain: chainOb, nfts };
    }
    const results = await fetchLastNftsTradePrice(nfts, chainOb);

    return { nfts: results, totalBalanceUsd: 0, totalBalanceEth: 0, chain: chainOb };
  }
  async function excuteFetchNftDataPriceByChainV2(address: string, chainOb: Chain) {
    const nftsData = await fetchNftsByWalletV2(address, chainOb);
    if (!nftsData.nfts) {
      return { chain: chainOb, nfts: nftsData.nfts };
    }
    // const results = await fetchLastNftsTradePrice(nfts, chainOb);

    return { nfts: nftsData.nfts, totalBalanceUsd: 0, totalBalanceEth: 0, chain: chainOb };
  }

  async function handleSelectFunction(_address: string, _chain: Chain) {
    // //TODO : open when data api finished
    if (_chain.bluwhaleChain === BLUWHALE_CHAIN.ETHEREUM) {
      return excuteFetchNftDataPriceByChainV2(_address, _chain);
    }
    return excuteFetchNftDataPriceByChain(address, _chain);
  }

  async function excute(address: string) {
    // address = '0x00E5d15149eE3c1cceC40D964471990aa01bA904'; //'0xEF8b383C218AD72919FEfAfC1b60ef720aCa3F47'; //'0xD08118A42bd945Bd02e4Ab0551bD9041BC7dCE68'; //test
    const counts = chainList.length;
    const list: NftsDataByChain[] = [];
    for (let i = 0; i < counts; i++) {
      // if (chainList[i].chain.apiHex != EvmChain.ETHEREUM.apiHex) continue; //Test ETH
      // const res = await excuteFetchNftDataPriceByChain(address, chainList[i]);
      const res = await handleSelectFunction(address, chainList[i]);
      list.push(res);
    }
    return list;
  }

  async function excuteAll(address: string, tokensInfo?: TokensInfo) {
    //console.log('results excuteAll');

    functionRecord.current = 'fetchAll';

    // const tasks = [];
    const list = await excute(address);
    const hasTrade = await checkNftHasTrades(address, chainList);
    const _results = await excuteGenerateDatas(list, tokensInfo);
    const results = { ..._results, nftListByChain: list, hasNftTrades: hasTrade };
    setResults((pre) => {
      return { ...pre, ...results };
    });
    return results;
  }

  async function excuteSelector(
    address: string,
    tokensInfo?: TokensInfo,
    target: 'checkHasTrades' | 'list' | 'all' = 'all',
  ) {
    if ((target === 'list' || target === 'checkHasTrades') && !tokensInfo) {
      return;
    }
    let fn: () => Promise<useGetNftDataOut | boolean | NftsDataByChain[]>;
    // //console.log(`useGetNftData:${target}`);
    switch (target) {
      case 'checkHasTrades':
        functionRecord.current = 'hasTrade';
        fn = async () => checkNftHasTrades(address, chainList);
        break;
      case 'list':
        functionRecord.current = 'fetchAll';

        fn = async () => excute(address);
        break;
      case 'all':
        functionRecord.current = 'fetchAll';
        initAll();

        fn = async () => excuteAll(address, tokensInfo);
        break;
      default:
        fn = async () => excute(address);
        break;
    }
    const results = await fn();
    statusRef.current = 'success';
    return results;
  }

  async function _fetchOpenSeaNftsByContract(
    address: string,
    chain?: string,
    identifier?: string,
  ): Promise<NftModel | undefined> {
    const url = opensea_api.retrieve_nft_by_contract
      .replace('{chain}', chain ?? 'ethereum')
      .replace('{address}', address)
      .replace('{identifier}', identifier ?? '1');
    const res = await axios
      .get(`${url}`, {
        headers: {
          // 'Authorization': `Bearer ${token}`
          'x-api-key': `${opensea_api.api_key}`,
        },
        // params: { chain: _chain, user_addr: _address },
      })
      .then((res) => res.data as { nft: NftModel })
      .catch((e) => {
        return { nft: undefined };
      });
    return res.nft;
  }

  useEffect(() => {
    initAll();
  }, [address]);

  return {
    results,
    hasNftTrades,
    nftListByFlat,
    statusPrice,
    statusMeta,

    onInit: initAll,

    onExcute: excuteSelector,
    onExcuteCheck: checkNftHasTrades,
    onExcuteAll: excuteAll,

    onExcuteOnlyMetaData: excuteFetchNftOnlyData,
    onExcuteFetchNftsPrice: excuteFetchNftsPrice,
    onExcuteFetchTradesByDate: excuteFetchNftTradesByDate,
    fetchOpenSeaNftsByContract: _fetchOpenSeaNftsByContract,
    onTest: fetchNftsByWallet,
  };
}
