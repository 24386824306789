/* eslint-disable @typescript-eslint/no-shadow */
import { useEffect, useState } from 'react';
import { Status } from '../interfaces/types';
import { delay } from '../utils/helper';

export function useHandleStatus(checkStatusList?: Status[], time?: number) {
  const [status, setStatus] = useState<Status>('idle');
  const [combineStatus, setCombineStatus] = useState<Status>('idle');
  async function initHandler(fn?: any[]) {
    setStatus((pre) => 'idle');
    setCombineStatus((pre) => 'idle');
  }
  async function completeHandler(fn?: any) {
    setStatus((pre) => 'success');
    return typeof fn === 'function' ? fn() : fn;
  }
  async function errorHandler(fn?: any) {
    setStatus((pre) => 'error');
    return typeof fn === 'function' ? fn() : fn;
  }
  async function excuteHandler(fn?: any) {
    setStatus((pre) => 'loading');
    return typeof fn === 'function' ? fn() : fn;
  }
  //   function handleSuccess(fn?: any) {
  //     return fn();
  //   }

  useEffect(() => {
    function checkStatus(statusList: Status[]): Status {
      const isCompleted = statusList?.filter((status) => status === 'success').length === statusList.length;
      const isError = statusList?.findIndex((status) => status === 'error') !== -1;
      return isError ? 'error' : isCompleted ? 'success' : 'loading';
    }
    async function checkStatusDelay(checkStatusList: Status[], time?: number) {
      const list = status !== 'idle' ? checkStatusList.concat(status) : checkStatusList;
      const combineStatus = checkStatus(list);
      if (combineStatus === 'success' && time) {
        await delay(time);
      }
      setCombineStatus(combineStatus);
    }
    if (!checkStatusList) {
      return;
    }
    if (checkStatusList.length === 0) {
      return;
    }
    checkStatusDelay(checkStatusList, time);
  }, [JSON.stringify(checkStatusList), time]);

  return { combineStatus, status, excuteHandler, completeHandler, errorHandler, initHandler };
}
