import { MutableRefObject, createContext, createRef, useContext, useEffect, useRef, useState } from 'react';
import { MoralisTokenPrice, Status } from '../interfaces/types';
import { CHAIN, chainList, whiteList } from '../configs';
import { useGetTokenBalances } from '../hooks/useGetTokenBalances';
import { useGetTokensPrice } from '../hooks/useGetTokensPrice';
import { useGetStableCoins } from '../hooks/useGetStableCoins';
import { DefaultTokensByChain, TokensInfo, useGetTokensInfo } from '../hooks/useGetTokensInfo';
import { delay, init } from '../utils/helper';
import { QueryClient, QueryClientProvider } from 'react-query';

export type DashBoardContextType = {
  errorFn?: () => void;
  staticBolockRef: MutableRefObject<Map<string, any>>;
  tokensPriceBySymbolRef: MutableRefObject<Map<string, MoralisTokenPrice>>;
  walletAddress: string;
  defaultTokens?: DefaultTokensByChain;
  defaultTokensPrice?: Map<string, Map<string, MoralisTokenPrice>>;
  // staticNftPriceRef?: Map<string, any>;
  staticNftPriceRef: MutableRefObject<Map<string, any>>;
  tokensInfoRef: MutableRefObject<TokensInfo | undefined>;
  globalStatus: Status;
};

const defaultContext: DashBoardContextType = {
  errorFn: () => console.log('init'),
  walletAddress: '0x0000000000',
  // Create an empty Map using { current: new Map<string, any>() }
  staticBolockRef: { current: new Map<string, any>() },
  tokensPriceBySymbolRef: { current: new Map<string, any>() },
  staticNftPriceRef: { current: new Map<string, any>() },
  tokensInfoRef: { current: undefined },
  globalStatus: 'idle',
};

const DashBoardContext = createContext<DashBoardContextType>(defaultContext);
function useDashBoard(): DashBoardContextType {
  return useContext(DashBoardContext);
}
export default DashBoardContext;
export { useDashBoard };

export function DashBoardProvider({ ...props }) {
  const defaultWhiteTokens = whiteList;
  const { walletAddress } = props;
  // const { defaultTokens, tokensInfo } = useGetTokensInfo(walletAddress);
  const defaultChainList = chainList.map((chain) => chain.chain);
  const staticBolockRef = useRef<Map<string, any>>(new Map());
  const staticNftPrice = useRef<Map<string, any>>(new Map());
  const staticNftPriceRef = useRef<Map<string, any>>(new Map());
  const tokensPriceBySymbolRef = useRef<Map<string, MoralisTokenPrice>>(new Map<string, MoralisTokenPrice>());
  const tokensInfoRef = useRef<TokensInfo>();

  const [globalStatus, setGlobalStatus] = useState<Status>('idle');

  async function handleSetGlobalStatus() {
    await delay(3);
    setGlobalStatus('idle');
  }
  useEffect(() => {
    if (globalStatus !== 'loading') {
      return;
    }
    handleSetGlobalStatus();
  }, [globalStatus]);
  useEffect(() => {
    init();
    setGlobalStatus((pre) => 'loading');
  }, [walletAddress]);
  // const { chainsTokensPrice } = useGetTokensPrice(defaultWhiteTokens, defaultChainList);
  // useGetStableCoins();
  const queryClient = new QueryClient({
    defaultOptions: { queries: { staleTime: 1000 * 60 * 5 } },
  });
  return (
    <QueryClientProvider client={queryClient}>
      <DashBoardContext.Provider
        value={{
          errorFn: props.errorFn,
          staticBolockRef,
          tokensPriceBySymbolRef,
          staticNftPriceRef,
          tokensInfoRef,
          // defaultTokens,
          // tokensInfo,
          walletAddress,
          globalStatus,
        }}
      >
        {props.children}
      </DashBoardContext.Provider>
    </QueryClientProvider>
  );
}
