import React from 'react';
import { BlockInfo } from '../common';
import { Flex, Grid, Icon, Text, Tooltip } from '@chakra-ui/react';
import { formatNumber } from '@/utils/format';
import { Status } from '../interfaces/types';
import { useDashBoard } from '../contexts/DashBoardContext';
import { mockInfo } from '@/mocks/mockData';
import { InfoOutlineIcon } from '@chakra-ui/icons';

export type infoParams = { title: string; balance: number; status: Status; tooltip?: string };
export type PlatformInfoParams = { info?: infoParams[]; isDemo?: boolean };

function PlatformInfo({ info, isDemo }: PlatformInfoParams) {
  // console.log('🚀 ~ file: PlatformInfo.tsx:13 ~ PlatformInfo ~ info:', info);
  const { globalStatus } = useDashBoard();
  const infos = isDemo ? mockInfo.infos : info;
  return (
    <Grid
      display={{
        base: 'grid',
        md: 'flex',
      }}  
      justifyContent="space-between"
      gap="8px"
      mb="24px"
      templateColumns="repeat(2, 1fr)"
    >
      {infos?.map((item, index) => (
        <BlockInfo key={index}>
          <Flex justifyItems="center" alignItems="center" flexDirection="column">
            <Tooltip
              hasArrow
              placement="bottom-start"
              label={item.tooltip}
              borderRadius="10px"
              bg="rgba(255, 255, 255, 0.8)"
              color="gray.900"
              fontSize="12px"
              maxWidth="150px"
              padding="8px"
              textAlign="center"
              transition="opacity 0.5s ease-in-out"
            >
              <div className="flex justify-center items-center pb-2">
                <Text fontSize="xs" color="#9ba2a9">
                  {item.title}
                </Text>
                <Icon as={InfoOutlineIcon} mx={1} color="#9ba2a9" fontSize="xs" />
              </div>
            </Tooltip>

            <Text fontSize="xl" fontWeight="bold">
              {`$${
                (item.status === 'loading' || item.status === 'idle') && !isDemo
                  ? ' --'
                  : formatNumber(Number(item.balance))
              }`}
            </Text>
          </Flex>
        </BlockInfo>
      ))}
    </Grid>
  );
}

export default PlatformInfo;
