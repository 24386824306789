import { useBluwhale } from '@/context/BluwhaleContext';
import { AppDispatch, RootState } from './store';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { setEnterpriseOutReachUsers } from './enterpriseOutReachUsers';
import { getOutReachList } from '../pages/api/enterpriseapi';
import { auth } from '@/configs/firebase';

export const useOutReachList = () => {
  const user = typeof window !== 'undefined' ? sessionStorage.getItem('firebaseUser') : undefined;

  const dispatch = useDispatch<AppDispatch>();
  const [status, setStatus] = useState('idle');
  const { user: BluUser, axiosInstance } = useBluwhale();
  const enterpriseDetail = useSelector((state: RootState) => state.enterpriseDetail.enterpriseDetail);
  const { owned_project } = enterpriseDetail ?? {};
  const outReachList = useSelector((state: RootState) => state.enterpriseOutReachUsers.enterpriseOutReachUsers);

  const changeEnterpriseOutReachList = useCallback(
    (details: any) => {
      dispatch(setEnterpriseOutReachUsers(details));
    },
    [dispatch, setEnterpriseOutReachUsers],
  );

  async function fetchData() {
    if (owned_project) {
      try {
        if (!getEnable()) {
          return undefined;
        }
        setStatus('loading');
        const result = await getOutReachList(axiosInstance);
        setStatus('succeeded');
        await changeEnterpriseOutReachList(result);
      } catch (error) {
        setStatus('failed');
        console.error('Error fetching campaign list:', error);
      }
    }
  }

  async function getEnable() {
    const currentUser = await auth.currentUser;
    return (currentUser && user && BluUser?.user_type === 'enterprise') || false;
  }
  useEffect(() => {
    if (!outReachList && status === 'idle') {
      fetchData();
    }
  }, [outReachList, status]);

  const refetch = () => {
    fetchData();
  };

  return { data: outReachList, status, outReachList, refetch };
};
