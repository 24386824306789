import { WalletNativeBalanceResponse } from '@/types/bluwhaleDataApi';
import axios from 'axios';

/**
 * Get the balance of the native token
 * @param _url {string} - The url of the API {basicUrl}/wallets/balance/native/
 * @param _address {string} - The address of the wallet {0x...
 * @returns
 * @throws
 * @example
 * ```ts
 * const url = 'https://api.bluwhale.com/wallets/balance/native/';
 * const address = '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984';
 * const data = await getNativeBalance(url, address);
 * console.log(data);
 * ```
 */
async function getNativeBalance(_url: string, _address: string): Promise<WalletNativeBalanceResponse> {
  try {
    const body = { wallet: _address };
    const response = await axios.post(_url, body);
    const json = response.data;
    return json;
  } catch (e) {
    console.log(e);
    throw e;
  }
}

export { getNativeBalance };
