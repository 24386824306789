/* eslint-disable complexity */
import React, { useState } from 'react';
import Image from 'next/image';
import { BlockChart } from '../common';
import { Box, Text, Flex, Avatar, Progress, Grid, GridItem, Tooltip, Icon } from '@chakra-ui/react';
import { ChainNative } from '../hooks/useGetNativeBalance';
import { formatNumber, separator } from '@/utils/format';
import NoData from './NoData';
import { Status } from '../interfaces/types';
import LoadingLayout from '../common/LoadingLayout';
import Loading from './Loading';
import { useDashBoard } from '../contexts/DashBoardContext';
import { mockChainAlloction } from '@/mocks/mockData';
import { InfoOutlineIcon } from '@chakra-ui/icons';

export type ChartChainAlloctionParams = {
  totalBalanceUsd: number | string;
  list?: Array<ChainNative>;
  status?: Status;
};

function ChartChainAlloction({
  datas: chainData,
  status,
  isDemo = false,
}: {
  datas?: ChartChainAlloctionParams;
  status: Status;
  isDemo?: boolean;
}) {
  // console.log('🚀 ~ file: ChartChainAlloction.tsx:29 ~ chainData:', chainData);
  const [chainAlloctionList, setChainAlloctionList] = useState([
    {
      tokenId: '1',
      tokenImg: 'https://bit.ly/dan-abramov',
      tokenName: 'bicon',
      amoiunt: '8',
      percentage: 70,
    },
    {
      tokenId: '2',
      tokenImg: 'https://bit.ly/dan-abramov',
      tokenName: 'bicon1',
      amoiunt: '6782345678',
      percentage: 50,
    },
    {
      tokenId: '3',
      tokenImg: 'https://bit.ly/dan-abramov',
      tokenName: 'bicon2',
      amoiunt: '123',
      percentage: 20,
    },
    {
      tokenId: '4',
      tokenImg: 'https://bit.ly/dan-abramov',
      tokenName: 'bicon3',
      amoiunt: '122345678',
      percentage: 10,
    },
    {
      tokenId: '5',
      tokenImg: 'https://bit.ly/dan-abramov',
      tokenName: 'bicon',
      amoiunt: '123456782345678',
      percentage: 50,
    },
  ]);
  const { globalStatus } = useDashBoard();
  // const isLoading = globalStatus === 'loading' || status === 'loading' || status === 'idle';
  const isLoading = status === 'loading' || status === 'idle';
  const isComplete = globalStatus === 'idle' && status === 'success';
  const isError = globalStatus === 'idle' || status === 'error';
  const datas = isDemo ? mockChainAlloction : chainData;

  if (isLoading && !isDemo) {
    return (
      <BlockChart>
        <Tooltip
          hasArrow
          placement="bottom-start"
          label="The chains this profile utilizes"
          borderRadius="10px"
          bg="rgba(255, 255, 255, 0.8)"
          color="gray.900"
          fontSize="12px"
          maxWidth="150px"
          padding="8px"
          textAlign="center"
          transition="opacity 0.5s ease-in-out"
        >
          <div className="flex items-center mb-3 font-bold text-lg">
            <div className="text-lg font-bold "> Chain Utilization</div>
            <Icon as={InfoOutlineIcon} mx={1} color="#9ba2a9" fontSize="xs" />
          </div>
        </Tooltip>

        <div className="relative flex items-center justify-center h-full">
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <Loading />
          </div>
        </div>
      </BlockChart>
    );
  }
  return (
    <BlockChart>
      <Tooltip
        hasArrow
        placement="bottom-start"
        label="The chains this profile utilizes"
        borderRadius="10px"
        bg="rgba(255, 255, 255, 0.8)"
        color="gray.900"
        fontSize="12px"
        maxWidth="150px"
        padding="8px"
        textAlign="center"
        transition="opacity 0.5s ease-in-out"
      >
        <div className="flex items-center mb-3 font-bold text-lg">
          <div className="text-lg font-bold "> Chain Utilization</div>
          <Icon as={InfoOutlineIcon} mx={1} color="#9ba2a9" fontSize="xs" />
        </div>
      </Tooltip>

      {datas?.list && datas?.list.length > 0 && datas?.totalBalanceUsd !== 0 ? (
        <Grid
          display={{
            base: 'flex',
            md: 'grid',
          }}
          templateColumns="repeat(2, 1fr)"
          columnGap="48px"
          rowGap="16px"
          px="6"
          py="1"
          alignItems="center"
          flex="1"
          h="100%"
          flexDir={'column'}
        >
          {datas?.list.map((item, index) => {
            const balance = separator(
              (Number(datas?.totalBalanceUsd) === 0
                ? 0
                : Number(item.tokenBalance?.balanceUsd) / Number(datas?.totalBalanceUsd ?? 0)) * 100,
              2,
            );
            return (
              <React.Fragment key={index}>
                {/* @ts-ignore */}
                <GridItem w="100%" key={item.chain.chain.apiHex + index}>
                  <Flex w="100%" alignItems="center" justifyContent="space-between">
                    <Flex alignItems="center" mb="1">
                      <Avatar size="xs" src={item.chain.icon} />
                      <Text fontSize="xs" fontWeight="bold" pl="2">
                        {item.chain.title}
                      </Text>
                    </Flex>
                    <Text fontSize="xs" fontWeight="bold">
                      ${formatNumber(Number(item.tokenBalance?.balanceUsd ?? 0) ?? 0)}
                    </Text>
                  </Flex>
                  <Flex alignItems="center">
                    <Box flexGrow={1} mr="2">
                      <Progress
                        size="sm"
                        value={Number(
                          separator(
                            (Number(datas?.totalBalanceUsd ?? 0) === 0
                              ? 0
                              : Number(item.tokenBalance?.balanceUsd ?? 0) / Number(datas?.totalBalanceUsd ?? 100)) *
                              100,
                            2,
                          ),
                        )}
                      />
                    </Box>
                    <Text w="49px" fontSize="xs" fontWeight="bold" textAlign="right">
                      {`${balance}%`}
                    </Text>
                  </Flex>
                </GridItem>
              </React.Fragment>
            );
          })}
        </Grid>
      ) : (
        <NoData />
      )}
    </BlockChart>
  );
}

export default ChartChainAlloction;
