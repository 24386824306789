import { useQuery } from 'react-query';
import { Status } from '../interfaces/types';
import { getAttrubutes } from '../api';

export function useGetAttributesData({ address, isExcute = true }: { address: string; isExcute?: boolean }) {
  const query = useQuery({
    enabled: isExcute,
    queryKey: ['attributes', address],
    queryFn: async () => getAttrubutes(address),
  });
  const { data, status } = query;
  const _status = status as Status;
  return { data, status: _status };
}
