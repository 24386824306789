import { Status } from '../interfaces/types';
import { useGetNativeBalanceOut } from './useGetNativeBalance';

export type UseGenerateUIChartChainAlloction = {
  nativeDto?: useGetNativeBalanceOut;
  nativeStatus?: Status;
};
export default function useGenerateUIChartChainAlloction({
  nativeDto,
  nativeStatus,
}: UseGenerateUIChartChainAlloction) {
  const chainAlloctionUiDto = {
    list: nativeDto?.balanceList ?? [],
    totalBalanceUsd: nativeDto?.totalBalanceUsd ?? 0,
    status: nativeStatus ?? 'idle',
  };
  return { chainAlloctionUiDto, status: nativeStatus ?? 'idle' };
}
