/* eslint-disable no-await-in-loop */
/* eslint-disable consistent-return */
import { useEffect, useState } from 'react';
import { Status } from '../interfaces/types';
import { EvmChain } from 'moralis/common-evm-utils';
import Moralis from 'moralis';
import { Chain, chainList } from '../configs/chain';
import { MoralisConfig } from '@moralisweb3/react';
import { calcUsdByWei, fetchTokenPrice } from '../utils/moralisUtils';
import { TokenType } from '../configs/wrappedToken';
import { useHandleStatus } from './useHandleStatus';
import { delay } from '../utils/helper';
import axios from 'axios';
import { basicConfig } from '@/configs';
import { WalletNativeBalanceResponse } from '@/types/bluwhaleDataApi';
import { getNativeBalance } from '../api';

export type TokenBalance = {
  token: TokenType;
  balance: number | string;
  balanceUsd?: number | string;
  usdPrice: number;
};
export type ChainNative = {
  chain: Chain;
  tokenBalance?: TokenBalance;
  raw?: any;
};

export type useGetNativeBalanceOut = {
  balanceList?: Array<ChainNative>;
  balanceUsdBySymbol?: Map<string, number>;
  totalBalanceUsd?: string | number;
  totalChainCounts?: string | number;
};

export function useGetNativeBalance(address?: string, preStatus?: Status) {
  const defaultList = chainList.map((chain) => {
    return { chain };
  });
  // const { status, excuteHandler, errorHandler, completeHandler } = useHandleStatus();
  const [balanceList, setBalanceList] = useState<Array<ChainNative>>(defaultList);
  // const [balanceUsdBySymbol, setBalanceUsdBySymbol] = useState<Map<string, number>>();
  // const [totalBalanceUsd, setTotalBalanceUsd] = useState<string | number>();
  // const [totalChainCounts, setTotalChainCounts] = useState<string | number>();

  const [results, setResults] = useState<useGetNativeBalanceOut>();
  const [status, setStatus] = useState<Status>('idle');

  function init() {
    setBalanceList((pre) => defaultList);
    // setBalanceUsdBySymbol((pre) => undefined);
    // setTotalBalanceUsd((pre) => 0);
    // setTotalChainCounts((pre) => 0);
    setResults((pre) => undefined);
    setStatus((pre) => 'loading');
  }

  function calcSumBalance(list: ChainNative[]) {
    return list.reduce((sum, item) => sum + Number(item.tokenBalance?.balanceUsd ?? 0), 0);

    // setTotalBalanceUsd((pre) => balanceUsd);
  }

  function generateBySymbol(list: ChainNative[]) {
    const symbolMap = new Map<string, number>();
    list.forEach((item) => {
      if (!item.tokenBalance) {
        return;
      }
      if (!item.tokenBalance.balanceUsd) {
        return;
      }

      const symbol = item.tokenBalance.token.symbol.toLowerCase();
      const balanceUsd = Number(item.tokenBalance.balanceUsd);

      // symbolMap.set(item.tokenBalance?.token.symbol.toLowerCase(), Number(item.tokenBalance.balanceUsd));
      //fix some chain has same symbol
      if (symbolMap.has(symbol)) {
        symbolMap.set(symbol, (symbolMap.get(symbol) ?? 0) + balanceUsd);
      } else {
        symbolMap.set(symbol, balanceUsd);
      }
    });

    return symbolMap;
  }
  function getChainHasBalanceCounts(list: ChainNative[]) {
    const counts = list.filter((chain) => Number(chain.tokenBalance?.balance ?? 0) !== 0).length;
    return counts;
  }

  async function fetchBalance(_address: string, chain: EvmChain) {
    const response = await Moralis.EvmApi.balance.getNativeBalance({ chain: chain.apiHex, address: _address });
    return { ...response.toJSON() };
  }
  async function fetchBalanceTask(
    chainItem: Chain,
    _address: string,
    priceBySymbol?: Map<string, number>,
  ): Promise<ChainNative> {
    const balanceTask = fetchBalance(_address, chainItem.chain);
    const priceTask = fetchTokenPrice(chainItem.wrappedToken.address, chainItem);
    const _results = await Promise.allSettled([balanceTask, priceTask]);

    const balanceOb = _results[0];
    const priceOb = _results[1];

    const balance = balanceOb.status !== 'rejected' ? balanceOb.value.balance : '0';
    const { decimal } = chainItem.wrappedToken;
    const _key = chainItem.wrappedToken.symbol.toLowerCase();
    if (priceOb.status !== 'rejected') {
      if (!priceBySymbol?.has(_key)) {
        priceBySymbol?.set(_key, priceOb.value.usdPrice);
      }
    }
    const usdPrice = priceBySymbol?.has(_key) ? priceBySymbol?.get(_key) ?? 0 : 0;
    const balanceUsd = calcUsdByWei(balance, decimal, usdPrice);
    const tokenBalance: TokenBalance = {
      token: chainItem.wrappedToken,
      balance,
      balanceUsd: balanceUsd.toString(),
      usdPrice,
    };

    return { chain: chainItem, tokenBalance, raw: results };
  }
  async function fetchNativeBalance(_address: string): Promise<WalletNativeBalanceResponse> {
    const url = `${basicConfig.bluwhale.walletAPIUrl}/wallets/balance/native/`;
    return getNativeBalance(url, _address);
  }
  function generateData(_address: string, res: WalletNativeBalanceResponse): Array<ChainNative> {
    _address = _address.toLowerCase();
    const result = new Array<ChainNative>();
    const { data } = res;
    const _data = data[_address];
    _data?.forEach((item) => {
      const chain = chainList.find((_chain) => _chain.bluApiChain === item.platformId);
      if (!chain) {
        return;
      }
      const token = chain.wrappedToken;
      const { balance, balanceUsd } = item;
      const usdPrice = Number(item.price);
      const tokenBalance: TokenBalance = {
        token,
        balance,
        balanceUsd,
        usdPrice,
      };
      result.push({ chain, tokenBalance, raw: item });
    });
    return result;
  }
  async function fetchNativeBalanceFromBluwhaleApi(_address: string): Promise<Array<ChainNative>> {
    try {
      const res = await fetchNativeBalance(_address);
      const _data = generateData(_address, res);
      return _data;
    } catch (e) {
      console.error('fetchNativeBalanceFromBluwhaleApi', e);
      return new Array<ChainNative>();
    }
  }
  async function fetchBalanceTasks(_address: string) {
    const tasks: Promise<any>[] = [];
    //   chainList.map((chainItem) => {
    //     tasks.push(fetchBalanceTask(chainItem));
    //   });
    //   let results = await Promise.all(tasks);
    const counts = chainList.length;
    const _results = Array<ChainNative>();
    const priceBySymbol = new Map<string, number>();
    for (let i = 0; i < counts; i++) {
      const result = await fetchBalanceTask(chainList[i], _address, priceBySymbol);
      _results.push(result);
    }
    // setBalanceList((pre) => results);
    return _results;
  }

  async function excute(_address: string): Promise<useGetNativeBalanceOut | undefined> {
    init();
    const _balanceList = await fetchNativeBalanceFromBluwhaleApi(_address);
    // const _balanceList = await fetchBalanceTasks(_address);
    const _balanceUsdBySymbol = generateBySymbol(_balanceList);
    const _totalChainCounts = getChainHasBalanceCounts(_balanceList);
    // setTotalChainCounts((pre) => counts);
    // setBalanceUsdBySymbol((pre) => symbolMap);
    const _totalBalanceUsd = calcSumBalance(_balanceList);
    // await delay(1);
    const _results = {
      balanceList: _balanceList,
      balanceUsdBySymbol: _balanceUsdBySymbol,
      totalChainCounts: _totalChainCounts,
      totalBalanceUsd: _totalBalanceUsd,
    };
    setResults(_results);
    setStatus((pre) => 'success');
    // throw new Error('429');

    return _results;
  }

  useEffect(() => {
    // async function fetchTokenPrice(address: string, chain: EvmChain) {
    //   try {
    //     let price = await Moralis.EvmApi.token.getTokenPrice({ address, chain: chain.apiHex });
    //     return price.toJSON();
    //   } catch (e) {
    //     return { usdPrice: 0 };
    //   }
    // }

    if (preStatus && preStatus !== 'success' && preStatus !== 'error') {
      return;
    }
    if (!address) {
      return;
    }
    excute(address);
  }, [address, preStatus]);

  return {
    status,
    //  balanceList, totalBalanceUsd, balanceUsdBySymbol, totalChainCounts,
    results,
    preStatus,
    onExcute: excute,
    onInit: init,
    fetchNativeBalanceFromBluwhaleApi,
  };
}
