/* eslint-disable default-param-last */
/* eslint-disable consistent-return */
/* eslint-disable no-promise-executor-return */
/* eslint-disable no-await-in-loop */
import moment from 'moment';
import Moralis from 'moralis';
import { EvmChain } from 'moralis/common-evm-utils';
import { MoralisBlock } from '../interfaces/types';

const staticBolock = new Map<string, any>();
// console.log('staticBolock');
const init = () => {
  staticBolock.clear();
};
const delay = (n: number) => new Promise((r) => setTimeout(r, n * 1000));

const randomInt = (max = 10, min = 1) => Math.floor(Math.random() * (max - min + 1) + min);

const calcPercent = (num: number, maxNum: number, percentMax: number) => {
  if (num >= maxNum) {
    return percentMax;
  }
  const res = (percentMax * num) / maxNum;
  return res;
  // return res < 1 ? 0.1 : res;
};

const fetchBlockByMoralis = async (chain: EvmChain, date: string) => {
  const res = await Moralis.EvmApi.block.getDateToBlock({ date, chain: chain.apiHex });
  return res.toJSON();
};
const getLastDayOfMonthBeforeTodayTimeStmp = (months = 3): number[] => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();

  const lastDaysOfMonth: number[] = [];

  for (let month = 0; month <= months; month++) {
    const targetMonth = currentMonth - month;
    const targetYear = currentYear - Math.floor(targetMonth / 12);
    const lastDayOfMonth = new Date(targetYear, targetMonth - 1, 0);
    const timestamp = Math.floor(lastDayOfMonth.getTime() / 1000);
    lastDaysOfMonth.push(timestamp);
  }
  return lastDaysOfMonth;
};
// const getLastDayOfMonthBeforeToday = (months: number = 3): string[] => {
//   const today = new Date();
//   const currentYear = today.getFullYear();
//   const currentMonth = today.getMonth();

//   const lastDaysOfMonth: string[] = [today.toISOString()];

//   for (let month = 0; month <= months; month++) {
//     const targetMonth = currentMonth - month;
//     const targetYear = currentYear - Math.floor(targetMonth / 12);
//     const lastDayOfMonth = new Date(targetYear, targetMonth - 1, 1);
//     lastDaysOfMonth.push(lastDayOfMonth.toISOString());
//   }
//   return lastDaysOfMonth;
// };

function getLastDayOfMonth(year: number, month: number) {
  const lastDayOfNextMonth = new Date(year, month + 1, 0);
  return lastDayOfNextMonth.getDate();
}

const getLastDayOfMonthBeforeToday = (months = 3) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const lastDays = [];
  lastDays.push(currentDate);
  for (let i = 1; i < months; i++) {
    let year = currentYear;
    let month = currentMonth - i;
    if (month < 0) {
      year--;
      month += 12;
    }
    const lastDay = getLastDayOfMonth(year, month);
    const lastDateOfMonth = new Date(year, month, lastDay);
    lastDays.push(lastDateOfMonth);
  }

  return lastDays;
};

const _getBlockNumberList = async (
  chain: EvmChain,
  timestamp: number[] | string[] | Date[],
  staticBolockRef: Map<string, any>,
) => {
  const counts = timestamp.length;
  const tasks = [];
  const result: MoralisBlock[] = [];
  for (let i = 0; i < counts; i++) {
    const date = timestamp[i].toString();
    const key = `${chain.apiHex}${date}`;

    if (staticBolockRef.has(key)) {
      result.push(staticBolockRef.get(key));
      continue;
    }
    // date = moment(Number(timestamp[i]) * 1000).format('YYYY-MM-DD HH:mm:ss');
    const res = await fetchBlockByMoralis(chain, date);
    staticBolockRef.set(key, res);

    if (res) {
      result.push(res);
    }
    // tasks.push(fetchBlockByMoralis(chain, date));
  }
  // result = await Promise.all(tasks);
  return result;
};
const getBlockNumberListByTimeStmps = async (
  chain: EvmChain,
  months = 3,
  staticBolockRef: Map<string, any>,
): Promise<MoralisBlock[]> => {
  // let timestamps = getLastDayOfMonthBeforeTodayTimeStmp(months);
  const dates = getLastDayOfMonthBeforeToday(months);
  const result = await _getBlockNumberList(chain, dates, staticBolockRef);
  return result;
};
const getBlockNumberListByDate = async (chain: EvmChain, months = 3, staticBolockRef: Map<string, any>) => {
  const timestamps = getLastDayOfMonthBeforeToday(months);
  const result = await _getBlockNumberList(
    chain,
    timestamps,
    staticBolockRef,
    // timestamps.map((date) => date.getTime()),
  );
  return result;
};
const getBlockNumberList = async (chain: EvmChain, months = 3, staticBolockRef: Map<string, any>) => {
  return getBlockNumberListByDate(chain, months, staticBolockRef);
};
const getBlockNumberFromDate = async (chain: EvmChain, date: Date, staticBolockRef: Map<string, any>) => {
  return _getBlockNumberList(chain, [date], staticBolockRef);
};

const getDateFromToByDays = (days = 1, format = 'YYYY-MM-DD', date?: Date) => {
  const currentDate = !date ? new Date() : new Date(date);
  const daysAgoDate = !date ? new Date() : new Date(date);
  daysAgoDate.setDate(daysAgoDate.getDate() - days);
  const _currentDate = moment(currentDate).format(format);
  const _daysAgoDate = moment(daysAgoDate).format(format);

  return {
    currentDate: _currentDate,
    daysAgoDate: _daysAgoDate,
  };
};
const getDateFromToByMonths = (months = 1, date?: Date) => {
  // const format = 'YYYY-MM-DD HH:mm:ss';
  const format = 'YYYY-MM-DD';
  const currentDate = !date ? new Date() : new Date(date);
  const monthsAgoDate = !date ? new Date() : new Date(date);
  monthsAgoDate.setMonth(monthsAgoDate.getMonth() - months);
  const _currentDate = moment(currentDate).format(format);
  const _monthsAgoDate = moment(monthsAgoDate).format(format);

  return {
    currentDate: _currentDate,
    monthsAgoDate: _monthsAgoDate,
  };
};

const getNumber = (num?: string | number) => {
  return Number(num ?? 0);
};

function formatCurrency(input: number | string): string {
  const num = typeof input === 'string' ? parseFloat(input) : input;
  const roundedNum = Math.round(num * 100) / 100;
  return roundedNum.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

export {
  init,
  delay,
  randomInt,
  calcPercent,
  getBlockNumberList,
  getLastDayOfMonthBeforeToday,
  getLastDayOfMonthBeforeTodayTimeStmp,
  getBlockNumberListByTimeStmps,
  getBlockNumberFromDate,
  // getBlockNumberListByDate,
  getDateFromToByMonths,
  getDateFromToByDays,
  staticBolock,
  getNumber,
  formatCurrency,
};
