import { formatNumber } from '@/utils/format';
import { useGetNativeBalanceOut } from './useGetNativeBalance';
import { useGetTokenBalancesNOut } from './useGetTokenBalancesN';
import { useEffect, useState } from 'react';
import { ChartTokenAlloctionParams } from '../elements/ChartTokenAlloction';
import { Status } from '../interfaces/types';
import { useDashBoard } from '../contexts/DashBoardContext';
import { delay } from '../utils/helper';

export type useGenerateUIChartTokenAlloctionParams = {
  nativeDto?: useGetNativeBalanceOut;
  tokenDto?: useGetTokenBalancesNOut;
};

export default function useGenerateUIChartTokenAlloction({
  nativeDto,
  tokenDto,
}: useGenerateUIChartTokenAlloctionParams) {
  const { walletAddress: address } = useDashBoard();
  //TODO
  const [dto, setDto] = useState<ChartTokenAlloctionParams>();
  const [status, setStatus] = useState<Status>('idle');

  //#region ChartTokenAlloction
  function calcTokenChartTokenAlloction(balances?: Map<string, number>) {
    if (!balances) {
      return [];
    }
    const sortedBalances = Array.from(balances.entries())
      .sort((a, b) => b[1] - a[1])
      .slice(0, 5);
    const totalSum = balances ? Array.from(balances.values()).reduce((sum, balance) => sum + balance, 0) : 0;
    if (totalSum === 0) {
      return [];
    }
    const proportions = sortedBalances.map((entry) => ({
      // key
      key: entry[0],
      // value
      value: formatNumber(entry[1]),
      // percent
      percent: parseFloat(((entry[1] / totalSum) * 100).toFixed(2)),
    }));
    const remainingSum =
      Array.from(balances.values()).reduce((sum, balance) => sum + balance, 0) -
      sortedBalances.reduce((sum, entry) => sum + entry[1], 0);
    const remainingProportion = parseFloat(((remainingSum / totalSum) * 100).toFixed(2));
    const others = { key: 'others', value: formatNumber(remainingSum), percent: remainingProportion };
    const result = proportions.concat(others);
    return result;
  }
  function mergeMapChartTokenAlloction(entities: (Map<string, number> | undefined)[]) {
    const mergedMap = new Map<string, number>();

    entities.forEach((entity) => {
      if (!entity) {
        return;
      }
      entity.forEach((value, key) => {
        mergedMap.set(key, (mergedMap.get(key) || 0) + value);
      });
    });
    return mergedMap;
  }
  function excuteChartTokenAlloction(
    _native: Map<string, number> | undefined,
    _token: Map<string, number> | undefined,
  ) {
    const mapList: (Map<string, number> | undefined)[] = [];
    mapList.push(_native);
    mapList.push(_token);
    const balances = mergeMapChartTokenAlloction(mapList);
    const list = calcTokenChartTokenAlloction(balances);
    return { balances, list };
  }
  //#endregion

  function init() {
    setDto(undefined);
    setStatus((pre) => 'loading');
  }

  function excute(_nativeDto: useGetNativeBalanceOut, _tokenDto: useGetTokenBalancesNOut) {
    const _datas = excuteChartTokenAlloction(_nativeDto.balanceUsdBySymbol, _tokenDto.balanceSumBySymbol?.list);
    const result = {
      datas: _datas,
      // status: chainAlloctionStatus,
    };
    // @ts-ignore
    setDto(result);
    delay(0.5).then(() => setStatus((pre) => 'success'));
    return result;
  }
  useEffect(() => {
    if (!address) {
      return;
    }
    if (!nativeDto || !tokenDto) {
      return;
    }
    excute(nativeDto, tokenDto);
  }, [address, nativeDto, tokenDto]);

  return { onExcute: excute, onInit: init, status, dto, setStatus };
}
