import { useEffect, useRef, useState } from 'react';
import { Status } from '../interfaces/types';
import { useDashBoard } from '../contexts/DashBoardContext';

export default function useHandleError(statusList?: Array<Status>, fn?: any) {
  const { walletAddress } = useDashBoard();
  const [hasError, setHasError] = useState<boolean>(false);
  const isInitRef = useRef<boolean>(false);
  function checkHasError(_statusList: Array<Status>) {
    if (hasError) {
      return true;
    }
    return _statusList.findIndex((status) => status === 'error') !== -1;
  }
  useEffect(() => {
    // console.log('hasError', hasError, statusList);
    if (hasError && fn && typeof fn === 'function') {
      fn();
    }
  }, [hasError]);
  useEffect(() => {
    if (!isInitRef.current) {
      return;
    }
    if (!statusList) {
      return;
    }

    setHasError((pre) => checkHasError(statusList));
  }, [JSON.stringify(statusList)]);
  useEffect(() => {
    // console.log('hasError walletAddress', walletAddress, hasError, statusList);

    setHasError((pre) => false);
    isInitRef.current = !isInitRef.current;
  }, [walletAddress]);
}
