import axios from 'axios';
import { blue_whale_api } from '../configs';
import { useQuery } from 'react-query';
import { json } from 'stream/consumers';

export type SimalarWalletType = {
  address: string;
  avatar?: string;
  similar_score: number;
};

export function useGetWalletList({ address }: { address: string }) {
  function getData(_address: string) {
    return axios({
      method: 'post',
      url: `${blue_whale_api.simalar_wallets}`,
      data: {
        wallet: _address,
      },
    }).then((res) => {
      // console.log('🚀 ~ file: useGetWalletList.ts:21 ~ getData ~ res:', res);

      return res.data as { wallets: Array<SimalarWalletType> };
    });
  }
  const query = useQuery({
    queryKey: ['walletlist', address],
    queryFn: async () => getData(address),
  });
  const { data, status } = query;

  return { data, status };
}
