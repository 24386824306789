export type DefaultTokenBalance = {
  token: TokenType;
  balance: string;
  Usd?: string;
  balanceFormat?: string;
};

export type TokenType = {
  key: string;
  name: string;
  address: string | `0x${string}`;
  symbol: string;
  logo: string;
  decimal: number;
  chainId: `0x${string}` | number;
};

const wrappedBNB: TokenType = {
  key: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  name: 'Binance ',
  address: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  symbol: 'BNB',
  logo: '/images/bnb-logo.png',
  decimal: 18,
  chainId: '0x38',
};
const wrappedETH: TokenType = {
  key: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  name: 'Etherem ',
  address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  symbol: 'ETH',
  logo: '/images/eth-logo.png',
  decimal: 18,
  chainId: '0x01',
};
const wrappedMATIC: TokenType = {
  key: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  name: 'MATIC',
  address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  symbol: 'MATIC',
  logo: '/images/matic-logo.png',
  decimal: 18,
  chainId: '0x89',
};
const wrappedAVAX: TokenType = {
  key: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
  name: 'AVAX',
  address: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
  symbol: 'AVAX',
  logo: '/images/avax-logo.png',
  decimal: 18,
  chainId: '0xa86a',
};
const wrappedFTM: TokenType = {
  key: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
  name: 'FTM',
  address: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
  symbol: 'FTM',
  logo: '/images/ftm-logo.png',
  decimal: 18,
  chainId: '0xfa',
};

const wrappedARBITRUM: TokenType = {
  key: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
  name: 'Etherem ',
  address: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
  symbol: 'ETH',
  logo: '/images/eth-logo.png',
  decimal: 18,
  chainId: '0xA4B1',
};
// const wrappedCRO: TokenType = {
//   key: '0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23',
//   name: 'CRO',
//   address: '0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23',
//   symbol: 'CRO',
//   logo: '/images/CRONOS.png',
//   decimal: 18,
//   chainId: '0x19',
// };

// //#region Test
// const wrappedTBNB: TokenType = {
//   key: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
//   name: 'Binance ',
//   address: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
//   symbol: 'BNB',
//   logo: '/images/bnb-logo.png',
//   decimal: 18,
//   chainId: '0x61',
// };
// //#endregion

enum WRAPPEDTOKEN {
  ETHEREUM = 'ETHEREUM',
  BSC = 'BSC',
  FANTOM = 'FANTOM',
  AVALANCHE = 'AVALANCHE',
  POLYGON = 'POLYGON',
  ARBITRUM = 'ARBITRUM',
}
const wrappedToken: { [key in WRAPPEDTOKEN]: TokenType } = {
  [WRAPPEDTOKEN.ETHEREUM]: wrappedETH,
  [WRAPPEDTOKEN.BSC]: wrappedBNB,
  [WRAPPEDTOKEN.FANTOM]: wrappedFTM,
  [WRAPPEDTOKEN.AVALANCHE]: wrappedAVAX,
  [WRAPPEDTOKEN.POLYGON]: wrappedMATIC,
  [WRAPPEDTOKEN.ARBITRUM]: wrappedARBITRUM,
};

export { wrappedToken as WRAPPEDTOKEN };
