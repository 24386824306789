'use client';
import ChartNetworthBarChart from './ChartNetworthBarChart';
import ChartChainAlloction from './ChartChainAlloction';
import ChartTokenAllocation from './ChartTokenAlloction';
import ProtocolAllocation from './ChartProtocolAllocation';
import TransactionHistory from './TransactionHistory';
import InfoPlatform from './PlatformInfo';
import UserPlatform from './PlatformUser';
// import ChartBar from './ChartBar';
import ChartRadar from './ChartRadar';
import NftPlatform from './PlatformNft';
import Platform from './Platform';
import PlatformChart from './PlatformChart';
import ChartCredit from './ChartCredit';
import dynamic from 'next/dynamic';
const ChartBar = dynamic(() => import('./ChartBar'), { ssr: false });

export {
  InfoPlatform,
  ChartBar,
  ChartRadar,
  ChartNetworthBarChart,
  ChartChainAlloction,
  ChartTokenAllocation,
  ProtocolAllocation,
  PlatformChart,
  TransactionHistory,
  NftPlatform,
  Platform,
  UserPlatform,
  ChartCredit,
};
