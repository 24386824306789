/* eslint-disable complexity */
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Status } from '../interfaces/types';
import Loading from './Loading';
import { useDashBoard } from '../contexts/DashBoardContext';
import { Icon, Tooltip } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';

export type ChartCreditParams = {
  crditScore: number;
  crditPercent: number;
  color: string;
  textColor?: string;
};
const defaultParams = {
  crditScore: 0,
  crditPercent: 0,
  color: '393939',
};
function ChartCredit({
  params = defaultParams,
  status,
  isDemo = false,
}: {
  params?: ChartCreditParams;
  status: Status;
  isDemo?: boolean;
}) {
  const { crditScore, crditPercent, color, textColor } = params;
  const { walletAddress: address, globalStatus } = useDashBoard();

  const tagIsLoading = (globalStatus === 'loading' || status === 'loading') && !isDemo;
  const tagIsComplete = globalStatus === 'idle' && status === 'success';
  const tagIsError = globalStatus === 'idle' || status === 'error';

  const datasetsData = [tagIsLoading && !isDemo ? 0 : crditPercent, 100 - crditPercent];
  // const datasetsBackgroundColor = [color, tagIsLoading ? '#393939' : '#ecf0f1'];
  const datasetsBackgroundColor = [color, '#393939'];
  const data = {
    datasets: [
      {
        data: datasetsData,
        backgroundColor: datasetsBackgroundColor,
        display: true,
        borderWidth: 0,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    rotation: -90,
    circumference: 180,
    cutout: '85%',
    responsive: true,
  };

  const colorArr = ['text-[#EB4700]', 'text-[#EBA900]', 'text-[#E6EB00]', 'text-[#00F0FF]'];

  return (
    <div className="relative p-3">
      <Doughnut data={data} options={options} className="h-[124px]" />
      <div className="absolute top-[60%] left-[50%] -translate-x-1/2 -translate-y-1/2 text-center">
        <div className={tagIsLoading ? 'text-white' : textColor}>
          {/* <div className={`text-[#EB4700]"`}> */}
          {tagIsLoading ? (
            <Loading isShowContent={false} h={32} w={32} />
          ) : (
            <div className="text-3xl">{crditScore}</div>
          )}
          <Tooltip
            hasArrow
            placement="bottom-start"
            label="Crypto score is evaluated based on net worth + NFT valuation."
            borderRadius="10px"
            bg="rgba(255, 255, 255, 0.8)"
            color="gray.900"
            fontSize="12px"
            padding="8px"
            textAlign="center"
            transition="opacity 0.5s ease-in-out"
          >
            <div className="text-sm">Crypto Score</div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

export default ChartCredit;
