import React from 'react';
import { useGetWalletList } from '../hooks/useGetWalletList';
import { useDashBoard } from '../contexts/DashBoardContext';
import Loading from './Loading';
import { BlockChart } from '../common';
import { Avatar, Box, ChakraProvider, Progress, Text, Tooltip, extendTheme } from '@chakra-ui/react';
import { separator } from '@/utils/format';
import { useRouter } from 'next/router';
import { mockSimilarWallets } from '@/mocks/mockData';
import { Icon, InfoOutlineIcon } from '@chakra-ui/icons';
import { event } from 'nextjs-google-analytics';
import { events } from '@/constants/analytics';
import { useBluwhale } from '@/context/BluwhaleContext';
type ChartSimalarWalletProps = {
  isDemo?: boolean;
};
function ChartSimalarWallet(props: ChartSimalarWalletProps) {
  const { user } = useBluwhale();
  const { isDemo = false } = props;
  const { pathname } = useRouter();
  const rootUrl = pathname.split('/')[0];
  const { walletAddress: address, globalStatus } = useDashBoard();

  const { data, status } = useGetWalletList({ address });
  const wallets = isDemo ? mockSimilarWallets : data?.wallets;
  const isLoading = globalStatus === 'loading' || status === 'loading' || status === 'idle';
  if (isLoading && !isDemo) {
    return (
      <BlockChart>
        <div className="flex justify-between items-center mb-3">
          <Tooltip
            hasArrow
            placement="bottom-start"
            label="Similar wallets based on on-chain activities"
            borderRadius="10px"
            bg="rgba(255, 255, 255, 0.8)"
            color="gray.900"
            fontSize="12px"
            maxWidth="150px"
            padding="8px"
            textAlign="center"
            transition="opacity 0.5s ease-in-out"
          >
            <div className="flex justify-center items-center pb-2">
              <div className="text-lg font-bold ">Similar Wallets</div>
              <Icon as={InfoOutlineIcon} mx={1} color="#9ba2a9" fontSize="xs" />
            </div>
          </Tooltip>
          <div className="mt-2 text-xs text-white opacity-50">Match %</div>
        </div>
        <div className="relative flex items-center justify-center min-h-[200px] md:h-full ">
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <Loading />
          </div>
        </div>
      </BlockChart>
    );
  }
  const theme = extendTheme({
    components: {
      Progress: {
        baseStyle: {
          filledTrack: {
            bg: '#2f9ef4',
          },
        },
      },
    },
  });
  const clickSimilarWallet = async () => {
    event(events.similar_wallets);
  };
  return (
    <BlockChart>
      <ChakraProvider resetCSS theme={theme}>
        <div className="">
          <div className="flex justify-between items-center mb-3">
            <Tooltip
              hasArrow
              placement="bottom-start"
              label="Similar wallets based on on-chain activities"
              borderRadius="10px"
              bg="rgba(255, 255, 255, 0.8)"
              color="gray.900"
              fontSize="12px"
              maxWidth="150px"
              padding="8px"
              textAlign="center"
              transition="opacity 0.5s ease-in-out"
            >
              <div className="flex justify-center items-center pb-2">
                <div className="text-lg font-bold ">Similar Wallets</div>
                <Icon as={InfoOutlineIcon} mx={1} color="#9ba2a9" fontSize="xs" />
              </div>
            </Tooltip>
            <div className="mt-2 text-xs text-white opacity-50">Match %</div>
          </div>
          <div className="flex flex-col gap-3 max-h-[189px] overflow-y-scroll">
            {wallets &&
              wallets
                // .concat(data?.wallets)
                // .concat(data?.wallets)
                // @ts-ignore
                .map((_data, index) => {
                  // console.log('🚀 ~ file: ChartSimalarWallet.tsx:63 ~ ChartSimalarWallet ~ _data:', _data);

                  return (
                    <div key={index} className="flex justify-between items-center">
                      <div className="flex items-center">
                        <Avatar
                          _before={{
                            content: `""`,
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            bgColor: `#${_data.address.replace('0x', '').substring(0, 6)}`,
                            opacity: '0.5',
                            rounded: '20px',
                          }}
                          // bgImage="/images/default_avatar.png"
                          size="xs"
                          src={!_data.avatar ? '/images/default_avatar.png' : _data.avatar}
                          // src={'/images/default_avatar.png'  }
                        />
                        <div className="pl-2 text-sm underline" onClick={clickSimilarWallet}>
                          <a
                            href={
                              user?.user_type === 'enterprise'
                                ? `/enterprise/userdetail/${_data.address}`
                                : `${rootUrl}/home/${_data.address}`
                            }
                          >
                            {_data.address.slice(0, 6)}...{_data.address.slice(-4)}
                          </a>
                        </div>
                      </div>
                      <Box flexGrow={1} maxW="60px">
                        <Text fontSize="xs" fontWeight="bold" textAlign="end" mb="1px">
                          {`${_data.similar_score}%`}
                        </Text>
                        <Progress
                          rounded="xl"
                          size="sm"
                          value={Number(
                            separator(
                              (Number(_data.similar_score ?? 0) === 0 ? 0 : Number(_data.similar_score ?? 0) / 100) *
                                100,
                              2,
                            ),
                          )}
                          bg="blue.200"
                        />
                      </Box>
                    </div>
                  );
                })}
          </div>
        </div>
      </ChakraProvider>
    </BlockChart>
  );
}

export default ChartSimalarWallet;
