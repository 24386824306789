import axios from 'axios';
import { blue_whale_api } from '../../configs';
import {
  AttributeResponse,
  BluWhaleProtocolDataRequest,
  BluWhaleProtocolDataResponse,
  BluwhaleChain,
  ProtocolData,
} from '@/types/bluwhaleDataApi';

async function getAttrubutes(_address: string): Promise<AttributeResponse> {
  try {
    const response = await axios.post(`${blue_whale_api.attributes}`, {
      wallet: _address,
    });
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
}

async function getProtocols(_wallet: string, _platformId: BluwhaleChain = 'ethereum'): Promise<ProtocolData> {
  try {
    const body: BluWhaleProtocolDataRequest = {
      platformId: _platformId,
      wallet: _wallet,
    };
    const response = await axios.post(`${blue_whale_api.protocols}`, body);
    const _data = response.data as BluWhaleProtocolDataResponse;
    return convertData(_data, _platformId, _wallet);
  } catch (e) {
    console.log(e);
    throw e;
  }
}
function convertData(data: BluWhaleProtocolDataResponse, platformId: BluwhaleChain, wallet: string): ProtocolData {
  return {
    ...data.data,
    platformId,
    wallet,
  };
}

export { getAttrubutes, getProtocols };
