'use client';
import { Box } from '@chakra-ui/react';
// import zoomPlugin from 'chartjs-plugin-zoom';
import React from 'react';
import {
  RadialLinearScale,
  Chart,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  TimeScale,
} from 'chart.js';
// import zoomPlugin from 'chartjs-plugin-zoom';

function BlockChart({ ...props }) {
  Chart.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    // zoomPlugin,
    TimeScale,
  );

  return (
    <Box
      w="100%"
      h="100%"
      p="5"
      display="flex"
      rounded="20px"
      backgroundColor={'rgba(255, 255, 255, 0.1)'}
      flexDir="column"
      position="relative"
    >
      {props.children}
    </Box>
  );
}

export default BlockChart;
