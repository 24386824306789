/* eslint-disable no-await-in-loop */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-shadow */
import Moralis from 'moralis';
import { EvmChain } from 'moralis/common-evm-utils';
import { useEffect, useRef, useState } from 'react';
import { BLUWHALE_CHAIN, Chain, TokenType, chainList } from '../configs';
import { delay, getDateFromToByDays, getDateFromToByMonths } from '../utils/helper';
import { MoralisTransactionVerbose, Status } from '../interfaces/types';
import { calcUsdByWei, calcWeiToEth } from '../utils/moralisUtils';
import { useDashBoard } from '../contexts/DashBoardContext';
import { useHandleStatus } from './useHandleStatus';
import { getTransactionAggregate } from '../api';
import { basicConfig } from '@/configs';
import { TransactionData, TransactionResponse } from '@/types/bluwhaleDataApi';

export type MoralisWalletTransctionsVerbosParams = {
  address: string;
  chain?: string;
  fromBlock?: number;
  toBlock?: number;
  fromDate?: string;
  toDate?: string;
  cursor?: string | undefined;
  disableTotal?: boolean;
  limit?: number;
};
export type Transaction = {
  chain?: Chain;
  date: string;
  function: string;
  amountsWei: string | number;
  amountsEth: string | number;
  amountsUsd: string | number;
  from: string;
  to: string;
  token: string;
  isPositive: boolean;
  raw: MoralisTransactionVerbose | TransactionData;
};
type Value = { amountsEth: string | number; amountsUsd: string | number; amountsWei: string | number };

export type useGetTransactionsParams = {
  address?: string;
  monthsAgo?: number;
  daysAgo?: number;
  daysAgos?: number[];
  chain?: Chain;
  preStatus?: Status;
};

export function useGetTransactions({ address, monthsAgo, daysAgo, chain, preStatus }: useGetTransactionsParams) {
  const { tokensInfoRef } = useDashBoard();
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const { status, excuteHandler, errorHandler, completeHandler } = useHandleStatus();
  const statusRef = useRef<Status>('idle');
  const tokensInfo = tokensInfoRef.current;
  function init() {
    setTransactions((pre) => []);
  }

  async function fetchTransactions(
    params: MoralisWalletTransctionsVerbosParams,
    allTxs: MoralisTransactionVerbose[] = [],
    countsLimit = 100,
  ): Promise<MoralisTransactionVerbose[]> {
    params = { ...params };
    const res = await Moralis.EvmApi.transaction.getWalletTransactionsVerbose(params);
    const txses = res.toJSON();
    const results = txses.result;
    // const nextCursor = results && results.length > 0 ? txses.cursor : undefined;
    // const nextCursor = results && results.length > 0 && allTxs.length <= countsLimit ? txses.cursor : undefined;
    //currently just fetch top 100 transations, because Moralis API RATE LIMIT
    const nextCursor = undefined;
    if (results) {
      allTxs.push(...(results as MoralisTransactionVerbose[]));
    }
    const _params = { ...params, cursor: nextCursor };
    if (nextCursor && allTxs.length < countsLimit) {
      await delay(0.5);
      return fetchTransactions(_params, allTxs);
    }
    // await delay(1);

    return allTxs;
  }
  //TODO get TOKEN
  function getToken(txs: MoralisTransactionVerbose, chainTarget?: Chain) {
    return Number(txs.value) > 0 ? chainTarget?.wrappedToken.symbol ?? '' : '';
  }
  function getValueNative(value: string, chainTarget?: Chain): Value {
    // calcUsdByWei, calcUsdByEth, calcWeiToEth
    const wrappedToken = chainTarget?.wrappedToken;
    if (!tokensInfo || !wrappedToken) {
      return { amountsWei: '0', amountsEth: '0', amountsUsd: '0' };
    }
    const { native } = tokensInfo[chainTarget.key];
    const deicmals = wrappedToken.decimal;
    return {
      amountsWei: value,
      amountsEth: calcWeiToEth(value, deicmals),
      amountsUsd: calcUsdByWei(value, deicmals, Number(native?.usdPrice ?? 0)),
    };
  }
  //TODO get VALUE
  function getValue(txs: MoralisTransactionVerbose, chainTarget?: Chain): Value {
    if (txs.value) {
      return getValueNative(txs.value, chainTarget);
    }

    // return txs.value;
    return {
      amountsEth: '0',
      amountsUsd: '0',
      amountsWei: '0',
    };
  }

  function getIsPositive(from_address: string, wallet_address: string): boolean {
    return from_address.toLowerCase() === wallet_address.toLowerCase();
  }

  function generateData(txsArr: MoralisTransactionVerbose[], chain: EvmChain, _address: string): Transaction[] {
    return txsArr.map((txs) => {
      const chainTarget = chainList.find((_chain) => _chain.chain.apiHex === chain.apiHex);
      const value = getValue(txs, chainTarget);
      return {
        chain: chainTarget,
        date: txs.block_timestamp,
        function: txs.decoded_call?.label ?? '',
        amountsWei: value.amountsWei,
        amountsEth: value.amountsEth,
        amountsUsd: value.amountsUsd,
        from: txs.from_address,
        to: txs.to_address,
        token: getToken(txs, chainTarget),
        isPositive: getIsPositive(txs.from_address, _address),
        raw: txs,
      };
    });
  }

  function sortByDate(datas: Transaction[]) {
    return datas.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  }

  async function getTransactionsByChainByMonths(chain: EvmChain, _address: string) {
    const { currentDate, monthsAgoDate } = getDateFromToByMonths(monthsAgo);
    const params: MoralisWalletTransctionsVerbosParams = {
      address: _address,
      chain: chain.apiHex,
      // disableTotal: false,
      fromDate: monthsAgoDate,
      toDate: currentDate,
    };
    const res = await fetchTransactions(params);
    return generateData(res, chain, _address);
  }
  async function getTransactionsByChainByDays(chain: EvmChain, days: number, _address: string) {
    const { currentDate, daysAgoDate } = getDateFromToByDays(days);
    const params: MoralisWalletTransctionsVerbosParams = {
      address: _address,
      chain: chain.apiHex,
      // disableTotal: false,
      fromDate: daysAgoDate,
      toDate: currentDate,
    };
    const res = await fetchTransactions(params);
    return generateData(res, chain, _address);
  }

  async function _getTransactionsByChainV2(
    _address: string,
    _chain: Chain,
    _page = 1,
    _pageSize = 50,
    _maxLimit = 100,
  ): Promise<Array<TransactionResponse<TransactionData>>> {
    const url = `${basicConfig.bluwhale.walletAPIUrl}/wallets/transactions/aggregate/`;
    const body = {
      platformId: _chain.bluApiChain,
      address: _address,
      page: _page,
      pageSize: _pageSize,
    };

    const result = await getTransactionAggregate(url, body);

    if (result && result.data.total > _page * _pageSize && _page * _pageSize < _maxLimit) {
      const nextPageResult = await _getTransactionsByChainV2(_address, _chain, _page + 1, _pageSize, _maxLimit);
      return [result, ...nextPageResult];
    }

    return [result];
  }

  function generateDataV2(txsArr: Array<TransactionResponse<TransactionData>>, chain: Chain): Array<Transaction> {
    const results: Array<Transaction> = [];
    txsArr.forEach((txs) => {
      if (txs.data.list) {
        txs.data.list.forEach((tx) => {
          results.push({
            chain,
            date: new Date(tx.blockTimestamp * 1000).toISOString(),
            function: tx.functionName,
            amountsWei: tx.value,
            amountsEth: tx.value,
            amountsUsd: tx.valueUsd,
            from: tx.fromAddress,
            to: tx.toAddress,
            token: tx.tokenName,
            isPositive: false,
            raw: tx,
          });
        });
      }
    });
    return results;
  }

  async function getTransactionsByChainV2(_address: string, _chain: Chain): Promise<Array<Transaction>> {
    try {
      const res = await _getTransactionsByChainV2(_address, _chain);
      const result = generateDataV2(res, _chain);
      return result;
    } catch (e) {
      console.log('getTransactionsByChainV2 error', e);
      throw e;
    }
  }

  async function getTransactionsByChain(_address: string, chain: EvmChain, days?: number) {
    // console.log('transactions getTransactionsByChain============');
    if (days) {
      return getTransactionsByChainByDays(chain, days, _address);
    }
    return getTransactionsByChainByMonths(chain, _address);
  }

  async function handleExectureFunction(_address: string, _chain: Chain, _daysAgo?: number) {
    if (_chain.bluApiChain === BLUWHALE_CHAIN.ETHEREUM) {
      return getTransactionsByChainV2(_address, _chain);
    }
    return getTransactionsByChain(_address, _chain.chain, daysAgo);
  }
  async function excuteGetTransactionsByChains(_address: string) {
    const tasksPromise = [];
    const tasks = [];
    // let counts = 3;
    const counts = chainList.length;

    for (let i = 0; i < counts; i++) {
      //MORALIS LIMIT RATE
      // tasksPromise.push(getTransactionsByChain(chainList[i].chain));
      const _chain = chainList[i];
      if (!_chain) {
        continue;
      }
      // const response = await getTransactionsByChain(_address, _chain.chain, daysAgo);
      const response = await handleExectureFunction(_address, _chain, daysAgo);
      tasks.push(response);

      // 6 requests / 1sec
      // const task = getTransactionsByChain(_chain.chain, daysAgo);
      // tasksPromise.push(task);
    }
    // tasks = await Promise.all(tasksPromise);
    const res = tasks;
    const flatRes = res.flat();
    const transactions = sortByDate(flatRes);
    setTransactions((pre) => transactions);
    return transactions;
  }

  async function excuteGetChainsTransactionsByDays(_address: string, chainList: Chain[], daysAgo?: number) {
    const counts = chainList.length;
    const results: Transaction[] = [];
    for (let i = 0; i < counts; i++) {
      // const res = await getTransactionsByChain(_address, chainList[i].chain, daysAgo);
      const res = await handleExectureFunction(_address, chainList[i], daysAgo);
      results.concat(res);
    }
    setTransactions((pre) => results);
    return results;
  }
  async function excuteGetAllChainsTransactionsByDays(_address: string, daysAgo?: number) {
    return excuteGetChainsTransactionsByDays(_address, chainList, daysAgo);
  }
  async function excute(_address: string, chains?: Chain[], daysAgo?: number) {
    if (daysAgo) {
      if (!chains || chains.length === 0) {
        return excuteGetAllChainsTransactionsByDays(_address, daysAgo);
      }
      return excuteGetChainsTransactionsByDays(_address, chains, daysAgo);
    }
    return excuteGetTransactionsByChains(_address);
  }

  useEffect(() => {
    //use nextStaus to protect RATE LIMIT
    if (preStatus && preStatus !== 'success' && preStatus !== 'error') {
      return;
    }
    if (status === 'loading' || statusRef.current === 'loading') {
      return;
    }
    if (!address) {
      return;
    }
    init();
    statusRef.current = 'loading';
    // getTransactionsByChain(chainList[0].chain);
    // excuteHandler(excute(address, chain))
    //   .then(() => {
    //     completeHandler();
    //     statusRef.current = 'success';
    //   })
    //   .catch((e) => {
    //     console.log('transactions', e);
    //     statusRef.current = 'error';

    //     errorHandler();
    //   });
  }, [address, monthsAgo, daysAgo, chain, preStatus]);
  return { transactions, status, onExcute: excute };
}
