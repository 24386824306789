import React from 'react';
import Image from 'next/image';
import { DataTable } from '../common/DataTable';
import { createColumnHelper } from '@tanstack/react-table';
import { Transaction } from '../hooks/useGetTransactions';
import { Avatar, Tooltip } from '@chakra-ui/react';
import moment from 'moment';
import { formatNumber } from '@/utils/format';
import { Status } from '../interfaces/types';
import Loading from './Loading';
import { useDashBoard } from '../contexts/DashBoardContext';
import { mockTransactionHistory } from '@/mocks/mockData';
import { Icon, InfoOutlineIcon } from '@chakra-ui/icons';

type TransactionTable = {
  chain: string;
  date: string;
  interactions: string;
  amount: string | number;
  token: string;
  form: string;
  to: string;
  usd: number | string;
};

function TransactionHistory({
  transactions,
  status,
  isDemo = false,
}: {
  transactions?: Transaction[];
  status: Status;
  isDemo?: boolean;
}) {
  const { walletAddress: address, globalStatus } = useDashBoard();

  const tagIsLoading = globalStatus === 'loading' || status === 'loading' || status === 'idle';

  const data: TransactionTable[] = isDemo
    ? mockTransactionHistory.transactions
    : transactions
    ? transactions.map((tsx) => {
        return {
          chain: tsx.chain?.icon ?? '',
          date: tsx.date,
          interactions: tsx.function,
          // amount: formatNumber(Number(tsx.amountsEth ?? 0)),
          amount: Number(tsx.amountsEth).toFixed(2),
          token: tsx?.token ?? '',
          form: tsx.from,
          to: tsx.to,
          usd: formatNumber(Number(tsx.amountsUsd ?? 0)),
        };
      })
    : [];
  // console.log('🚀 ~ file: TransactionHistory.tsx:51 ~ constdata:TransactionTable[]=transactions.map ~ data:', data);

  const columnHelper = createColumnHelper<TransactionTable>();

  const columns = [
    columnHelper.accessor('chain', {
      // eslint-disable-next-line no-confusing-arrow
      cell: (info) =>
        isDemo ? (
          <div className="flex justify-center">
            <Image alt="icon" src="/images/chain-eth.svg" width={24} height={24} className="rounded-full" />
          </div>
        ) : (
          <Avatar size="xs" src={info.getValue()} />
        ),
      header: 'Chain',
      size: 40,
      enableSorting: false,
    }),
    columnHelper.accessor('date', {
      cell: (info) => <div className="text-[#9ba2a9]">{moment(info.getValue()).format('YY MMM DD hh:mm A')}</div>,
      header: 'Date',
      size: 85,
      enableSorting: false,
    }),
    columnHelper.accessor('interactions', {
      cell: (info) => (
        <div className="text-[#ffffff]">
          <span className="text-[#ffffff] font-bold">{info.getValue()} </span>
          {/* <span className="text-[#9ba2a9]">supply</span> */}
        </div>
      ),
      header: 'Interactions',
      size: 75,
      meta: {
        isNumeric: true,
      },
    }),
    columnHelper.accessor('amount', {
      cell: (info) => (
        <div className={`break-all ${info.row.index % 2 ? 'text-[#ef867e]' : 'text-[#66deb5]'}`}>
          {info.getValue()}
        </div>
      ),
      header: 'Amount',
      size: 55,
      meta: {
        isNumeric: true,
      },
    }),
    columnHelper.accessor('token', {
      cell: (info) => <div className="text-[#9ba2a9]">{info.getValue()}</div>,
      header: 'Token',
      size: 50,
    }),
    columnHelper.accessor('form', {
      cell: (info) => (
        <div className={`${info.row.index % 2 ? 'text-[#9ba2a9]' : 'text-[#ffffff]'} truncate`}>{info.getValue()}</div>
      ),
      header: 'From',
      size: 60,
    }),
    columnHelper.accessor('to', {
      cell: (info) => (
        <div className={`${info.row.index % 2 ? 'text-[#9ba2a9]' : 'text-[#ffffff]'} truncate`}>{info.getValue()}</div>
      ),
      header: 'To',
      size: 60,
    }),
    columnHelper.accessor('usd', {
      cell: (info) => (
        <div className={`${info.row.index % 2 ? 'text-[#ef867e]' : 'text-[#66deb5]'}`}>${info.getValue()}</div>
      ),
      header: 'USD',
      size: 40,
      meta: {
        isNumeric: true,
      },
    }),
  ];
  return (
    <div className="bg-[#06192a] my-4 ">
      <Tooltip
        hasArrow
        placement="bottom-start"
        label="Transactions within the last 3 months"
        borderRadius="10px"
        bg="rgba(255, 255, 255, 0.8)"
        color="gray.900"
        fontSize="12px"
        maxWidth="150px"
        padding="8px"
        textAlign="center"
        transition="opacity 0.5s ease-in-out"
      >
        <div className="flex items-center mb-3 font-bold text-lg p-2">
          <div className="text-lg font-bold"> Transaction History</div>
          <Icon as={InfoOutlineIcon} mx={1} color="#9ba2a9" fontSize="xs" />
        </div>
      </Tooltip>
      {tagIsLoading && !isDemo ? (
        <div className="flex-1 flex justify-center items-center min-h-[200px] ">
          <Loading />
        </div>
      ) : (
        <DataTable columns={columns} data={data} />
      )}
    </div>
  );
}

export default TransactionHistory;
