import React from 'react';
import {
  ChartNetworthBarChart,
  ChartChainAlloction,
  ProtocolAllocation,
  ChartRadar,
  // ChartBar,
} from '.';
import { Flex, Box, GridItem } from '@chakra-ui/react';
import { ChartRadarParams, chartRadarTestData } from './ChartRadar';
import { ChainNative } from '../hooks/useGetNativeBalance';
import { Status } from '../interfaces/types';
import ChartTokenAlloction, { ChartTokenAlloctionParams } from './ChartTokenAlloction';
import Loading from './Loading';
import { UseGetTokenBalancesOutByDates } from '../hooks/useGetTokenBalancesN';
import { ChartChainAlloctionParams } from './ChartChainAlloction';
import ChartSimalarWallet from './ChartSimalarWallet';

export type PlatformChartParams = {
  radarDto?: ChartRadarParams;
  historyBalanceDto?: UseGetTokenBalancesOutByDates;
  chartTokenAlloctionDto?: ChartTokenAlloctionParams;
  chartChainAlloctionDto?: ChartChainAlloctionParams;
  radarStatus: Status;
  tokenHistoryStatus: Status;
  chartTokenAlloctionStatus: Status;
  chartChainAlloctionStatus: Status;
  isDemo?: boolean;
};
function PlatformChart({
  radarDto,
  historyBalanceDto,
  radarStatus,
  chartTokenAlloctionDto,
  chartChainAlloctionDto,
  tokenHistoryStatus,
  chartTokenAlloctionStatus,
  chartChainAlloctionStatus,
  isDemo = false,
}: PlatformChartParams) {
  //= chartRadarTestData
  const charRadarParams = undefined;
  // console.log('PlatformChart', ChainNativeList, RadarData, tokenBalances, tokenAlloction, preStatus);
  return (
    <>
      <Flex gap={4} mb="16px" minHeight="255px" flexDir={['column', null, 'row']} display={['grid', null, 'flex']}>
        <Box w={['100%', null, '33%']} display="flex" justifyContent="center" alignItems="center" position={'relative'}>
          <ChartRadar params={radarDto} status={radarStatus} isDemo={isDemo} />
        </Box>
        <Box w={['100%', null, '33%']}>
          <ChartNetworthBarChart
            status={tokenHistoryStatus}
            tokenBalancesUsdByDates={historyBalanceDto}
            isDemo={isDemo}
          />
        </Box>
        <Box w={['100%', null, '33%']}>
          <ChartSimalarWallet isDemo={isDemo} />
        </Box>
      </Flex>
      <Flex gap={4} minHeight="255px" flexDir={['column', null, 'row']} display={['grid', null, 'flex']}>
        <Box flex="1">
          <ChartTokenAlloction
            datas={chartTokenAlloctionDto?.datas}
            status={chartTokenAlloctionStatus}
            isDemo={isDemo}
          />
        </Box>
        <Box flex="1">
          <ChartChainAlloction datas={chartChainAlloctionDto} status={chartChainAlloctionStatus} isDemo={isDemo} />
        </Box>
        {/* <ProtocolAllocation /> */}
      </Flex>
    </>
  );
}

export default PlatformChart;
