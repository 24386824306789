import React from 'react';
import Image from 'next/image';
export type LoadingParams = {
  h?: number;
  w?: number;
  content?: string;
  isShowContent?: boolean;
};
function Loading({ h = 45, w = 45, content, isShowContent = true }: LoadingParams) {
  return (
    <div className="flex flex-col items-center justify-center h-full">
      <div>
        <Image src="/images/loading.gif" height={h} width={w} alt="Loading" />
      </div>

      <div className={`text-[#B1B1B1] text-sm ${isShowContent ? '' : 'hidden'}`}>{content ?? `Analyzing...`}</div>
    </div>
  );
}

export default Loading;
