/* eslint-disable no-useless-catch */
/* eslint-disable consistent-return */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-inline-comments */
import Moralis from 'moralis';
import { EvmChain } from 'moralis/common-evm-utils';
import { MoralisTokenPrice } from '../interfaces/types';
import { Chain, MoralisApi, moralisSdkParams } from '../configs';

function calcUsdByWei(balance: string | number, decimals: string | number, usdPrice: string | number) {
  try {
    const balanceEth = calcWeiToEth(balance, Number(decimals));
    return calcUsdByEth(balanceEth, Number(usdPrice)); //(Number(balance)  10 ** decimals) * usdPrice;
  } catch {
    return 0;
  }
}
function calcUsdByEth(balance: string | number, usdPrice: number) {
  try {
    return Number(balance) * usdPrice;
  } catch {
    return 0;
  }
}
function calcWeiToEth(amountWei: number | string, decimals: number) {
  const weiPerEther = BigInt(10 ** decimals); // Convert the decimals to BigInt
  const wei = BigInt(amountWei); // Convert the input value to BigInt
  const etherValue = Number(Number(wei) / Number(weiPerEther)); // Divide Wei by Wei per Ether and convert back to Number
  return etherValue;
}

async function fetchTokenPrice(address: string, chain: Chain) {
  const exchage = moralisSdkParams[chain.key].token.exchange;
  const price = await Moralis.EvmApi.token.getTokenPrice({ address, chain: chain.chain.apiHex, exchange: exchage });
  const raw = price.toJSON();
  return { usdPrice: raw.usdPrice, raw };
}

async function fetchMutilTokenPrice(
  tokens: { token_address: string }[],
  chain: EvmChain,
): Promise<Array<MoralisTokenPrice> | undefined> {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('X-API-Key', process.env.NEXT_PUBLIC_MORALIS_API_KEY ?? '');
  const options = {
    method: 'POST',
    headers,
    body: JSON.stringify({
      tokens,
    }),
  };
  const res = await fetch(`${MoralisApi.MULTITOKENPRICE}?chain=${chain.apiHex}&include=percent_change`, options);
  return res.json();
}
async function fetchMutilTokenPriceByLimit(tokens: string[], chain: EvmChain): Promise<Map<string, MoralisTokenPrice>> {
  const limit = 25;
  const results: Map<string, MoralisTokenPrice> = new Map();

  const counts = tokens.length;

  for (let i = 0; i < counts; i += limit) {
    const batchTokens = tokens.slice(i, i + limit).map((token) => {
      return { token_address: token };
    });
    const batchResult = await fetchMutilTokenPrice(batchTokens, chain);
    if (!batchResult) {
      continue;
    }
    if (!Array.isArray(batchResult)) {
      continue;
    }
    batchResult.forEach((price) => {
      const wrappedName = ['WBNB', 'WETH', 'WFTM', 'WAVAX', 'WMATIC'];
      const key = price?.address?.toLowerCase();
      const symbol = price?.tokenSymbol;
      const isNative = symbol && wrappedName.includes(symbol) && !key;

      if (key) {
        results.set(key, price);
      }
      if (isNative) {
        results.set(`${price.chain}_${symbol}`, price);
      }
    });
  }

  return results;
}

export { fetchTokenPrice, calcUsdByWei, calcUsdByEth, calcWeiToEth, fetchMutilTokenPriceByLimit };
