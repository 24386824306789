import { CHAIN } from './chain';

type Exchnage =
  | 'uniswapv2'
  | 'uniswapv3'
  | 'pancakeswapv1'
  | 'pancakeswapv2'
  | 'pancakeswapv3'
  | 'sushiswapv2'
  | 'quickswapv2'
  | 'traderjoe'
  | 'pangolin'
  | 'spookyswap'
  | 'camelotv2';
export type MoralisSdkParamsType = {
  token: {
    //https://docs.moralis.io/web3-data-api/evm/supported-dexs-token-api
    exchange: Exchnage;
    exchanges: Array<Exchnage>;
  };
};

const ethereum: MoralisSdkParamsType = {
  token: {
    exchange: 'uniswapv3',
    exchanges: ['uniswapv2', 'uniswapv3', 'sushiswapv2'],
  },
};
const bsc: MoralisSdkParamsType = {
  token: {
    exchange: 'pancakeswapv3',

    exchanges: ['uniswapv3', 'pancakeswapv1', 'pancakeswapv2', 'pancakeswapv3'],
  },
};
const polygon: MoralisSdkParamsType = {
  token: {
    exchange: 'uniswapv3',
    exchanges: ['uniswapv2', 'uniswapv3', 'quickswapv2'],
  },
};
//TODO
const avalanche: MoralisSdkParamsType = {
  token: {
    exchange: 'pangolin',
    exchanges: ['traderjoe', 'pangolin'],
  },
};
const arbitrum: MoralisSdkParamsType = {
  token: {
    exchange: 'camelotv2',
    exchanges: ['camelotv2', 'uniswapv3', 'quickswapv2'],
  },
};
const fantom: MoralisSdkParamsType = {
  token: {
    exchange: 'spookyswap',
    exchanges: ['spookyswap'],
  },
};

export const moralisSdkParams: { [key in CHAIN]: MoralisSdkParamsType } = {
  [CHAIN.ETHEREUM]: ethereum,
  [CHAIN.BSC]: bsc,
  [CHAIN.FANTOM]: fantom,
  [CHAIN.AVALANCHE]: avalanche,
  [CHAIN.POLYGON]: polygon,
  [CHAIN.ARBITRUM]: arbitrum,
};
