import { useGenerateUIChartRadar } from './useGenerateUIChartRadar';
import { useGenerateUITags } from './useGenerateUITags';
import { useGetNativeBalanceOut } from './useGetNativeBalance';
import { useGetNftDataOut } from './useGetNftData';
import { useGetTokenBalancesNOut } from './useGetTokenBalancesN';
import { Transaction } from './useGetTransactions';
import { useGenerateUIInfos } from './useGenerateUIInfos';
import { Status } from '../interfaces/types';
import useGetProtocolData from './useGetProtocolData';
import useGenerateUIChartChainAlloction from './useGenerateUIChartChainAlloction';
import useGenerateUIChartTokenAlloction from './useGenerateUIChartTokenAlloction';
import { useGenerateUIChartCredit } from './useGenerateUIChartCredit';
import { useEffect } from 'react';
import { AttributeResponse, ProtocolData } from '@/types/bluwhaleDataApi';

export type UseGenerateUIAggregateParamsType = {
  address: string;
  nativeDto?: {
    status: Status;
    dto?: useGetNativeBalanceOut;
  };
  tokenDto?: { status: Status; dto?: useGetTokenBalancesNOut };
  nftDto?: { metaStatus: Status; priceStatus: Status; dto?: useGetNftDataOut };
  tsxDto?: { status: Status; dto?: Transaction[] };
  protocolDto?: { status: Status; dto?: any[] };
  // tagsDto?: { dto?: string[]; status: Status };
  attributesDto?: { dto?: AttributeResponse; status: Status };
  protocolDtoV2?: { status: Status; dto?: ProtocolData };
};
export function useGenerateUIAggregate({
  address,
  nativeDto,
  tokenDto,
  nftDto,
  tsxDto,
  // protocolDto,
  attributesDto,
  protocolDtoV2,
}: UseGenerateUIAggregateParamsType) {
  /**
   * token profile(TokenChartTokenAlloction)
   * chain Utilization(ChainChartTokenAlloction)
   * tags
   * credit
   * info
   * radar
   * networth
   * simalar wallets
   */

  //hooks
  const { onCalcTotalBalance } = useGetProtocolData({ address, protocols: undefined });

  //tokenAlloctionUiDto
  //=======================================//
  //#region
  const {
    dto: tokenAlloctionUiDto,
    status: tokenAlloctionUiStatus,
    onInit: uiTokensInit,
  } = useGenerateUIChartTokenAlloction({
    nativeDto: nativeDto?.dto,
    tokenDto: tokenDto?.dto,
  });
  //#endregion

  //chainAlloctionUiDto
  //=======================================//
  //#region
  const { chainAlloctionUiDto, status: chainAlloctionUiStatus } = useGenerateUIChartChainAlloction({
    nativeDto: nativeDto?.dto,
    nativeStatus: nativeDto?.status,
  });
  //#endregion

  // Tags
  //=======================================//
  //#region
  const {
    status: tagsUiStatus,
    tagsDto: tagsUiDto,
    onInit: uiTagsInit,
  } = useGenerateUITags({
    // nativeDto: nativeDto?.dto,
    // tokenDto: tokenDto?.dto,
    // nftDto: nftDto?.dto,
    // tsxDto: tsxDto?.dto,
    tagsDto: attributesDto?.dto?.attributes,
    // protocolDto: protocolDto?.dto,
    protocolDtoV2: protocolDtoV2?.dto,
  });
  //#endregion

  //TODO
  // Infos
  //=======================================//
  //#region
  const {
    infosDto: infosUiDto,
    status: infosUiStatus,
    onInit: uiInfoInit,
    onExcute: onGeneratUiUnfo,
  } = useGenerateUIInfos(address, {
    nativeDto,
    tokenDto,
    // protocolDto,
    protocolDtoV2: { status: protocolDtoV2?.status ?? 'idle', dto: protocolDtoV2?.dto },
    nftDto: { dto: nftDto?.dto, status: nftDto?.priceStatus ?? 'idle' },
  });

  //#endregion

  //#endregion
  // onCalcTotalBalance(protocolDto?.dto);

  // Radar
  //=======================================//
  //#region
  const {
    onInit: uiRadarInit,
    status: radarUiStatus,
    radarDto: radarUiDto,
  } = useGenerateUIChartRadar({
    nativeDto: nativeDto?.dto,
    tokenDto: tokenDto?.dto,
    nftDto: nftDto?.dto?.nftListByFlat,
    tsxDto: tsxDto?.dto,
    tokenAlloction: tokenAlloctionUiDto,
    // protocolDto: protocolDto?.dto,
    protocolDtoV2: protocolDtoV2?.dto,
  });
  //#endregion

  const {
    onGetCredit,
    creditDto: creditUiDto,
    status: creditUiStatus,
    onInit: uiCreditInit,
  } = useGenerateUIChartCredit({
    address,
    score: attributesDto?.dto?.score,
    status: attributesDto?.status,
    // netWorth: {
    //   nativeBalanceUsd: getNumber(nativeDto?.dto?.totalBalanceUsd),
    //   tokenBalanceUsd: getNumber(tokenDto?.dto?.totalBalanceUsd),
    //   stableBalanceUsd: getNumber(tokenDto?.dto?.balanceSumByStableCoin?.totalBalanceUsd),
    //   nftBalanceUsd: getNumber(nftDto?.dto?.totalChainNftBalanceUsd),
    // },
    // statusList: [nativeDto?.status ?? 'idle', tokenDto?.status ?? 'idle', nftDto?.priceStatus ?? 'idle'],
  });
  function init() {
    uiTokensInit();
    // uiChainInit()
    uiTagsInit();
    uiInfoInit();
    uiRadarInit();
    uiCreditInit();
  }
  useEffect(() => {
    init();
  }, [address]);

  return {
    tagsUiDto,

    radarUiDto,
    radarUiStatus,

    infosUiDto,
    infosUiStatus,

    creditUiDto,
    creditUiStatus,

    chainAlloctionUiDto,
    chainAlloctionUiStatus,

    tokenAlloctionUiDto,
    tokenAlloctionUiStatus,
    tagsUiStatus,
  };
}
