import { useQuery } from 'react-query';
import { Chain } from '../configs';
import { ProtocolModule } from '../interfaces/interface';
import { Status } from '../interfaces/types';
import { getProtocols } from '../api';

export type useGetProtocolDataV2Type = {
  address: string;
};
export type useGetProtocolDataOut = {
  protocolName: string;
  chain: Chain;
  collectsAmount: string;
  rewardsAmount: string;
  borrowsAmount: string;
};
// Inject by protocolModules
export default function useGetProtocolDataV2({ address }: useGetProtocolDataV2Type) {
  const protocolQuery = useQuery({
    // enabled: !!protocols,
    queryKey: ['protocols', address],
    queryFn: async () => getProtocols(address),
  });

  return {
    data: protocolQuery.data,
    status: protocolQuery.status as Status,
  };
}
