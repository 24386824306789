/* eslint-disable @typescript-eslint/no-unused-vars */
import { Plugin } from 'chart.js';

const multilineLabelPlugin: Plugin<'radar'> = {
  id: 'multiline-labels',
  beforeInit: (chart) => {
    //@ts-ignore
    chart.data.labels.forEach((label, index, labels) => {
      //@ts-ignore
      if (/\n/.test(label)) {
        //@ts-ignore
        labels[index] = label.split('\n');
      }
    });
  },
};
const showMonthLabelPlugin: Plugin<'bar'> = {
  id: 'month-labels',
  beforeInit: (chart) => {
    //@ts-ignore
    chart.data.labels.forEach((label, index, labels) => {
      //@ts-ignore
    });
  },
};

export { multilineLabelPlugin };
