/* eslint-disable complexity */
import { useEffect, useRef, useState } from 'react';
import { CreditColor, creditColor } from '../configs/creditColor';
import { randomInt } from '../utils/helper';
import { Status } from '../interfaces/types';
import { ChartCreditParams } from '../elements/ChartCredit';

export type CreditData = {
  crditScore: number;
  crditPercent: number;
  color: CreditColor;
};

// export type UseGetCreditDataParams = {
//   address?: string;
//   netWorth?: NetWorth;
//   statusList?: Status[];
// };
// type NetWorth = {
//   // totalBalanceUsd: number;
//   nativeBalanceUsd: number;
//   tokenBalanceUsd: number;
//   stableBalanceUsd: number;
//   nftBalanceUsd: number;
// };
export type UseGetCreditDataParams = {
  address?: string;
  score?: number;
  status?: Status;
};
export function useGenerateUIChartCredit({ address, score, status }: UseGetCreditDataParams) {
  const defaultColor = creditColor[Object.keys(creditColor)[0]];
  const defaultData = { crditScore: 0, crditPercent: 0, color: creditColor[Object.keys(creditColor)[0]] };
  // const defaultDto: ChartCreditParams = {
  //   crditScore: 0,
  //   crditPercent: 0,
  //   color: defaultColor.color,
  //   textColor: defaultColor.textColor,
  // };
  const [creditData, setCreditData] = useState<CreditData>(defaultData);
  const [creditDto, setCreditDto] = useState<ChartCreditParams>();
  const [uiStatus, setUiStatus] = useState<Status>('idle');
  const addressRef = useRef<string>();
  const checkRef = useRef<boolean>(false);
  const isInitRef = useRef<boolean>(false);
  function init() {
    setUiStatus((pre) => 'loading');
    setCreditDto((pre) => undefined);
    setCreditData((pre) => defaultData);
    isInitRef.current = true;
  }

  function getColor(num?: number) {
    const min = 0;
    const max = 850;
    const randomNum = num !== undefined ? (num === 0 ? randomInt(200, min) : num) : randomInt(min, max);
    const colors = Object.keys(creditColor);
    const target = colors.find((_num) => creditColor[_num].max >= randomNum) ?? colors[0];
    if (!target) {
      throw Error('not found');
    }
    // debugger;
    const crditPercent = max - randomNum === 0 ? 100 : ((randomNum - min) / (max - min)) * 100;
    return {
      crditScore: randomNum,
      crditPercent,
      color: creditColor[target],
    };
  }
  /**
   * "體驗 : 隨機呈現 450~800 之間的數值, (顏色依照右邊的圖)
   *  300 -> Net Worth under USD$1000
   *  300 ~ 579 -> Net Worth USD$1000 ~ USD$9999 (+279)
   *  580 ~ 669 -> Net Worth above USD$10000 + NFT 0% ~ 10% (39) + Has stable coin (+50)
   *  670 ~ 739 -> Net Worth above USD$10000 + NFT 10% ~ 30% (+19) + Has stable coin (+50)
   *  740 ~ 850 -> Net Worth above USD$10000 + NFT above 30% (+10) + Has stable coin 0% ~ 70% (+100)"
   */
  function getPoor() {
    // return 300;
    return randomInt(300, 579);
  }
  function getFair() {
    // return 579;
    return randomInt(301, 579);
  }
  // function buinessLogic(_netWorth: NetWorth) {
  //   const { ...res } = _netWorth;

  //   const totalBalanceUsd = res.nativeBalanceUsd + res.nftBalanceUsd + res.tokenBalanceUsd;
  //   if (totalBalanceUsd < 1000) {
  //     return getPoor();
  //   }
  //   if (totalBalanceUsd < 9999) {
  //     return getFair();
  //   }
  //   let num = 579;
  //   // let num = randomInt(579, 600);
  //   const nftPercent = (res.nftBalanceUsd / totalBalanceUsd) * 100;
  //   if (res.stableBalanceUsd > 0) {
  //     num += 50;
  //   }
  //   if ((res.stableBalanceUsd / totalBalanceUsd) * 100 < 70) {
  //     num += 100;
  //   }

  //   if (nftPercent !== 0 && nftPercent < 10) {
  //     num += 39;
  //   }
  //   if (nftPercent >= 10 && nftPercent < 30) {
  //     num += 19;
  //   }
  //   if (nftPercent > 30) {
  //     num += 10;
  //   }
  //   return num;
  // }

  function _getCredit(_score: number) {
    // const num = buinessLogic(__netWorth);
    return getColor(_score);
  }
  function getCredit(_score?: number) {
    const dto = _score ? _getCredit(_score) : getColor();
    setCreditData(dto);
    const creditParams = {
      crditScore: dto.crditScore,
      crditPercent: dto.crditPercent,
      color: dto.color.color,
      textColor: dto.color.textColor,
      // status: 'loading' as Status,
    };
    setCreditDto(creditParams);
    return { dto, creditParams };
  }
  function checkStatus() {
    //true: has complete or no check
    //false: status list all loading
    return status === 'success';
    // return statusList.findIndex((_status) => _status === 'success') !== -1;
  }
  // function checkStatusAllIdle() {
  //   const sum = statusList?.reduce((_sum, _status) => {
  //     return _status === 'idle' ? _sum + 1 : _sum;
  //   }, 0);
  //   return sum === statusList?.length;
  // }

  // useEffect(() => {
  //   if (creditDto) {
  //     setUiStatus((pre) => 'success');
  //   }
  // }, [JSON.stringify(creditDto)]);
  useEffect(() => {
    if (creditData) {
      setUiStatus((pre) => 'success');
    }
  }, [JSON.stringify(creditData)]);
  useEffect(() => {
    isInitRef.current = false;
    if (checkStatus() && status !== undefined) {
      checkRef.current = false;
    }
  }, [address]);

  useEffect(() => {
    if (!address) {
      return;
    }
    // setCreditData(getColor());
    if (addressRef.current !== address) {
      if (isInitRef.current === false) {
        init();
      }
    }
    // wait native and tokens price
    // if (!netWorth?.nativeBalanceUsd || !netWorth.stableBalanceUsd || !netWorth.tokenBalanceUsd) {
    //   return;
    // }
    if (!checkStatus()) {
      return;
    }

    getCredit(score);
  }, [score, address, status]);
  return { data: creditData, status, onGetCredit: getCredit, onInit: init, creditDto };
}
