import { getNumber } from './helper';

function filterProtocol(targets: Array<string>, _datas?: Array<any>) {
  return _datas?.filter((data) => targets.includes(data.name));
}
function calcBalance(targets: Array<string>, key: string, _datas?: Array<any>) {
  const _filterDatas = filterProtocol(targets, _datas);
  const _sum = _filterDatas?.reduce((sum, data) => {
    sum += getNumber(data[key]);
    return sum;
  }, 0);
  return _sum;
}
function calcTotalAsset(balances: Array<string | number | undefined>) {
  return balances.reduce((_sum: number, balance) => {
    _sum += Number(balance ?? 0);
    return _sum;
  }, 0);
}

export { filterProtocol, calcBalance, calcTotalAsset };
