import { Box, Card, Flex, Grid, Icon, Image, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { NftData, NftsDataByChain } from '../hooks/useGetNftData';
import { useDashBoard } from '../contexts/DashBoardContext';
import { Chain } from '../configs';
import NoData from './NoData';
import { Status } from '../interfaces/types';
import Loading from './Loading';
import { mockNFTList } from '@/mocks/mockData';
import { isNil } from 'ramda';
import { InfoOutlineIcon } from '@chakra-ui/icons';

export type PlatformNftParams = {
  status: Status;
  priceStatus: Status;
  nftListByFlat: NftData[] | undefined;
  isDemo?: boolean;
};

// eslint-disable-next-line complexity
function PlatformNft({ status, nftListByFlat, priceStatus, isDemo = false }: PlatformNftParams) {
  const { tokensInfoRef, globalStatus } = useDashBoard();
  const tokensInfo = tokensInfoRef.current;

  const isLoading = (globalStatus === 'loading' || status === 'loading' || status === 'idle') && !isDemo;
  const isComplete = globalStatus === 'idle' && status === 'success';
  const isError = globalStatus === 'idle' || status === 'error';

  const isImage = (url: string) => {
    return /^https?:\/\/.+\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
  };

  const defaultNftImg = '/images/default-nft.png';
  const NFTList = isDemo
    ? mockNFTList.filter((nft) => !isNil(nft.imgUrl) && isImage(nft.imgUrl || ''))
    : nftListByFlat?.map((nft, index) => ({
        id: `${nft.token_address}${nft.token_id}${index}`,
        imgUrl: nft.imageH?.url,
        symbol: nft.symbol ?? '',
        tokenId: nft.token_id ?? '',
        priceEth: nft.priceEth,
        priceUsd: getUsd(nft.priceEth, nft.chain),
        nativeSymbol: nft.chain?.wrappedToken.symbol,
        raw: nft,
      }));
  // console.log('🚀 ~ file: PlatformNft.tsx:36 ~ NFTList ~ NFTList:', NFTList);

  // console.log('NFTList', NFTList, nftListByFlat);
  function getUsd(priceEth?: string | number, chain?: Chain) {
    if (!chain || !priceEth || !tokensInfo) {
      return '0';
    }
    const usd = tokensInfo[chain.key]?.native?.usdPrice ?? 0;
    return Number(usd) * Number(priceEth);
  }

  return (
    <>
      <Tooltip
        hasArrow
        placement="bottom-start"
        label="NFT holdings"
        borderRadius="10px"
        bg="rgba(255, 255, 255, 0.8)"
        color="gray.900"
        fontSize="12px"
        padding="8px"
        textAlign="center"
        transition="opacity 0.5s ease-in-out"
      >
        <div className="flex items-center font-bold text-lg mt-8 mb-4">
          <div className=" text-lg font-bold">NFTs</div>
          <Icon as={InfoOutlineIcon} mx={1} color="#9ba2a9" fontSize="xs" />
        </div>
      </Tooltip>
      <Flex flexDir={'column'} p={[0, null, 2]}>
        <div className="pb-3"> {NFTList?.length ?? 0} results </div>
        <Grid
          className="  max-h-[650px] overflow-y-scroll"
          templateColumns={['repeat(2, 1fr)', null, 'repeat(4, 1fr)']}
          gap={[1, null, 4]}
        >
          {isLoading && !isDemo ? (
            <Box
              className="w-full"
              borderRadius="xl"
              h="260px"
              display="flex"
              flexDirection="column"
              backgroundColor="#262a2e"
              minW="200px"
            >
              {/* <div className="min-h-[263px] flex justify-center items-center"> */}
              <Loading />
              {/* </div> */}
            </Box>
          ) : (
            <>
              {NFTList && NFTList.length > 0 ? (
                NFTList?.map((NFT) => (
                  <Box
                    className="w-full"
                    borderRadius="xl"
                    h="260px"
                    key={NFT.id}
                    display="flex"
                    flexDirection="column"
                    onClick={() => {
                      // console.log(NFT)
                    }}
                    // minW={140}
                  >
                    <Box className="relative  w-full" h={[142, null, 160]}>
                      <Image
                        h="100%"
                        borderTopRadius="xl"
                        objectFit="cover"
                        src={!NFT.imgUrl || NFT.imgUrl === '' ? defaultNftImg : NFT.imgUrl}
                        alt="NFT"
                        width={'100%'}
                      />
                      <div className="absolute top-1 right-2">
                        {/* <Image
                      border="2px"
                      borderColor="#fff"
                      borderRadius="md"
                      boxSize="40px"
                      objectFit="cover"
                      src="https://images.unsplash.com/photo-1531403009284-440f080d1e12?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
                      alt="Chakra UI"
                    /> */}
                        <div className="bg-[#0e1013] rounded-full text-base p-1 w-[76px] px-1 relative self-end">
                          <div className="font-bold text-center uncate">
                            #
                            {NFT.tokenId.length <= 5
                              ? NFT.tokenId
                              : `${NFT.tokenId.slice(0, 2)}...${NFT.tokenId.slice(-2)}`}
                          </div>
                        </div>
                      </div>
                    </Box>
                    <div className="h-[100px] bg-[#262a2e] px-[10px] py-3 rounded-b-xl">
                      <div className="flex flex-col justify-between h-full">
                        <p className="text-lg font-bold leading-6 line-clamp-2">{NFT.symbol}</p>

                        <div className="mt-0 text-sm">
                          <span className="mr-1 text-gray-400">Last</span>
                          <span className="font-bold">
                            {priceStatus === 'loading' || priceStatus === 'idle' ? ` --` : `${NFT.priceEth}`}{' '}
                            {NFT.nativeSymbol}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Box>
                ))
              ) : (
                <Box
                  className="w-full"
                  borderRadius="xl"
                  h="260px"
                  display="flex"
                  flexDirection="column"
                  backgroundColor="#262a2e"
                  minW="200px"
                >
                  {/* <div className="min-h-[263px] flex justify-center items-center"> */}
                  <NoData />
                  {/* </div> */}
                </Box>
              )}
            </>
          )}
        </Grid>
      </Flex>
    </>
  );
}

export default PlatformNft;
