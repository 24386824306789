import React from 'react';
import { BlockChart } from '../common';

function ChartProtocolAllocation() {
  return (
    <BlockChart>
      <div>ChartProtocolAllocation</div>
    </BlockChart>
  );
}

export default ChartProtocolAllocation;
