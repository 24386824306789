/* eslint-disable no-await-in-loop */
import Moralis from 'moralis';
import {
  MoralisBlock,
  MoralisToken,
  MoralisTokenApi,
  MoralisTokenPrice,
  MoralisTokenPriceApi,
  MoralisTokenTransfer,
  Status,
} from '../interfaces/types';
import {
  BLUWHALE_CHAIN,
  CHAIN,
  Chain,
  MoralisApi,
  chainList,
  defaultTokenList,
  stableCoinListBySymbol,
  supportChains,
  whiteList,
} from '../configs';
import { useEffect, useMemo, useRef, useState } from 'react';
import { calcUsdByWei } from '../utils/moralisUtils';
import {
  delay,
  getBlockNumberListByTimeStmps,
  getDateFromToByDays,
  getDateFromToByMonths,
  getLastDayOfMonthBeforeTodayTimeStmp,
  staticBolock,
} from '../utils/helper';
import moment from 'moment';
import { useHandleStatus } from './useHandleStatus';
import { useDashBoard } from '../contexts/DashBoardContext';
import { basicConfig } from '@/configs';
import {
  BluwhaleChain,
  Erc20PriceData,
  Erc20PriceResponse,
  WalletTokenBalance,
  WalletTokenBalanceResponse,
} from '@/types/bluwhaleDataApi';
import { getErc20Balance, getErc20Price } from '../api';

export type useGetTokenBalancesNParams = {
  address?: string;
  to_blocks?: number[] | undefined;
  to_dates?: number[] | string[] | undefined;
  months?: number | undefined;
  preStatus?: Status;
};
export type TokenBalanceInUsdPrice = {
  chain: Chain;
  address: string;
  balance: string;
  balanceUsd?: string | number;
  decimals: number;
  symbol: string;
  usdPrice: string | number | undefined;
  raw?:
    | {
        data: MoralisTokenApi;
        price: MoralisTokenPrice | Erc20PriceData | undefined;
      }
    | WalletTokenBalance;
};

export type UseGetTokenBalancesOut = {
  chain: Chain;
  results?: TokenBalanceInUsdPrice[];
};
export type UseGetTokenBalancesOutByDates = Map<
  string,
  {
    list: UseGetTokenBalancesOut[];
    totalBalanceUsd: number;
  }
>;
export type UseGetTokenBalancesOutList = {
  list: Map<string, number>;
  totalBalanceUsd: number;
};

export type useGetTokenBalancesNOut = {
  currentTokensBalance?: UseGetTokenBalancesOut[];
  totalBalanceUsd?: string | number;
  tokenBalancesByDates?: Map<string, UseGetTokenBalancesOut[]>;
  tokenBalancesUsdByDates?: UseGetTokenBalancesOutByDates;
  balanceSumBySymbol?: UseGetTokenBalancesOutList;
  balanceSumByStableCoin?: UseGetTokenBalancesOutList;
  hasFlipper?: boolean;
  trades?: any;
};

export function useGetTokenBalancesN({ address, to_blocks, to_dates, months, preStatus }: useGetTokenBalancesNParams) {
  const { staticBolockRef } = useDashBoard();
  const { status, excuteHandler, errorHandler, completeHandler, initHandler } = useHandleStatus();
  const [results, setResults] = useState<useGetTokenBalancesNOut>();

  const tokensPriceBySymbolRef = useRef<Map<string, MoralisTokenPrice>>(new Map<string, MoralisTokenPrice>());
  function init() {
    initHandler();
    setResults(undefined);
  }

  //#region Generate /Filter
  function generateChainsTotalBalanceUsd(datas: UseGetTokenBalancesOut[]) {
    return datas.reduce((sum, data) => {
      const price = data.results?.reduce((_sum, _data) => {
        const value = _data.balanceUsd ?? calcUsdByWei(_data.balance, _data.decimals, Number(_data.usdPrice ?? 0));
        return _sum + Number(value);
      }, 0);
      return sum + (price ?? 0);
    }, 0);
  }
  function generateChainsTotalBalanceUsdByStable(datas: UseGetTokenBalancesOut[]) {
    //TODO
    const tmp = new Map();
    tmp.set('current', datas);
    return generateChainsTotalBalanceUsdBySymbol(tmp);
  }
  function generateChainTotalBalanceUsdByDates(_results: Map<string, UseGetTokenBalancesOut[]> | undefined) {
    const entity: UseGetTokenBalancesOutByDates = new Map();
    _results?.forEach((value, key) => {
      const totalBalanceUsd = generateChainsTotalBalanceUsd(value);
      const data = {
        list: value,
        totalBalanceUsd,
      };
      entity.set(key, data);
    });
    return entity;
  }

  function generateChainsTotalBalanceUsdBySymbol(
    _results: Map<string, UseGetTokenBalancesOut[]>,
  ): Map<string, UseGetTokenBalancesOutList> {
    // Map to store the total balance USD for each symbol
    const symbolMapByDate: Map<string, UseGetTokenBalancesOutList> = new Map();
    const symbolMap: Map<string, number> = new Map();

    // Iterate over each entry in the results map
    _results.forEach((value, key) => {
      const entity: UseGetTokenBalancesOutList = {
        list: new Map(),
        totalBalanceUsd: 0,
      };
      // Iterate over each token balance result in the UseGetTokenBalancesOut array
      value?.forEach((tokenBalance) => {
        // Calculate the total balance USD for the current token balance
        const balanceUsd = tokenBalance.results?.forEach((balance) => {
          const _balanceUsdStr =
            balance.balanceUsd ?? calcUsdByWei(balance.balance, balance.decimals, balance.usdPrice ?? 0);
          const _balanceUsd = Number(_balanceUsdStr);
          const symbol = balance.symbol.toLowerCase();
          if (entity.list.has(symbol)) {
            entity.list.set(symbol, entity.list.get(symbol) ?? 0 + _balanceUsd);
          } else {
            entity.list.set(symbol, _balanceUsd);
          }
          entity.totalBalanceUsd += _balanceUsd;
        });
      });
      // Convert the symbolMap to the final structure in UseGetTokenBalancesOutBySymbol

      symbolMapByDate.set(key, entity);
    });
    return symbolMapByDate;
  }

  function filterStableCoinBySymbol(datas: UseGetTokenBalancesOut[]): UseGetTokenBalancesOut[] {
    const res = datas.map((data) => {
      return {
        chain: data.chain,
        results: data.results?.filter(
          (_data) => stableCoinListBySymbol.findIndex((symbol) => symbol === _data.symbol.toLowerCase()) !== -1,
        ),
      };
    });
    return res;
  }

  // eslint-disable-next-line complexity
  function generateHasTokenTradeInOneDay(_address: string, data?: MoralisTokenTransfer) {
    const calcBasic = 5;
    const tradesBasic = 1;
    let calc = 0;
    const tokenStatus: Record<string, { inCount: number; outCount: number }> = {};
    const list = data?.result ?? [];
    for (let i = 0; i < list.length; i++) {
      if (calc >= calcBasic) {
        return true;
      }
      const { address: tokenAddr, from_address } = list[i];
      const isIn = from_address.toLowerCase() === _address.toLowerCase();
      if (!tokenStatus[tokenAddr]) {
        tokenStatus[tokenAddr] = { inCount: 0, outCount: 0 };
      }
      if (isIn) {
        tokenStatus[tokenAddr].inCount++;
      } else {
        tokenStatus[tokenAddr].outCount++;
      }
      if (tokenStatus[tokenAddr].inCount >= tradesBasic && tokenStatus[tokenAddr].outCount >= tradesBasic) {
        calc++;
      }
    }
    if (calc >= calcBasic) {
      return true;
    }
    return false;
  }

  //#endregion

  async function fetchMoralisApiMultiTokensUsdPrice(
    tokens: { token_address: string }[],
    chain: Chain,
  ): Promise<MoralisTokenPrice[]> {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('X-API-Key', process.env.NEXT_PUBLIC_MORALIS_API_KEY ?? '');
    const options = {
      method: 'POST',
      headers,
      body: JSON.stringify({
        tokens,
      }),
    };

    try {
      const res = await fetch(
        `${MoralisApi.MULTITOKENPRICE}?chain=${chain.chain.apiHex}&include=percent_change`,
        options,
      );

      const _results = await res.json();
      //
      if (!Array.isArray(_results)) {
        return tokens.map((token) => {
          return {
            usdPrice: 0,
            decimals: 0,
            address: token.token_address,
            chain,
            error: 'array is empty',
            raw: _results,
          };
        });
      }

      const result = _results
        .filter((_res) => !!_res)
        .map((_res_: MoralisTokenPriceApi) => {
          return {
            decimals: Number(_res_.tokenDecimals),
            usdPrice: _res_.usdPrice,
            chain,
            address: _res_.tokenAddress,
            raw: _res_,
          };
        });
      return result;
    } catch (e) {
      console.error(e);
      const res = tokens.map((token) => {
        return { usdPrice: 0, decimals: 0, address: token.token_address, chain, error: e };
      });
      return res;
    }
  }
  async function fetchMoralisApiMultiTokensUsdPriceByLimit(
    tokens: { token_address: string }[],
    chain: Chain,
  ): Promise<MoralisTokenPrice[]> {
    const limit = 25;
    const _results: MoralisTokenPrice[] = [];

    for (let i = 0; i < tokens.length; i += limit) {
      //
      const batchTokens = tokens.slice(i, i + limit);
      const batchResult = await fetchMoralisApiMultiTokensUsdPrice(batchTokens, chain);
      _results.push(...batchResult);
    }

    return _results;
  }
  //#region Bluwhale Erc20 price fetchTokenPriceByBluwhaleApiFromMoralisResponse
  async function fetchTokenUsdPriceByBluwhaleApi(_tokenAddressList: Array<string>, _chain: BluwhaleChain) {
    const url = `${basicConfig.bluwhale.walletAPIUrl}/erc20/price/`;
    return getErc20Price(url, _tokenAddressList, _chain);
  }
  async function fetchTokenUsdPriceByBluwhaleApiByLimit(_tokenAddressList: Array<string>, _chain: BluwhaleChain) {
    const LIMITI = 100;
    const _results = Array<Erc20PriceResponse>();
    const taskCounts = Math.ceil(_tokenAddressList.length / LIMITI);
    for (let i = 0; i < taskCounts; i++) {
      const start = i * LIMITI;
      const end = start + LIMITI;
      const batchTokenList = _tokenAddressList.slice(start, end);
      const res = await fetchTokenUsdPriceByBluwhaleApi(batchTokenList, _chain);
      _results.push(res);
    }
    return _results;
  }
  async function fetchTokenPriceByBluwhaleApiFromMoralisResponse(datas: MoralisTokenApi[], chain: Chain) {
    if (!datas.length) {
      return [];
    }
    const tokenAddressList = datas.map((data) => data.token_address);
    return fetchTokenUsdPriceByBluwhaleApiByLimit(tokenAddressList, chain.bluApiChain);
  }
  //#endregion

  //#region Bluwhale Erc20 balance fetchChainCurrentTokenBalancesV2
  async function fetchChainCurrentTokenBalancesFromBluwhaleApi(
    _address: string,
    chain: BluwhaleChain,
  ): Promise<WalletTokenBalanceResponse> {
    const url = `${basicConfig.bluwhale.walletAPIUrl}/wallets/balance/erc20/`;
    return getErc20Balance(url, _address, chain);
  }
  function generateDataFromBluwhaleApi(
    _address: string,
    _chain: Chain,
    _data: WalletTokenBalanceResponse,
  ): UseGetTokenBalancesOut {
    const _results: UseGetTokenBalancesOut = {
      chain: _chain,
      results: [],
    };
    _address = _address.toLowerCase();
    const list = _data.data[_address];
    list.forEach((item) => {
      _results.results?.push({
        chain: _chain,
        address: item.contractAddress,
        balance: item.balance,
        balanceUsd: item.balanceUsd,
        decimals: item.decimals,
        symbol: item.symbol,
        usdPrice: item.price,
        raw: item,
      });
    });
    return _results;
  }
  async function fetchChainCurrentTokenBalancesV2(_address: string, _chain: Chain): Promise<UseGetTokenBalancesOut> {
    const res = await fetchChainCurrentTokenBalancesFromBluwhaleApi(_address, _chain.bluApiChain);
    const _data = generateDataFromBluwhaleApi(_address, _chain, res);
    return _data;
  }
  //#endregion

  async function fetchMoralisApiWalletTokenBalances(
    _address: string,
    chain: Chain,
    tokenAddresses?: string[],
    toBlock?: MoralisBlock,
  ) {
    try {
      const to_block = toBlock && toBlock.block;
      const response = await Moralis.EvmApi.token.getWalletTokenBalances({
        chain: chain.chain.apiHex,
        address: _address,
        toBlock: to_block,
        tokenAddresses,
      });
      const responseJson: MoralisTokenApi[] = response.toJSON();
      return responseJson;
    } catch (e) {
      console.error(
        `fetchMoralisApiWalletTokenBalances | address : ${_address} | chain:${chain.chain.name}|error:${e}`,
      );
      return [];
    }
  }

  function checkTokenPriceNotInMap(datas: MoralisTokenApi[]) {
    const counts = datas.length;
    const fetchTokenPriceList: { token_address: string }[] = [];
    for (let i = 0; i < counts; i++) {
      const key = datas[i].symbol.toLowerCase();
      const { token_address } = datas[i];
      if (!tokensPriceBySymbolRef.current.has(key)) {
        fetchTokenPriceList.push({ token_address });
      }
    }
    return fetchTokenPriceList;
  }
  function setTokenPriceToMap(datas: MoralisTokenPrice[], oriDatas: MoralisTokenApi[]) {
    const priceRef = tokensPriceBySymbolRef.current;
    datas.forEach((res) => {
      const key = oriDatas
        .find((ori) => ori.token_address.toLowerCase() === res.address.toLowerCase())
        ?.symbol.toLowerCase();
      if (!key) {
        return;
      }
      const isError = tokensPriceBySymbolRef.current.get(key)?.error !== undefined;
      if (!isError) {
        tokensPriceBySymbolRef.current.set(key, res);
      }
      priceRef.set(key, res);
    });

    return tokensPriceBySymbolRef.current;
  }
  function generateTokenUsdPrice(
    datas: MoralisTokenApi[],
    chain: Chain,
    filterUsdDatas: Map<string, MoralisTokenPrice>,
  ) {
    return datas.map((data) => {
      const key = data.symbol.toLowerCase();
      // const priceOb = tokensPriceBySymbolRef.current.get(key);
      const priceOb = filterUsdDatas.get(key);
      return {
        chain,
        address: data.token_address,
        balance: data.balance,
        decimals: data.decimals,
        symbol: data.symbol,
        usdPrice: priceOb?.usdPrice,
        raw: { data, price: priceOb },
      };
    });
  }
  function generateTokenUsdPriceV2(
    datas: Array<MoralisTokenApi>,
    chain: Chain,
    filterUsdDatas: Array<Erc20PriceResponse>,
  ): Array<TokenBalanceInUsdPrice> {
    const usdData: Array<Erc20PriceData> = [];
    filterUsdDatas.forEach((item) => {
      usdData.push(...item.data);
    });
    return datas.map((data) => {
      const priceOb = usdData.find((item) => item.tokenAddress.toLowerCase() === data.token_address.toLowerCase());
      return {
        chain,
        address: data.token_address,
        balance: data.balance,
        balanceUsd: calcUsdByWei(data.balance, data.decimals, Number(priceOb?.usdPrice ?? 0)),
        decimals: data.decimals,
        symbol: data.symbol,
        usdPrice: priceOb?.usdPrice,
        raw: { data, price: priceOb },
      };
    });
  }
  async function getTokensUsdPrice(datas: MoralisTokenApi[], chain: Chain) {
    const fetchTokenPriceList = checkTokenPriceNotInMap(datas);
    const priceResults = await fetchMoralisApiMultiTokensUsdPriceByLimit(fetchTokenPriceList, chain);
    await delay(0.5);
    return setTokenPriceToMap(priceResults, datas);
    // let results = generateTokenUsdPrice(datas, chain);
    // return results;
  }

  async function fetchChainCurrentTokenBalances(
    _address: string,
    chain: Chain,
    _whiteList: string[],
    toBlock?: MoralisBlock,
  ): Promise<UseGetTokenBalancesOut> {
    //#region
    // 1. fetch API by chain
    // if wallet has more than 100 erc20 it will coast more than 200ms ,
    // so use token address list to request API
    const datas = await fetchMoralisApiWalletTokenBalances(_address, chain);
    // 2. white list filter
    const filterDatas = datas.filter(
      (data) =>
        _whiteList.findIndex((_address_) => _address_.toLowerCase() === data.token_address.toLowerCase()) !== -1,
    );
    //#endregion
    //#region
    // replace 1, 2
    // const datas = await fetchMoralisApiWalletTokenBalances(address, chain, whiteList, toBlock);
    // const filterDatas = datas;
    //#endregion
    // 3. get usd price
    //TODO
    //phase 1 because of Moralis RATE LIMIT,
    //just get same symbol to the same usd price
    const filterUsdDatas = await getTokensUsdPrice(filterDatas, chain);
    // 4. generate data
    const _results = generateTokenUsdPrice(filterDatas, chain, filterUsdDatas);

    return { chain, results: _results };
  }
  async function fetchChainCurrentTokenBalancesN(
    _address: string,
    chain: Chain,
    _whiteList: Set<string>,
    toBlock?: MoralisBlock,
  ): Promise<UseGetTokenBalancesOut> {
    // 1. fetch API by chain
    // if wallet has more than 100 erc20 it will coast more than 200ms ,
    // so use token address list to request API
    const datas = await fetchMoralisApiWalletTokenBalances(_address, chain);
    // 2. white list filter
    const filterDatas = datas.filter((data) => _whiteList.has(data.token_address.toLowerCase()));

    // 3. get usd price
    //TODO
    const filterUsdDatas = await fetchTokenPriceByBluwhaleApiFromMoralisResponse(filterDatas, chain);
    // 4. generate data
    const _results = generateTokenUsdPriceV2(filterDatas, chain, filterUsdDatas);

    return { chain, results: _results };
  }

  async function handleSelectFunctionByChain(
    _address: string,
    chain: Chain,
    _whiteList: Set<string>,
    toBlock?: MoralisBlock,
  ) {
    if (chain.bluApiChain === BLUWHALE_CHAIN.ETHEREUM) {
      return fetchChainCurrentTokenBalancesV2(_address, chain);
    }
    // return fetchChainCurrentTokenBalances(address, chain, whiteList, toBlock);
    return fetchChainCurrentTokenBalancesN(_address, chain, _whiteList, toBlock);
  }

  async function fetchChainsCurrentTokenBalances(
    _address: string,
    chains: Chain[],
    toBlock?: MoralisBlock,
  ): Promise<UseGetTokenBalancesOut[]> {
    try {
      const counts = chains.length;
      const _results: UseGetTokenBalancesOut[] = [];
      for (let i = 0; i < counts; i++) {
        // const start = new Date().getTime();
        const chain = chains[i];
        const _whiteList = defaultTokenList[chain.key];
        const result = await handleSelectFunctionByChain(_address, chain, _whiteList, toBlock);
        _results.push(result);
        // const end = new Date().getTime();
        // console.log(
        //   `excuteFetchChainsCurrentTokenBalances excuteFetchChainsTokenBalances fetchChainsCurrentTokenBalances | chain : ${
        //     chain.key
        //   }| ${end - start}ms`,
        // );
      }
      return _results;
    } catch (e) {
      if (!(e as string).includes('429')) {
        console.log(e);
      }
      errorHandler();
      throw e;
    }
  }

  async function excuteFetchChainsTokenBalances(_address: string, chains: Chain[], toBlocks?: MoralisBlock) {
    return fetchChainsCurrentTokenBalances(_address, chains, toBlocks);
  }

  async function excuteFetchChainsCurrentTokenBalances(_address: string, chains: Chain[]) {
    'calc process time';
    // const start = new Date().getTime();
    const currentTokensBalance = await excuteFetchChainsTokenBalances(_address, chains);
    // let end = new Date().getTime();
    // console.log(`excuteFetchChainsCurrentTokenBalances excuteFetchChainsTokenBalances| ${end - start}ms`);
    // setCurrentTokensBalance((_pre) => currentTokensBalance);

    const totalBalanceUsd = generateChainsTotalBalanceUsd(currentTokensBalance);

    //Stable Coin
    const isStableCoinList = filterStableCoinBySymbol(currentTokensBalance);
    // const totalStableCoinBalanceUsdBySymbol = generateChainsTotalBalanceUsd(isStableCoinList);
    const tmp = generateChainsTotalBalanceUsdByStable(isStableCoinList);
    const balanceSumByStableCoin = tmp.values().next().value;
    // setBalanceSumByStableCoin((_pre) => balanceSumByStableCoin);

    const mapResults: Map<string, UseGetTokenBalancesOut[]> = new Map();
    mapResults.set('current', currentTokensBalance);
    const balancesBySymbolByDates = generateChainsTotalBalanceUsdBySymbol(mapResults);
    const balanceSumBySymbol = balancesBySymbolByDates.values().next().value;
    // setBalanceSumBySymbol((_pre) => balanceSumBySymbol);
    // SetTotalBalanceUsd((_pre) => totalBalanceUsd);
    const balanceSumGroupBySymbol = balanceSumBySymbol.value;
    // setBalanceSumGroupBySymbol((_pre) => firstValue.value);
    // end = new Date().getTime();
    // console.log(`excuteFetchChainsCurrentTokenBalances END| ${end - start}ms`);
    return {
      mapResults,
      isStableCoinList,
      tmp,
      totalBalanceUsd,
      currentTokensBalance,
      balanceSumByStableCoin,
      balanceSumBySymbol,
      balanceSumGroupBySymbol,
      balancesBySymbolByDates,
    };
  }

  /////////////////////////////////

  async function fetchChainsCurrentTokenBalancesMultiMonths(
    _address: string,
    datesMap: Map<
      string,
      {
        chain: Chain;
        block: MoralisBlock;
      }[]
    >,
  ) {
    const _results: Map<string, UseGetTokenBalancesOut[]> = new Map();

    const datesArray = Array.from(datesMap.entries());
    const counts = datesArray.length;
    for (const [key, value] of datesArray) {
      const _counts = value.length;
      for (let k = 0; k < _counts; k++) {
        const ob = value[k];
        const _whiteList = defaultTokenList[ob.chain.key];
        // const result = await fetchChainCurrentTokenBalances(address, ob.chain, whiteList, ob.block);
        const _result = await handleSelectFunctionByChain(_address, ob.chain, _whiteList, ob.block);
        if (!_result) {
          continue;
        }
        if (!_results.has(key)) {
          _results.set(key, [_result]);
        } else {
          _results.set(key, _results.get(key)!.concat(_result));
        }
      }
    }

    return _results;
  }
  async function excuteFetchChainsCurrentTokenBalancesMultiMonths(_address: string, chains: Chain[], _months = 3) {
    const toBlocksMap: Map<string, { chain: Chain; block: MoralisBlock }[]> = new Map();
    // 1. get blocks by months ago
    const toBlocksByChains = [];
    for (let i = 0; i < chains.length; i++) {
      const blocks = await getBlockNumberListByTimeStmps(chains[i].chain, _months, staticBolockRef.current);
      blocks.forEach((block) => {
        if (block) {
          const key = moment(block.date).format('YYYY-MM-DD');
          if (toBlocksMap.has(key)) {
            const value = [...toBlocksMap.get(key)!, { chain: chains[i], block }];
            toBlocksMap.set(key, value);
          } else {
            toBlocksMap.set(key, [{ chain: chains[i], block }]);
          }
        }
      });
    }
    // 2. fetch data by Date &blocks
    const _results = await fetchChainsCurrentTokenBalancesMultiMonths(_address, toBlocksMap);
    return _results;
  }

  async function excuteByDates(_address: string, chains: Chain[], _months = 3) {
    const tokenBalancesByDates = await excuteFetchChainsCurrentTokenBalancesMultiMonths(_address, chains, _months);
    const tokenBalancesUsdByDates = generateChainTotalBalanceUsdByDates(tokenBalancesByDates);
    const balancesBySymbolByDates = generateChainsTotalBalanceUsdBySymbol(tokenBalancesByDates);

    const balanceSumBySymbol = balancesBySymbolByDates.values().next().value;

    return {
      tokenBalancesByDates,
      tokenBalancesUsdByDates,
      balanceSumBySymbol,
    };
  }

  async function excuteHistory(_address: string, _chains: Chain[], _months = 3) {
    const _results = await excuteByDates(_address, _chains, _months);
    setResults((pre) => {
      return { ...pre, ..._results };
    });
    return _results;
  }
  async function fetchTransferByDateByChain(_address: string, _chain: Chain, _fromDate: string, _toDate: string) {
    try {
      const result = await Moralis.EvmApi.token.getWalletTokenTransfers({
        address: _address,
        chain: _chain.chain.apiHex,
        toDate: _toDate,
        fromDate: _fromDate,
      });
      return result;
    } catch (e) {
      console.log(`fetchTransferByDateByChain | address : ${_address} | chain:${_chain.chain.name}|error:${e}`);
      return undefined;
    }
  }
  async function fetchTransferByDateByChains(_address: string, chains: Chain[], fromDate: string, toDate: string) {
    const _results = [];
    for (let i = 0; i < chains.length; i++) {
      const res = await fetchTransferByDateByChain(_address, chains[i], fromDate, toDate);
      _results.push({ chain: chains[i], transfers: res });
    }
    return _results;
  }

  async function excuteGetEthHasTokenBuySell(_address: string) {
    const { currentDate, daysAgoDate } = getDateFromToByDays(1, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    const res = await fetchTransferByDateByChain(_address, supportChains[CHAIN.ETHEREUM], daysAgoDate, currentDate);
    const resJson = res?.toJSON();
    const hasInOut = generateHasTokenTradeInOneDay(_address, resJson);
    return { hasInOut, resJson };
  }
  async function excuteCurrent(_address: string, _chains: Chain[]) {
    const _results = await excuteFetchChainsCurrentTokenBalances(_address, _chains);
    // const { hasInOut: hasFlipper, resJson } = await excuteGetEthHasTokenBuySell(_address);
    setResults((pre) => {
      return { ...pre, ..._results };
    });
    return { ..._results };
  }

  async function excute(_address: string, _months?: number): Promise<useGetTokenBalancesNOut> {
    // throw new Error('429');
    if (_months !== 0 && _months) {
      return excuteHistory(_address, chainList, _months);
    }
    return excuteCurrent(_address, chainList);
  }
  async function handleExcuteCurrent(_address: string): Promise<useGetTokenBalancesNOut> {
    return excuteCurrent(_address, chainList);
  }

  useEffect(() => {
    if (!address) {
      return;
    }
    if (preStatus && preStatus !== 'success' && preStatus !== 'error') {
      return;
    }
    if (status === 'loading') {
      return;
    }
    init();
    excuteHandler(excute(address, months))
      .then(() => completeHandler())
      .catch(() => errorHandler());
  }, [address, months, preStatus]);
  return {
    // tokenBalancesByDates,
    // tokenBalancesUsdByDates,
    // currentTokensBalance,
    // totalBalanceUsd,
    // balanceSumByStableCoin,
    // balanceSumBySymbol,
    // balanceSumGroupBySymbol,
    results,
    status,
    onInit: init,
    onExcute: excute,
    onExcuteCurrent: handleExcuteCurrent,

    onExcuteGetFlipper: excuteGetEthHasTokenBuySell,
  };
}
