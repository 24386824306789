/* eslint-disable complexity */
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { TransactionHistory, NftPlatform, PlatformChart, InfoPlatform, UserPlatform } from '.';
import { Flex, Box, Text } from '@chakra-ui/react';
import { useDashBoard } from '../contexts/DashBoardContext';
import { basicConfig } from '@/configs';
import useGetAggregate from '../hooks/useGetAggregate';
import { useGenerateUIAggregate } from '../hooks/useGenerateUIAggregate';
import useHandleError from '../hooks/useHandleError';
import { useRouter } from 'next/router';
import { useFetchUserClaimStatus } from '../../../../../redux/useFetchUserClaimStatus';
function Platform(props: {
  myAddress?: string;
  address: string;
  onFollowClick: (address: string) => void;
  isHunted?: boolean;
  hideFollow?: boolean;
  enterpriseBack?: boolean;
  refetchOut?: () => void;
}) {
  const {
    myAddress = '',
    address,
    onFollowClick,
    isHunted = false,
    hideFollow = false,
    enterpriseBack = false,
    refetchOut,
  } = props;
  const { errorFn } = useDashBoard();
  //compaigns
  const router = useRouter();
  const { showBack } = router.query ?? {};
  const { result, refetch: refetchUserClaim } = useFetchUserClaimStatus(myAddress);
  const { claimed: hasReward } = result ?? {};
  // const { data } = useGetConsumerCompaigns();
  // const { inbox = [] } = data ?? {};

  const [compaignsList, setCompaigns] = useState([]);

  const aggregate = useGetAggregate({ address, preStatus: address === basicConfig.demo.address ? 'idle' : 'success' });
  useHandleError(
    [
      aggregate.tsxStatus,
      aggregate.nativeStatus,
      aggregate.tokenStatus,
      aggregate.tokenStatus,
      aggregate.nftMetaStatus,
      aggregate.nftPriceStatus,
    ],
    errorFn,
  );
  const uiAggregate = useGenerateUIAggregate({
    address,
    nativeDto: {
      status: aggregate.nativeStatus,
      dto: aggregate.nativeDto,
    },
    tokenDto: { status: aggregate.tokenStatus, dto: aggregate.tokenDto },
    nftDto: { metaStatus: aggregate.nftMetaStatus, priceStatus: aggregate.nftPriceStatus, dto: aggregate.nftDto },
    tsxDto: { status: aggregate.tsxStatus, dto: aggregate.tsxDto },
    // protocolDto: { status: aggregate.protocolStatus, dto: aggregate.protocolDto },
    // tagsDto: { status: aggregate.attributesStatus, dto: aggregate.attributesDto },
    attributesDto: { status: aggregate.attributesStatus, dto: aggregate.attributesDto },
    protocolDtoV2: { status: aggregate.protocolStatusV2, dto: aggregate.protocolDtoV2 },
  });

  useEffect(() => {
    // console.log('aggregate', aggregate);
  }, [aggregate]);
  useEffect(() => {
    // console.log('uiAggregate', uiAggregate);
  }, [uiAggregate]);

  const handleBackClick = () => {
    if (enterpriseBack) {
      router.replace('/enterprise');
      return;
    }
    router.back();
  };

  const isDemo = address === basicConfig.demo.address;
  return (
    <Flex flexDirection={'column'}>
      <Flex
        mb={4}
        display={{
          base: 'none',
          md: router?.history?.length || enterpriseBack ? 'block' : 'none',
        }}
      >
        <button onClick={handleBackClick}>
          <Text fontSize={20}>{enterpriseBack ? '< My dApp' : `< Back`}</Text>
        </button>
      </Flex>
      <UserPlatform
        tag={{
          tagsString: uiAggregate.tagsUiDto,
          status: uiAggregate.tagsUiStatus,
        }}
        myAddress={myAddress}
        credit={{ data: uiAggregate.creditUiDto, status: uiAggregate.creditUiStatus }}
        isDemo={isDemo}
        isHunted={isHunted}
        onFollowClick={onFollowClick}
        hideFollow={hideFollow}
        refetchOut={refetchOut}
      />
      {/* {myAddress === address && compaignsList.length > 0 && hasReward && <Slick compaignsList={compaignsList} />} */}
      <InfoPlatform info={uiAggregate.infosUiDto} isDemo={isDemo} />
      <PlatformChart
        radarDto={uiAggregate.radarUiDto}
        historyBalanceDto={aggregate.networthDto?.tokenBalancesUsdByDates}
        chartTokenAlloctionDto={uiAggregate.tokenAlloctionUiDto}
        chartChainAlloctionDto={uiAggregate.chainAlloctionUiDto}
        radarStatus={uiAggregate.radarUiStatus}
        tokenHistoryStatus={aggregate.networthStatus}
        chartTokenAlloctionStatus={uiAggregate.tokenAlloctionUiStatus}
        chartChainAlloctionStatus={uiAggregate.chainAlloctionUiStatus}
        // radarStatus={'loading'}
        // tokenHistoryStatus={'loading'}
        // chartTokenAlloctionStatus={'loading'}
        // chartChainAlloctionStatus={'loading'}
        isDemo={isDemo}
      />
      <TransactionHistory transactions={aggregate.tsxDto} status={aggregate.tsxStatus} isDemo={isDemo} />
      <NftPlatform
        status={aggregate.nftMetaStatus}
        priceStatus={aggregate.nftPriceStatus}
        nftListByFlat={aggregate.nftDto?.nftListByFlat}
        isDemo={isDemo}
      />
    </Flex>
  );

  // return (
  //   <Flex flexDirection={'column'}>
  //     <UserPlatform
  //       tag={{ tagsString: tagsDto, status: radarStatus }}
  //       credit={{ data: creditDto, status: infosStatus }}
  //     />
  //     <InfoPlatform info={infosDto} status={infosStatus} />
  //     <ChartPlatform
  //       radarDto={radarDto}
  //       historyBalanceDto={historyBalanceDto}
  //       radarStatus={radarStatus}
  //       chartTokenAlloctionDto={chartTokenAlloctionDto}
  //       chartChainAlloctionDto={chartChainAlloctionDto}
  //       tokenHistoryStatus={netWorthHistoryStatus}
  //       chartTokenAlloctionStatus={chartTokenAlloctionStatus}
  //       chartChainAlloctionStatus={chartChainAlloctionStatus}
  //     />
  //     <TransactionHistory transactions={tsxDto} status={tsxHistoryDtoStatus} />
  //     <NftPlatform status={nftStatus} nftListByFlat={nftDto} />
  //   </Flex>
  // );
}

export default Platform;
