import * as React from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, chakra, TableContainer } from '@chakra-ui/react';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  ColumnDef,
  SortingState,
  getSortedRowModel,
} from '@tanstack/react-table';
import NoData from '../elements/NoData';

export type DataTableProps<Data extends object> = {
  data: Data[];
  columns: ColumnDef<Data, any>[];
};

export function DataTable<Data extends object>({ data, columns }: DataTableProps<Data>) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  return (
    <TableContainer position="relative" maxH="400px" overflowY="scroll" whiteSpace="pre-wrap" overflowX="auto">
      <Table
        overflowY="scroll"
        size="sm"
        variant="striped"
        __css={{ 'table-layout': 'fixed', width: 'full' }}
        position="relative"
      >
        <Thead bgColor="#102538" p="1" color="#9ba2a9" top={0} zIndex="docked" w="100%" position="sticky">
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id} p="2">
              {headerGroup.headers.map((header) => {
                // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                const { meta } = header.column.columnDef;
                return (
                  <Th
                    color="#9ba2a9"
                    bgColor="#102538"
                    border="none"
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    //@ts-ignore
                    isNumeric={meta?.isNumeric}
                    w={[`${header.column.getSize() * 2.5}px`, null, `${header.column.getSize()}px`]}
                    fontSize={[10, null, 14]}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {header.column.getCanSort() && (
                      <chakra.span pl={['0', null, '2']}>
                        {header.column.getIsSorted() === 'desc' ? (
                          <TriangleDownIcon aria-label="sorted descending" />
                        ) : (
                          <TriangleUpIcon aria-label="sorted ascending" />
                        )}
                      </chakra.span>
                    )}
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        {table.getRowModel().rows.length > 0 ? (
          <Tbody>
            {table.getRowModel().rows.map((row, index) => (
              <Tr bgColor={`${index % 2 ? '#06192a' : '#0f2132'}`} key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                  const { meta } = cell.column.columnDef;
                  return (
                    <Td
                      border="none"
                      bgColor={`${index % 2 ? '#0f2132' : '#06192a'} !important`}
                      key={cell.id}
                      //@ts-ignore
                      isNumeric={meta?.isNumeric}
                      w={[`${cell.column.getSize() * 2.5}px`, null, `${cell.column.getSize()}px`]}
                      fontSize={[10, null, 14]}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Td>
                  );
                })}
              </Tr>
            ))}
          </Tbody>
        ) : (
          ''
        )}
      </Table>
      {table.getRowModel().rows.length === 0 ? (
        <div className="min-h-[200px] flex justify-center items-center">
          <NoData />
        </div>
      ) : (
        // <div className="p-6 mx-auto text-center text-gray-400">No Data</div>
        ''
      )}
    </TableContainer>
  );
}
