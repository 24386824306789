import { BluwhaleChain, WalletNftCollectionsResponse, WalletNftHoldsResponse } from '@/types/bluwhaleDataApi';
import axios from 'axios';

export type GetNftApiOptions = {
  platformId: BluwhaleChain;
  address: string;
  page: number;
  pageSize: number;
};

async function getNftCollections(_url: string, body: GetNftApiOptions): Promise<WalletNftCollectionsResponse> {
  try {
    const response = await axios.post(_url, body);
    const json = response.data;
    return json;
  } catch (e) {
    console.log(e);
    throw e;
  }
}

async function getNftHolds(_url: string, body: GetNftApiOptions): Promise<WalletNftHoldsResponse> {
  try {
    const response = await axios.post(_url, body);
    const json = response.data;
    return json;
  } catch (e) {
    console.log(e);
    throw e;
  }
}

export { getNftCollections, getNftHolds };
