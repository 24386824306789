/* eslint-disable prefer-arrow-callback */
/* eslint-disable prefer-template */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable complexity */
import React from 'react';
import Image from 'next/image';
import { BlockChart } from '../common';
import { Avatar, Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { Doughnut } from 'react-chartjs-2';
import { TooltipItem } from 'chart.js';
//@ts-ignore
import { formatNumber } from '@/utils/format';
import { Status } from '../interfaces/types';
import NoData from './NoData';
import Loading from './Loading';
import { useDashBoard } from '../contexts/DashBoardContext';
import { icon_api } from '../configs';
import { toLower } from 'ramda';
import { mockTokenAlloction } from '@/mocks/mockData';
import { Icon, InfoOutlineIcon } from '@chakra-ui/icons';

export type ChartTokenAlloctionParams = {
  status?: Status;
  datas?: {
    list:
      | {
          key: string;
          value: string;
          percent: number;
        }[]
      | undefined;
    balances: Map<string, number> | undefined;
  };
  isDemo?: boolean;
};

function ChartTokenAlloction({ datas, status, isDemo = false }: ChartTokenAlloctionParams) {
  const { globalStatus } = useDashBoard();
  const isLoading = globalStatus === 'loading' || status === 'loading' || status === 'idle';
  const isComplete = globalStatus === 'idle' && status === 'success';
  const isError = status === 'error';
  // console.log('tokenAlloction Chart', status);

  function calcToken(balances?: Map<string, number>) {
    if (!balances) {
      return [];
    }
    const sortedBalances = Array.from(balances.entries())
      .sort((a, b) => b[1] - a[1])
      .slice(0, 5);
    const totalSum = balances ? Array.from(balances.values()).reduce((sum, balance) => sum + balance, 0) : 0;
    if (totalSum === 0) {
      return [];
    }
    const proportions = sortedBalances.map((entry) => ({
      // key
      key: entry[0],
      // value
      value: formatNumber(entry[1]),
      // percent
      percent: parseFloat(((entry[1] / totalSum) * 100).toFixed(2)),
    }));
    const remainingSum =
      Array.from(balances.values()).reduce((sum, balance) => sum + balance, 0) -
      sortedBalances.reduce((sum, entry) => sum + entry[1], 0);
    const remainingProportion = parseFloat(((remainingSum / totalSum) * 100).toFixed(2));
    const others = { key: 'others', value: formatNumber(remainingSum), percent: remainingProportion };
    const result = proportions.concat(others);
    return result;
  }

  // const list = calcToken(balances);
  const list = datas?.list ?? [];

  const colors = [
    'rgba(104, 180, 227, 1)',
    'rgba(130, 252, 211, 1)',
    'rgba(92, 201, 156, 1)',
    'rgba(127, 127, 127, 1)',
    'rgba(55, 125, 247, 1)',
    'rgba(140, 138, 203, 1)',
  ];
  const coinsIconApiList = icon_api.list;
  // const iconUrl = 'https://coinicons-api.vercel.app';
  const iconUrl = icon_api.url;
  const tokenList = isDemo
    ? mockTokenAlloction
    : list?.map((item, index) => {
        const symbol = coinsIconApiList.find((symbol) => symbol.toLowerCase() === item.key);
        return {
          tokenId: item.key,
          tokenImg: !symbol ? '' : `${iconUrl}/api/icon/${symbol}`,
          tokenName: item.key.toUpperCase(),
          percentage: item.percent,
          bgColor: colors[index],
          balance: item.value,
        };
      });
  const defaultTokenList = [
    {
      tokenId: '1',
      tokenImg: 'https://bit.ly/dan-abramov',
      tokenName: 'WETH',
      percentage: 70,
      bgColor: '#68B4E3',
    },
    {
      tokenId: '2',
      tokenImg: 'https://bit.ly/dan-abramov',
      tokenName: 'SWISE',
      percentage: 50,
      bgColor: '#82FCD3',
    },
    {
      tokenId: '3',
      tokenImg: 'https://bit.ly/dan-abramov',
      tokenName: 'MATIC',
      percentage: 20,
      bgColor: '#5CC99C',
    },
    {
      tokenId: '4',
      tokenImg: 'https://bit.ly/dan-abramov',
      tokenName: 'ABPT',
      percentage: 10,
      bgColor: '#7F7F7F',
    },
    {
      tokenId: '5',
      tokenImg: 'https://bit.ly/dan-abramov',
      tokenName: 'SNX',
      percentage: 50,
      bgColor: '#377DF7',
    },
    {
      tokenId: '6',
      tokenImg: '',
      tokenName: 'Others',
      percentage: 50,
      bgColor: '#8C8ACB',
    },
  ];
  const getToken = (tokenName: string) => {
    return tokenList.find((token) => token.tokenName === tokenName);
  };

  const getData = (tokenList: any) => {
    return tokenList.map((token: any) => token.percentage);
  };
  const getLabels = (tokenList: any) => {
    return tokenList.map((token: any) => token.tokenName);
  };
  const getDataColor = (tokenList: any) => {
    return tokenList.map((token: any) => token.bgColor);
  };

  const data = {
    labels: getLabels(tokenList),
    datasets: [
      {
        // data: getData(tokenList),
        data: tokenList,
        backgroundColor: getDataColor(tokenList),
        display: true,
        borderWidth: 0,
      },
    ],
  };

  const options = {
    parsing: { key: 'percentage' },
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
        position: 'chartArea',
        align: 'center',
        labels: {
          boxWidth: 20,
          fontSize: 14,
          fontColor: 'black',
          padding: 10,
          usePointStyle: true,
        },
      },
      tooltip: {
        // Disable the on-canvas tooltip
        enabled: false,
        displayColors: false,
        xAlign: 'center',
        yAlign: 'center',
        external: function (context: any) {
          // console.log('context', context);
          // Tooltip Element
          let tooltipEl = document.getElementById('chartjs-tooltip');

          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.innerHTML = `
              <div id="tooltip-body" class=" min-w-max bg-white bg-opacity-80 px-4 py-2 rounded-md"></div>
            `;
            document.body.appendChild(tooltipEl);
          }

          // Hide if no tooltip
          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = '0';
            // tooltipEl.style.opacity = '1';
            return;
          }

          // Set caret Position
          tooltipEl.classList.remove('above', 'below', 'no-transform');
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add('no-transform');
          }

          function getBody(bodyItem: { lines: any }) {
            return bodyItem.lines;
          }

          // Set Text
          if (tooltipModel.body) {
            const titleLines = tooltipModel.title || [];
            const bodyLines = tooltipModel.body.map(getBody);
            const token = getToken(titleLines[0]);
            // console.log('titleLines', titleLines);
            // console.log('token', token);

            let innerHtml = '<div>';

            titleLines.forEach(function (title: string, i: any) {
              // console.log('title', title);
              innerHtml += `
                <div class="flex gap-2">
                  <div class="flex justify-center items-center w-6 h-6">
                      <div class="relative rounded-full overflow-hidden h-full w-full bg-white flex justify-center items-center">
                      ${
                        token?.tokenImg
                          ? `<img src="${token.tokenImg}" alt="Image" class="object-cover object-center h-full w-full">`
                          : ` <div class="text-[#1E2B37] text-center text-xs">${title.slice(0, 1).toUpperCase()}</div>`
                      }
                      </div>
                  </div>
                  <div class="flex-1 font-bold text-black font-md">${title}</div>
                </div>
              `;
            });
            innerHtml += '</div><div class="pt-2">';

            bodyLines.forEach(function (body: any, i: any) {
              // const colors = tooltipModel.labelColors[i];
              // let style = 'background:' + colors.backgroundColor;
              // style += '; border-color:' + colors.borderColor;
              // style += '; border-width: 2px';
              // const span = '<span style="' + style + '">' + body + '</span>';
              // innerHtml += '<tr><td>' + span + '</td></tr>';
              innerHtml += `
                <div class="flex justify-between gap-6 text-black">
                  <div>Balance:</div>
                  <div class="flex-1 text-right">$${token?.balance ? token?.balance : ''}</div>
                </div>
                <!--
                <div class="flex justify-between gap-2 text-black">
                  <div>Value:</div>
                  <div class="flex-1 text-right">$${``}</div>
                </div>
                -->
                <div class="flex justify-between gap-2 text-black">
                  <div>Ratio:</div>
                  <div class="flex-1 text-right">${body}%</div>
                </div>
              `;
              // console.log('body', body);
            });
            innerHtml += '</div>';

            const tableRoot = tooltipEl.querySelector('#tooltip-body');
            // console.log('tableRoot', tableRoot);
            if (tableRoot === null) {
              return;
            }
            tableRoot.innerHTML = innerHtml;
          }

          const position = context.chart.canvas.getBoundingClientRect();

          // Display, position, and set styles for font
          tooltipEl.style.opacity = '1';
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
          tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
          tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
          tooltipEl.style.pointerEvents = 'none';
          tooltipEl.style.zIndex = '40';
        },
      },
    },
    cutout: '75%',
  };
  // console.log('ChartTokenAllocation', status, tokenList);

  const hasData = !isLoading && tokenList && isComplete && tokenList.length > 0;

  if (isLoading && !isDemo) {
    return (
      <BlockChart>
        <Tooltip
          hasArrow
          placement="bottom-start"
          label="The percentage of the top five tokens based on total token holdings"
          borderRadius="10px"
          bg="rgba(255, 255, 255, 0.8)"
          color="gray.900"
          fontSize="12px"
          maxWidth="150px"
          padding="8px"
          textAlign="center"
          transition="opacity 0.5s ease-in-out"
        >
          <div className="flex items-center mb-3 font-bold text-lg">
            <div className="text-lg font-bold ">Token Profile</div>
            <Icon as={InfoOutlineIcon} mx={1} color="#9ba2a9" fontSize="xs" />
          </div>
        </Tooltip>

        <div className="relative flex items-center justify-center h-[200px] md:h-full ">
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  ">
            <Loading h={100} />
          </div>
        </div>
      </BlockChart>
    );
  }
  return (
    <BlockChart>
      <Tooltip
        hasArrow
        placement="bottom-start"
        label="The percentage of the top five tokens based on total token holdings"
        borderRadius="10px"
        bg="rgba(255, 255, 255, 0.8)"
        color="gray.900"
        fontSize="12px"
        maxWidth="150px"
        padding="8px"
        textAlign="center"
        transition="opacity 0.5s ease-in-out"
      >
        <div className="flex items-center mb-3 font-bold text-lg">
          <div className="text-lg font-bold ">Token Profile</div>
          <Icon as={InfoOutlineIcon} mx={1} color="#9ba2a9" fontSize="xs" />
        </div>
      </Tooltip>

      {/* {isLoading && !isDemo && (
        <div className="flex items-center justify-center h-full">
          <Loading />
        </div>
      )} */}
      {(!tokenList || tokenList.length === 0) && isComplete && !isLoading && !isDemo && (
        <div className="flex items-center justify-center h-full">
          <NoData />
        </div>
      )}
      {(isDemo || hasData) && (
        <Box className="flex flex-1 gap-8 px-3" flexDir={['column', null, 'row']}>
          <Box className="flex-1" px={[10, null, 3]}>
            {/* @ts-ignore */}
            <Doughnut className="w-full" data={data} options={options} redraw={false} />
            {/* <Doughnut className="w-full" data={data} options={options} redraw={true} /> */}
          </Box>
          <div className="flex flex-col justify-between flex-1">
            <div id="js-legend" className="whatever you wanna add"></div>
            {tokenList.map((token: any) => (
              <Flex w="100%" alignItems="center" justifyContent="space-between" key={token.tokenId}>
                <Flex alignItems="center" mb="1">
                  {/* <Avatar size="xs" src={token.tokenImg ?? ''} name={token.tokenName} bgColor="#fff" color="#1E2B37" /> */}
                  {token?.tokenName === 'OTHERS' ? (
                    <Avatar size="xs" src={token.tokenImg ?? ''} name={''} bgColor="#fff" color="#1E2B37" />
                  ) : (
                    <Avatar
                      src={`/images/icon/${toLower(token?.tokenName || 'btc')}.png`}
                      bgColor="#fff"
                      name={token?.tokenName}
                      size="xs"
                      color="#1E2B37"
                    />
                  )}

                  <Text fontSize="xs" fontWeight="bold" pl="2">
                    {token.tokenName}
                  </Text>
                </Flex>
                <Text fontSize="sm" fontWeight="bold" color={token.bgColor}>
                  {token.percentage}%
                </Text>
              </Flex>
            ))}
          </div>
        </Box>
      )}
    </BlockChart>
  );
}

export default ChartTokenAlloction;
