/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-shadow */
import { MoralisTokenPrice, Status, TokenType } from '../interfaces/types';
import { useEffect, useState } from 'react';
import { CHAIN, Chain, chainList, defaultTokenList, supportChains } from '../configs';
import { fetchMutilTokenPriceByLimit } from '../utils/moralisUtils';
import { basicConfig } from '@/configs';
import { WalletNativeBalance, WalletNativeBalanceResponse } from '@/types/bluwhaleDataApi';
import { getNativeBalance } from '../api';
import { useBluwhale } from '@/context/BluwhaleContext';

export type DefaultTokensByChain = {
  [key in CHAIN]: {
    chain: Chain;
    tokens: Array<(TokenType & (MoralisTokenPrice extends {} ? MoralisTokenPrice : {})) | string>;
  };
};

type Native = {
  [key in CHAIN]: {
    native: MoralisTokenPrice;
  };
};
type Token = {
  [key in CHAIN]: {
    tokens: string[];
  };
};

export type TokensInfo = {
  [key in CHAIN]: {
    native: MoralisTokenPrice;
    token: any;
  };
};

export function useGetTokensInfo(address?: string) {
  const [reload, setReload] = useState<boolean>();
  const [tokensInfo, setTokensInfo] = useState<TokensInfo>();
  const [defaultTokens, setDefaultTokens] = useState<DefaultTokensByChain>();
  const [nativeTokens, setNativeTokens] = useState<DefaultTokensByChain>();
  const { user } = useBluwhale();

  function onReload() {
    setReload((pre) => !pre);
  }
  async function fetchNativePriceByChain(chain: Chain) {
    const price = await fetchMutilTokenPriceByLimit([chain.wrappedToken.address], chain.chain);
    const res = {
      [chain.key]: {
        native: price.values().next().value,
      },
    } as Native;

    return res;
  }
  //#region Bluwhale API
  async function fetchNativeBalance(_address: string): Promise<WalletNativeBalanceResponse> {
    const url = `${basicConfig.bluwhale.walletAPIUrl}/wallets/balance/native/`;
    return getNativeBalance(url, _address);
  }
  function generateNativeBalanceResponse(res: Array<WalletNativeBalance>, chainList: Chain[]): Native {
    const native = {} as Native;
    res.forEach((item) => {
      const chain = chainList.find((_chain) => _chain.bluApiChain === item.platformId);
      if (!chain) {
        return;
      }
      native[chain.key] = {
        native: {
          tokenSymbol: item.symbol,
          tokenName: item.name,
          decimals: item.decimals,
          usdPrice: item.price,
          chain,
          address: chain.wrappedToken.address,
        },
      };
    });
    return native;
  }
  async function fetchNativePriceByChainsByBluwhale(chainList: Chain[]): Promise<Native> {
    let _address = address || user?.address;
    if (!_address) {
      return {} as Native;
    }
    _address = _address.toLowerCase();

    const res = await fetchNativeBalance(_address);
    const native = generateNativeBalanceResponse(res.data[_address], chainList);

    return native;
  }
  //#endregion
  async function fetchNativePriceByChains(chainList: Chain[]): Promise<Native> {
    // const tasks = chainList.map((chain) => fetchNativePriceByChain(chain));
    const results: Native[] = [];
    for (let i = 0; i < chainList.length; i++) {
      const result = await fetchNativePriceByChain(chainList[i]);
      if (result) {
        results.push(result);
      }
    }

    // const results = await Promise.all(tasks);
    const combinedResult: Native = results.reduce((acc, result) => {
      // Get the chain key from the result object
      const key = Object.keys(result)[0] as CHAIN;
      acc[key] = result[key];
      return acc;
    }, {} as Native);

    return combinedResult;
  }
  async function fetchTokenListByChain(chain: Chain) {
    //TODO phase 2 call api
    const tokenSet = defaultTokenList[chain.key];
    return {
      [chain.key]: {
        tokens: Array.from(tokenSet),
      },
    } as Token;
  }
  async function fetchTokenList(chainList: Chain[]): Promise<Token> {
    const tasks = chainList.map((chain) => fetchTokenListByChain(chain));
    const results = await Promise.all(tasks);
    const combinedResult: Token = results.reduce((acc, result) => {
      // Get the chain key from the result object
      const key = Object.keys(result)[0] as CHAIN;
      acc[key] = result[key];
      return acc;
    }, {} as Token);

    return combinedResult;
  }

  function merge(native: Native, token: Token) {
    const merged = {} as TokensInfo;

    for (const key in native) {
      if (key in token) {
        // If the key exists in both native and token, combine them
        const _key = key as CHAIN;
        merged[_key] = {
          native: native[_key].native,
          // Assuming the token[key] contains the "tokens" property
          token: token[_key].tokens,
        };
      }
    }
    return merged;
  }
  async function excuteGetTokenInfos() {
    const tasks = [];
    // tasks.push(fetchNativePriceByChains(chainList));
    tasks.push(fetchNativePriceByChainsByBluwhale(chainList));
    tasks.push(fetchTokenList(chainList));
    const results = await Promise.all(tasks);
    const tokenInfo = merge(results[0] as Native, results[1] as Token);

    setTokensInfo(tokenInfo);
    return tokenInfo;
  }

  useEffect(() => {
    if (!address) {
      return;
    }
    excuteGetTokenInfos();
  }, [reload, address]);

  useEffect(() => {
    function getDefaultTokenFromConfig() {
      return chainList.reduce((result, chain) => {
        const { key } = chain;
        result[key] = {
          chain,
          tokens: Array.from(defaultTokenList[key]) || [],
        };
        return result;
      }, {} as DefaultTokensByChain);
    }

    async function getDefaultToken() {
      //TODO
      // phase2 call API
    }
    async function excute() {
      const tokens = getDefaultTokenFromConfig();
      setDefaultTokens(tokens);
    }

    if (!address) {
      return;
    }
    excute();
  }, [reload, address]);

  return { defaultTokens, nativeTokens, tokensInfo, onReload, onExcute: excuteGetTokenInfos };
}
