'use client';
import React, { useEffect, useState } from 'react';
import { BlockChart } from '../common';
import { Text, Flex, Box, flexbox, Tooltip, Icon } from '@chakra-ui/react';
import { formatNumber, separator } from '@/utils/format';
import { ChartBar } from '.';
import { useGetTokenBalances } from '../hooks/useGetTokenBalances';
import { useDashBoard } from '../contexts/DashBoardContext';
import Loading from './Loading';
import { MoralisTokenEntity, Status } from '../interfaces/types';
import { supportChains } from '../configs';
import {
  UseGetTokenBalancesOut,
  UseGetTokenBalancesOutByDates,
  useGetTokenBalancesN,
} from '../hooks/useGetTokenBalancesN';
import moment from 'moment';
import { formatCurrency, getLastDayOfMonthBeforeToday, getNumber } from '../utils/helper';
import { mockNewWorth } from '@/mocks/mockData';
import { useGetPnlData } from '../hooks/useGetPnlData';
import { InfoOutlineIcon } from '@chakra-ui/icons';

export type ChartNetworthBarChartParams = {
  status: Status;
  tokenBalancesUsdByDates?: UseGetTokenBalancesOutByDates;
  isDemo?: boolean;
};
// eslint-disable-next-line complexity
function ChartNetworthBarChart({
  status = 'loading',
  tokenBalancesUsdByDates,
  isDemo = false,
}: ChartNetworthBarChartParams) {
  const isPnl = process.env.NEXT_PUBLIC_ISPNL;
  const { globalStatus, walletAddress } = useDashBoard();
  const { data, status: pnlStatus } = useGetPnlData({ address: walletAddress });
  // const [months, setMonths] = useState<number>(3);
  // const data = {
  //   total_month: 6,
  //   pnl: [
  //     {
  //       date: '2023-08',
  //       value: 1000200,
  //       label: 'balance',
  //     },
  //     {
  //       date: '2023-07',
  //       value: 1000200,
  //       label: 'balance',
  //     },
  //     {
  //       date: '2023-06',
  //       value: 910200,
  //       label: 'balance',
  //     },
  //     {
  //       date: '2023-05',
  //       value: 400200,
  //       label: 'balance',
  //     },
  //     {
  //       date: '2023-04',
  //       value: 500200,
  //       label: 'balance',
  //     },
  //     {
  //       date: '2023-03',
  //       value: 900200,
  //       label: 'balance',
  //     },
  //   ],
  // };

  // const { walletAddress } = useDashBoard();
  // const { status, balanceSumGroupByDates, ...res } = useGetTokenBalances({ address: walletAddress, months: months });
  // const { status, tokenBalancesUsdByDates } = useGetTokenBalancesN({
  //   address: walletAddress,
  //   months,
  //   preStatus: outStatus,
  // });
  // const { status, tokenBalancesUsdByDates } = { status: 'success', tokenBalancesUsdByDates: [] };
  const _status = isPnl ? pnlStatus : status;
  const isLoading = globalStatus === 'loading' || _status === 'loading' || _status === 'idle';

  const monthsCount = data?.total_month;
  // tokenBalancesUsdByDates &&
  // Array.from(tokenBalancesUsdByDates.keys()).length &&
  // Array.from(tokenBalancesUsdByDates.keys()).length !== 0
  //   ? Array.from(tokenBalancesUsdByDates.keys()).length
  //   : undefined;

  const defaultDates = getLastDayOfMonthBeforeToday(12).map((date) => moment(date).format('YYYY-MM'));

  const pnlDatas = data ? data.pnl.map((pnl) => pnl.date) : defaultDates;
  const netWrothDatas =
    tokenBalancesUsdByDates && Array.from(tokenBalancesUsdByDates.keys()).length >= 12
      ? Array.from(tokenBalancesUsdByDates.keys()).map((date) => moment(date).format('YYYY-MM'))
      : defaultDates;
  const dates: string[] = isPnl === 'true' ? pnlDatas : netWrothDatas;

  const balances = isDemo
    ? mockNewWorth.balances
    : tokenBalancesUsdByDates
    ? Array.from(tokenBalancesUsdByDates.values())
    : [{ entity: [], totalBalanceUsd: 0 }];

  // Array.from(balanceSumGroupByDates.values())
  // const {detas,balances} = chartNetworthBarChart

  const pnlBalances = data?.pnl.map((pnl) => pnl.value);
  const netWorthBalances = balances.map((balance) => balance?.totalBalanceUsd) ?? 0;
  const balancesUsd = isPnl === 'true' ? pnlBalances : netWorthBalances;

  const [balance, setBalance] = useState('--');
  // eslint-disable-next-line arrow-spacing

  useEffect(() => {
    // console.log('=============', status, balancesUsd);
    if (!balancesUsd || balancesUsd.length === 0) {
      return;
    }
    if (isLoading) {
      return;
    }
    const _balance = isPnl === 'true' ? data?.total_amount : balancesUsd[0];
    setBalance(getNumber(_balance).toFixed(2));
  }, [isLoading]);
  const getClickElement = (element: any) => {
    if (isPnl === 'true') {
      return;
    }
    if (!element) {
      return;
    }
    // console.log('element', element);
    // setBalance(separator(element));
    setBalance(element);
  };
  if (isLoading && !isDemo) {
    return (
      <BlockChart>
        <div className="relative flex items-center justify-center min-h-[200px] md:h-full">
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <Loading />
          </div>
        </div>
      </BlockChart>
    );
  }
  return (
    <BlockChart>
      <div className="flex flex-col">
        <Text mb={1} fontSize="32px" fontWeight={500}>
          ${isLoading && !isDemo ? `  --` : `${formatCurrency(isDemo ? 4568.35 : balance === '--' ? '0' : balance)}`}
        </Text>
        {isPnl === 'true' ? (
          <Tooltip
            hasArrow
            placement="bottom-start"
            label="Profit & Loss within the last 6 months"
            borderRadius="10px"
            bg="rgba(255, 255, 255, 0.8)"
            color="gray.900"
            fontSize="12px"
            maxWidth="150px"
            padding="8px"
            textAlign="center"
            transition="opacity 0.5s ease-in-out"
          >
            <div className="flex items-center pb-2">
              <Text fontSize="md" fontWeight={400}>
                P&L
              </Text>
              <Icon as={InfoOutlineIcon} mx={1} color="#9ba2a9" fontSize="xs" />
              {isDemo ? (
                <span className="text-sm text-[#959595]"> (6m)</span>
              ) : (
                <>
                  <span className="text-sm text-[#959595]">{monthsCount && `(${monthsCount}m)`}</span>
                </>
              )}
            </div>
          </Tooltip>
        ) : (
          <Text mb={4} fontSize="md" fontWeight={400}>
            Net Worth
            {isDemo ? (
              <span className="text-sm text-[#959595]"> (6m)</span>
            ) : (
              <>
                <span className="text-sm text-[#959595]">{monthsCount && `(${monthsCount}m)`}</span>
              </>
            )}
          </Text>
        )}

        {isLoading && !isDemo ? (
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <Loading />
          </div>
        ) : (
          <Box w="100%" h="100%" alignSelf="end">
            <ChartBar getClickElement={getClickElement} dates={dates} balances={balancesUsd} />
          </Box>
        )}
      </div>
    </BlockChart>
  );
}

export default ChartNetworthBarChart;
