import { EvmChain } from 'moralis/common-evm-utils';
import { CHAIN, Chain } from './chain';

//#region  blacklist
//Phishing scam
const bnbTokensFilter = [
  '0x6d4cc9dc49c8450853dec381c65f30a48aa52aa0',
  '0x949e0a0672299e6fcd6bec3bd1735d6647b20618',
  '0x2248ba304d2045cdc144866ce37d1435a30b29f3',
  '0xa02a0b2d67d4fa48677a79cadc483e114049916d',
  '0x7e6202903275772044198d07b8a536cc064f8480',
  '0x5f7a1a4dafd0718caee1184caa4862543f75edb1',
  '0x7aa3a53360541283ffa9192972223b47a902dc0c',
  '0xe3e1147acd39687a25ca7716227c604500f5c31a',
  '0x471afdbc819f7114ffc2cf806f6562072edfd978',
  '0xe4dc9c80b81e7fb385b1674b38c030936c3982d4',
  '0x6b928e0ed8a2f2bf40e7c557b52a3cf7bccca064',
];

//#endregion

//#region Stable Coin
const usdt = {
  id: 'usdt_bsc',
  chain: EvmChain.BSC,
  address: '0x55d398326f99059ff775485246999027b3197955',
};
const busd = {
  id: 'busd_bsc',
  chain: EvmChain.BSC,
  address: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
};

const STABLE_BSC: string[] = [
  '0x55d398326f99059ff775485246999027b3197955',
  '0xe9e7cea3dedca5984780bafc599bd69add087d56',
  '0x40af3827F39D0EAcBF4A168f8D4ee67c121D11c9',
  '0x90c97f71e18723b0cf0dfa30ee176ab653e89f40',
  '0xd17479997F34dd9156Deef8F95A52D81D265be9c',
  '0xb3c11196a4f3b1da7c23d9fb0a3dde9c6340934f',
  '0xe48a3d7d0bc88d552f730b62c006bc925eadb9ee',
  '0xe80772eaf6e2e18b651f160bc9158b2a5cafca65',
  '0xC1fdbed7Dac39caE2CcC0748f7a80dC446F6a594',
  '0x12f31b73d812c6bb0d735a218c086d44d5fe5f89',
  '0x97B6897AAd7aBa3861c04C0e6388Fc02AF1F227f',
  '0x1d6Cbdc6b29C6afBae65444a1f65bA9252b8CA83',
  '0xFa4BA88Cf97e282c505BEa095297786c16070129',
  '0x85daB10c3BA20148cA60C2eb955e1F8ffE9eAa79',
  '0xff54da7caf3bc3d34664891fc8f3c9b6dea6c7a5',
  '0x687cb243265b127fef45742f8d17d8a1be5539f0',
  '0x6F13b1Fb6B2897bb40AdBc09f7F6cfAd181C0904',
  '0xb8fe0e72f69cf8f89a243277b189b489b6453b1b',
  '0x23b8683Ff98F9E4781552DFE6f12Aa32814924e8',
  '0x049d68029688eabf473097a2fc38ef61633a3c7a',
  '0xc4b6f32b84657e9f6a73fe119f0967be5ba8cf05',
  '0xbb0fa2fbe9b37444f5d1dbd22e0e5bdd2afbbe85',
  '0x29c02150B09F55B3A0df37BB7A91549aCbF04Ba9',
  '0xcbB00E1f27a59735f390F3263d335A112f10Db8b',
  '0xFB5619043Ce84Ae1020ecD3132BCe448565894EE',
  '0x5801d0e1c7d977d78e4890880b8e579eb4943276',
  '0x598308047116a8055c1e3debd2b761e3bc3dbcb8',
  '0x3cad3835778159ce2492c7d590c399afa4313247',
  '0xE68b79e51bf826534Ff37AA9CeE71a3842ee9c70',
  '0x71be881e9c5d4465b3fff61e89c6f3651e69b5bb',
  '0x47b19Af93d0bC33805269Af02B5CA953Aa145127',
  '0xDEa12C8C23994EA2D88eD99eE1bdc0FF56f7F9d1',
  '0xdb5ee2a3d48f17a81bdcf513d6988ed430bbe7ab',
  '0x18e0662b2da216bc06aa3abcd5ceae88f372198b',
  '0x9a8284c2cec196478f01ef4446a0222dd4def00e',
  '0x316622977073BBC3dF32E7d2A9B3c77596a0a603',
  '0x883da316c7971b19bfe25df9ace93b9529c50f22',
  '0xA5286319ABbc22cEB755afBD81DF55c1328dAdd1',
  '0x151F5d74823Bd2DBD7e574706474a291Db8CAF95',
  '0x6B8b9AE0627a7622c593A1696859ca753c61A670',
  '0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b',
  '0xe0eb6ff76ebdeb2278d18306a7493ab9be1aa476',
  '0x4ce8a9DD76af01fe95C6F182a8305f3890132356',
  '0x7c869b5A294b1314E985283d01C702B62224a05f',
  '0x303de4bdb189b951f875eb4a8ecde2985138161e',
  '0xfdf8fe2d37e12149b1c7ca1b54e29325f4e9942d',
  '0x048E9b1ddF9EBbb224812372280e94Ccac443f9e',
  '0xcd6970d5211efdf2516a4fc73163db7ba812b212',
];

const STABLE_ETHEREUM: string[] = [
  '0x0f72714b35a366285df85886a2ee174601292a17',
  '0x1a7e4e63778b4f12a199c062f3efdd288afcbce8',
  '0x5F27184fA83fE0E5aEf2344B6D9eB3Bd2118A290',
  '0x1d48257386165d44e303c579386c46089b2775f5',
  '0xbc6da0fe9ad5f3b0d58160288917aa56653660e9',
  '0x5456bc77dd275c45c3c15f0cf936b763cf57c3b5',
  '0x5a7E6C8204A1359DB9AAcab7bA5Fc309B7981eFd',
  '0x29917c3b1c2b4779a7b61f08d98b3da0fcf3b6a4',
  '0x735fa792e731a2e8F83F32eb539841b7B72e6d8f',
  '0xa4335da338ec4C07C391Fc1A9bF75F306adadc08',
  '0xd7e0f80fb28233bdde0006c50568606a8feb964c',
  '0xbea0000029ad1c77d3d5d23ba2d8893db9d1efab',
  '0xc9a2c4868f0f96faaa739b59934dc9cb304112ec',
  '0x2c537e5624e4af88a7ae4060c022609376c8d0eb',
  '0x4fabb145d64652a948d72533023f6e7a623c7c53',
  '0x3c513db8bdc3806e4489d62c3d549a5aaf6a4e97',
  '0xb5bae50c084a2f99c8f990463c5eff12957a81d7',
  '0xB0B195aEFA3650A6908f15CdaC7D92F8a5791B0B',
  '0x9a1997c130f4b2997166975d9aff92797d5134c2',
  '0x679fa6dc913acab6def33ec469fc6e421bc794f5',
  '0x84e1c8823850d8adc3286f31bc1178f14d243168',
  '0xe1c67165ce0cff65e87770df17d8cc5631d99c37',
  '0xC22956c3CFeC3Ee9A9925abeE044F05Bc47f6632',
  '0xee586e7eaad39207f0549bc65f19e336942c992f',
  '0x082cA54e4Fc3d8aF022cDAb257122bfcfCdE4B6f',
  '0x7f2d8dc79b7712ec6549fb975a70d1c306a00187',
  '0xfc2189D367d8d3D7CCA1aF43ff6169197DC7351e',
  '0x9293c7b4b4fb90fb3ee76f7c6189aa841e57e5c0',
  '0xb7a84c8e97bc7c5d71147447cff0d598bf9672a2',
  '0xC285B7E09A4584D027E5BC36571785B515898246',
  '0xf5b574aaf22f8bbad4a8f544c6599482f88e8231',
  '0xA6FA6531acDf1f9F96EDdD66a0F9481E35c2e42A',
  '0xb4272071ecadd69d933adcd19ca99fe80664fc08',
  '0x0a4b2d4b48a63088e0897a3f147ba37f81a27722',
  '0xfdcdfa378818ac358739621ddfa8582e6ac1adcb',
  '0x9fC689CCaDa600B6DF723D9E47D84d76664a1F23',
  '0x6c3F90f043a72FA612cbac8115EE7e52BDe6E490',
  '0xdf5e0e81dff6faf3a7e52ba697820c5e32d806a8',
  '0xf7bcc16f5ccf1c7fa195c22dda8a88329e528649',
  '0x51be9f12DD5095C5B1ACF90E7e0aA4Aa8023218b',
  '0x155Ab266B9226525Cfd5b1e7D8a80BAb65b6B609',
  '0xD71BBf61079d3eA7ea7890356850D4579BE304aF',
  '0x6b175474e89094c44da98b954eedeac495271d0f',
  '0x739ca6d71365a08f584c8fc4e1029045fa8abc4b',
  '0xa48f322f8b3edff967629af79e027628b9dd1298',
  '0xd3dd721b74d72f29677a94c4b318f16a7f7426bc',
  '0x0C10bF8FcB7Bf5412187A595ab97a3609160b5c6',
  '0x5bc25f649fc4e26069ddf4cf4010f9f706c23831',
  '0x20c36f062a31865bED8a5B1e512D9a1A20AA333A',
  '0xeb269732ab75a6fd61ea60b06fe994cd32a83549',
  '0x9Cb2f26A23b8d89973F08c957C4d7cdf75CD341c',
  '0x4f3afec4e5a3f2a6a1a411def7d7dfe50ee057bf',
  '0x30365ed4ca8173013ad948b9842f34ac71d01f7c',
  '0xb9e6bdcba653b5f768dd2e2faf6288df9054e554',
  '0xcd8e8ec834c9127a18d8cd5c749a7f2a57355c9a',
  '0x60db53521c1563C89E55D9FcEDa8D47F55c14d0e',
  '0x0BaDD7aF129EFAc1f7bB7253716A589cc5E6c17c',
  '0x9EC749faa086BB604B96bfE02Bd49c53CEF47Ea5',
  '0x0f7f6531aF9792c9A42e2BEfCeD5ED81c0D63A23',
  '0x648E5c72316bb75a0a7171354c18F341Aae150b8',
  '0x6f0f28ceee6ae686ee0f939375674c01b156365a',
  '0x147e3d644d53adadd18a28201b91e2b98aeb7f94',
  '0xe94d535b6d19666bad9b39b1b253edcdae97ea4c',
  '0xb97fca5a2967ed91f36c1cbb60dbfe4c53c1df44',
  '0xdf1e9e1a218cff9888faef311d6fbb472e4175ce',
  '0x05ac103f68e05da35e78f6165b9082432fe64b58',
  '0x6871799a4866bb9068b36b7a9bb93475ac77ac5d',
  '0xf85ef57fcdb36d628d063fa663e61e44d35ae661',
  '0x86fadb80d8d2cff3c3680819e4da99c10232ba0f',
  '0xfa99e789aa0164a7eac475f3eda666be1d0669f2',
  '0x4389710ba2d358cc984bb70cdb5c0e9d48727874',
  '0x0b66833a52e9ef8b006af25fbb3fc3231ebb4c7f',
  '0x1aBaEA1f7C830bD89Acc67eC4af516284b1bC33c',
  '0xb1abd7aba7d99bbefb33d1dfc66b0dd522335350',
  '0xC581b735A1688071A1746c968e0798D642EDE491',
  '0x7a0e91c4204355e0a6bbf746dc0b7e32dfefdecf',
  '0x820802fa8a99901f52e39acd21177b0be6ee2974',
  '0x0bead9a1bcc1b84d06e3f2df67e3549fd55ab054',
  '0x54d2c48ad859b81d8a9ddc8b65dbe96327291bf5',
  '0x9baf5147505b980b2674aab556a51e03b3082efe',
  '0x956F47F50A910163D8BF957Cf5846D573E7f87CA',
  '0x617F7C416ceC4361246fac916feeB187d0C3e970',
  '0xb05097849bca421a3f51b249ba6cca4af4b97cb9',
  '0x853d955acef822db058eb8505911ed77f175b99e',
  '0x3432b6a60d23ca0dfca7761b7ab56459d9c964d0',
  '0x1655f5e479341Abe17a9E89A20C3C3594C8C02fE',
  '0x24d61a6988b710bdf4bf5220573c60576374dffb',
  '0x056fd409e1d7a124bd7017459dfea2f387b6d5cd',
  '0x2743Bb6962fb1D7d13C056476F3Bc331D7C3E112',
  '0x40D16FC0246aD3160Ccc09B8D0D3A2cD28aE6C2f',
  '0x713b30c4ea50830b1c3b5198ee3626dee2203342',
  '0xC08512927D12348F6620a698105e1BAac6EcD911',
  '0xF3DA4C6cB0E9e800428B9cA0F8342ac3B9eEAb21',
  '0xb692A99a298ef9f4e8103B06fB3A93Cdaa7Dea72',
  '0x171f9cfc136f2b2aaa148fcc6b660a2029bab048',
  '0xeAb43193CF0623073Ca89DB9B712796356FA7414',
  '0xdc732972811d25de61808b3d6e0d2ac44a7f036f',
  '0x9De152C32d820EB2E1Df392854d4dF2f4F305530',
  '0x6a4b6316D1d03d2f2B3A0294502F8fAF0F38cA14',
  '0x011c5c4e4a86fc95a7a6d5c49a69cdf0cb1d0467',
];
const STABLE_FANTOM: string[] = [
  '0x543acd673960041eee1305500893260f1887b679',
  '0x0f7adb77b6334b9b5a3c4f78aaa073ea1d915bf6',
  '0x0def844ed26409c5c46dda124ec28fb064d90d27',
  '0xe3a486c1903ea794eed5d5fa0c9473c7d7708f40',
  '0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e',
  '0xde1e704dae0b4051e80dabb26ab6ad6c12262da0',
  '0x3129662808bec728a27ab6a6b9afd3cbaca8a43c',
  '0x18f7f88be24a1d1d0a4e61b6ebf564225398adb0',
  '0x049d68029688eabf473097a2fc38ef61633a3c7a',
  '0xdc301622e621166bd8e82f2ca0a26c13ad0be355',
  '0x7d016eec9c25232b01f23ef992d98ca97fc2af5a',
  '0x82f8cb20c14f134fe6ebf7ac3b903b2117aafa62',
  '0x7dd7eaf5872e260f35dc60a54e0f25193128c765',
  '0x696c1cc6bd689b764be36eecb019c2d9bea761ca',
  '0x66b5666b2b9ad101fc6d3784f995bbf461970ede',
  '0x5f0456f728e2d59028b4f5b8ad8c604100724c6a',
  '0x57976c467608983513c9355238dc6de1b1abbcca',
  '0xc5e7a99a20941cbf56e0d4de608332cdb792e23e',
  '0xb9d62c829fbf7eaff1eba4e50f3d0480b66c1748',
  '0x1d3918043d22de2d799a4d80f72efd50db90b5af',
  '0xeff11197247ac09e0239ec9f83f7de13c186caa6',
  '0xa71353bb71dda105d383b02fc2dd172c4d39ef8b',
  '0x8a41f13a4fae75ca88b1ee726ee9d52b148b0498',
  '0x2b7c5841997275d2d548ce9b19d54b655725ebfc',
  '0x584b521912d5f8cfddcd84083b475daf92fe2865',
  '0x0e1694483ebb3b74d3054e383840c6cf011e518e',
  '0x6c021ae822bea943b2e66552bde1d2696a53fbb7',
  '0x74e23df9110aa9ea0b6ff2faee01e740ca1c642e',
  '0x6fc9383486c163fa48becdec79d6058f984f62ca',
  '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
  '0x4a89338a2079a01edbf5027330eac10b615024e5',
  '0x953e94caf91a1e32337d0548b9274f337920edfa',
  '0xc5cd01e988cd0794e05ab80f2bcdbdf13ce08bd3',
  '0x2f085edec7113e54a1747bd9572b213e662c6165',
];
const STABLE_AVALANCHE: string[] = [];
const STABLE_POLYGON: string[] = [];
const STABLE_ARBITRUM: string[] = [];

const stableCoinList = [usdt, busd];

const stableCoinListBySymbol = [
  'usdt',
  'usdc',
  'bsc-usd',
  'busd',
  'tusd',
  'frax',
  'usdd',
  'usdp',
  'fxs',
  'usd+',
  'tryb',
  'ageur',
  'angle',
  'tor',
  'cusd',
  'arth.bsc',
  'dolly',
  'wbrl',
  'geuro',
  'pxo',
  'jeur',
  'fusdt',
  'gwusdn',
  'usdm',
  'drt',
  'euri',
  'tdc',
  'usdo',
  'kusd',
  'zusd',
  'czusd',
  'brz',
  'jmxn',
  'l3usd',
  'mhappy',
  'zarc',
  'iddr',
  'jbrl',
  'cgt',
  'nupen',
  'usc',
  'jzar',
  'bob',
  'cnq',
  'usdy',
  'jchf',
  'usds',
  'bgold',
  'jgbp',
  'inrx',
];
//#endregion

//#region whitelist
const bnbTokensWhiteList: string[] = [...stableCoinList.map((token) => token.address)];
//#endregion

const TOKENS_BSC_ARRAY: Array<string> = [
  '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
  '0x55d398326f99059ff775485246999027b3197955',
  '0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe',
  '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
  '0x8965349fb649a33a30cbfda057d8ec2c48abe2a2',
  '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
  '0xba2ae424d960c26247dd6c32edc70b295c744c43',
  '0xcc42724c6683b7e57334c4e856f4c9965ed682bd',
  '0xce7de646e7208a4ef112cb6ed5038fa6cc6b12e3',
  '0x4338665cbb7b2485a8855a139b75d5e34ab0db94',
  '0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
  '0x1ce0c2827e2ef14d5c4f29a091d735a204794041',
  '0x8ff795a6f4d97e7887c79bea79aba5cc76444adf',
  '0x2859e4544c4bb03966803b044a93563bd2d0dd4d',
  '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
  '0xe9e7cea3dedca5984780bafc599bd69add087d56',
  '0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd',
  '0xbf5140a22578168fd562dccf235e5d43a02ce9b1',
  '0x0eb3a705fc54725037cc9e008bdede697f62f335',
  '0x40af3827f39d0eacbf4a168f8d4ee67c121d11c9',
  '0x3d6545b08693dae087e957cb1180ee38b9e3c25e',
  '0x8595f9da7b868b1822194faed312235e43007b49',
  '0x76a797a59ba2c17726896976b7b3747bfd1d220f',
  '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
  '0x1fa4a73a3f0133f0025378af00236f3abdee5d63',
  '0x29ced01c447166958605519f10dcf8b0255fb379',
  '0x90c97f71e18723b0cf0dfa30ee176ab653e89f40',
  '0xbf7c81fff98bbe61b40ed186e4afd6ddd01337fe',
  '0x5f0da599bb2cccfcf6fdfd7d81743b6020864350',
  '0xb7f8cd00c5a06c0537e2abff0b58033d02e5e094',
  '0x56b6fb708fc5732dec1afc8d8556423a2edccbd6',
  '0x16939ef78684453bfdfb47825f8a5f714f12623a',
  '0xad29abb318791d579433d831ed122afeaf29dcfe',
  '0x715d400f88c167884bbcc41c5fea407ed4d2f8a0',
  '0xd17479997f34dd9156deef8f95a52d81d265be9c',
  '0x9ac983826058b8a9c7aa1c9171441191232e8404',
  '0xc943c5320b9c18c153d1e2d12cc3074bebfb31a2',
  '0x0ef2e7602add1733bfdb17ac3094d0421b502ca3',
  '0x045c4324039da91c52c55df5d785385aab073dcf',
  '0x52ce071bd9b1c4b00a0b92d298c512478cad67e8',
  '0xb3c11196a4f3b1da7c23d9fb0a3dde9c6340934f',
  '0x111111111117dc0aa78b770fa6a738034120c302',
  '0xd944f1d1e9d5f9bb90b62f9d45e447d989580782',
  '0x1ba42e5193dfa8b03d15dd1b86a3113bbbef8eeb',
  '0x7950865a9140cb519342433146ed5b40c6f210f7',
  '0xe48a3d7d0bc88d552f730b62c006bc925eadb9ee',
  '0x64048a7eecf3a2f1ba9e144aac3d7db6e58f555e',
  '0x352cb5e19b12fc216548a2677bd0fce83bae434b',
  '0x4691937a7508860f876c9c0a2a617e7d9e945d4b',
  '0xb86abcb37c3a4b64f74f59301aff131a1becc787',
  '0x4b0f1812e5df2a09796481ff14017e6005508003',
  '0x101d82428437127bf1608f699cd651e6abf9766e',
  '0x3cd55356433c89e50dc51ab07ee0fa0a95623d53',
  '0x3019bf2a2ef8040c242c9a4c5c4bd4c81678b2a1',
  '0xf307910a4c7bbc79691fd374889b36d8531b08e3',
  '0xfb5b838b6cfeedc2873ab27866079ac55363d37e',
  '0x361c60b7c2828fcab80988d00d1d542c83387b50',
  '0x88f1a5ae2a3bf98aeaf342d26b30a79438c9142e',
  '0x4d5ac5cc4f8abdf2ec2cb986c00c382369f787d4',
  '0xd41fdb03ba84762dd66a0af1a6c8540ff1ba5dfb',
  '0x47bead2563dcbf3bf2c9407fea4dc236faba485a',
  '0xea998d307aca04d4f0a3b3036aba84ae2e409c0a',
  '0x302cd8973be5ca2334b4ff7e7b01ba41455559b3',
  '0xc748673057861a797275cd8a068abb95a902e8de',
  '0x031b41e504677879370e9dbcf937283a8691fa7f',
  '0x9678e42cebeb63f23197d726b29b1cb20d0064e5',
  '0x23396cf899ca06c4472205fc903bdb4de249d6fc',
  '0xfd7b3a77848f1c2d67e05e54d78d174a0c850335',
  '0x882c173bc7ff3b7786ca16dfed3dfffb9ee7847b',
  '0xa3f020a5c92e15be13caf0ee5c95cf79585eecc9',
  '0xad6caeb32cd2c308980a548bd0bc5aa4306c6c18',
  '0x4d2d32d8652058bf98c772953e1df5c5c85d9f45',
  '0x812764fa37a4fd731a5d39f0e519b6b8d61e82a0',
  '0xb0d502e938ed5f4df2e681fe6e419ff29631d62b',
  '0x371c7ec6d8039ff7933a2aa28eb827ffe1f52f07',
  '0x8b1f4432f943c465a973fedc6d7aa50fc96f1f65',
  '0x8da443f84fea710266c8eb6bc34b71702d033ef2',
  '0xfe56d5892bdffc7bf58f2e84be1b2c32d21c308b',
  '0xa4080f1778e69467e905b8d6f72f6e441f9e9484',
  '0xeca88125a5adbe82614ffc12d0db554e2e2867c8',
  '0x8fff93e810a2edaafc326edee51071da9d398e83',
  '0x9b17baadf0f21f03e35249e0e59723f34994f806',
  '0x42981d0bfbaf196529376ee702f2a9eb9092fcb5',
  '0xbc7d6b50616989655afd682fb42743507003056d',
  '0x20ee7b720f4e4c4ffcb00c4065cdae55271aecca',
  '0x1fc9004ec7e5722891f5f38bae7678efcb11d34d',
  '0x1f9f6a696c6fd109cd3956f45dc709d2b3902163',
  '0xaf53d56ff99f1322515e54fdde93ff8b3b7dafd5',
  '0xebd49b26169e1b52c04cfd19fcf289405df55f80',
  '0xa1faa113cbe53436df28ff0aee54275c13b40975',
  '0xaec945e04baf28b135fa7c640f624f8d90f1c3a6',
  '0x039cb485212f996a9dbb85a9a75d898f94d38da6',
  '0xf7de7e8a6bd59ed41a4b5fe50278b3b7f31384df',
  '0x5f588efaf8eb57e3837486e834fc5a4e07768d98',
  '0x2dff88a56767223a5529ea5960da7a3f5f766406',
  '0x42f6f551ae042cbe50c739158b4f0cac0edb9096',
  '0xac51066d7bec65dc4589368da368b212745d63e8',
  '0x762539b45a1dcce3d36d080f74d1aed37844b878',
  '0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63',
];
const TOKENS_ETHEREUM_ARRAY: Array<string> = [
  '0x48c3399719b582dd63eb5aadf12a40b4c3f52fa2',
  '0xa1116930326d21fb917d5a27f1e9943a9595fb47',

  '0xdac17f958d2ee523a2206206994597c13d831ec7',
  '0xb8c77482e45f1f44de1745f52c74426c631bdd52',
  '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
  '0xae7ab96520de3a18e5e111b5eaab095312d7fe84',
  '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0',
  '0x50327c6c5a14dcade707abad2e27eb517df87ab5',
  '0x3883f5e181fccaf8410fa61e12b59bad963fb645',
  '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
  '0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce',
  '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
  '0x6b175474e89094c44da98b954eedeac495271d0f',
  '0x4fabb145d64652a948d72533023f6e7a623c7c53',
  '0x2af5d2ad76741191d15dfe7bf6ac92d4bd912ca3',
  '0x514910771af9ca656af840dff83e8264ecf986ca',
  '0x0000000000085d4780b73119b644ae5ecd22b376',
  '0x75231f58b43240c9718dd58b4967c5114342a86c',
  '0x582d872a1b094fc48f5de31d3b73f2d9be47def1',
  '0x5a98fcbea516cf06857215779fd812ca3bef1b32',
  '0x2b591e99afe9f32eaa6214f7b7629768c40eeb39',
  '0xb50721bcf8d664c30412cfbc6cf7a15145234ad1',
  '0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b',
  '0x4a220e6096b25eadb88358cb44068a3248254675',
  '0xd850942ef8811f2a866692a623011bde52a462c1',
  '0x85f17cf997934a597031b2e18a9ab6ebd4b9f6a4',
  '0xba3d9687cf50fe253cd2e1cfeede1d6787344ed5',
  '0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9',
  '0x4da27a545c0c5b758a6ba100e3a049001de870f5',
  '0xc944e90c64b2c07662a292be6244bdf05cda44a7',
  '0x853d955acef822db058eb8505911ed77f175b99e',
  '0xae78736cd615f374d3085123a210448e74fc6393',
  '0x3845badade8e6dff049820680d1f14bd3903a5d0',
  '0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2',
  '0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f',
  '0xf57e7e7c23978c3caec3c3548e3d615c346e79ff',
  '0x1a4b46696b2bb4794eb3d4c26f1c55f9170fa4c5',
  '0x0f5d2fb29fb7d3cfee444a200298f468908cc942',
  '0xfd09cf7cfffa9932e33668311c4777cb9db3c9be',
  '0x4e15361fd6b4bb609fa63c81a2be19d873717870',
  '0x4d224452801aced8b2f0aebe155379bb5d594381',
  '0x0c10bf8fcb7bf5412187a595ab97a3609160b5c6',
  '0x6de037ef9ad2725eb40118bb1702ebb27e4aeb24',
  '0xe28b3b32b6c345a34ff64674606124dd5aceca30',
  '0xd33526068d116ce69f19a9ee46f0bd304f21a51f',
  '0x6982508145454ce325ddbe47a25d4ec3d2311933',
  '0xf34960d9d60be18cc1d5afc1a6f012a723a28811',
  '0x3506424f91fd33084466f402d5d97f05f8e3b4af',
  '0x056fd409e1d7a124bd7017459dfea2f387b6d5cd',
  // '0x8e870d67f660d95d5be530380d0ec0bd388289e1',
  '0x111111111117dc0aa78b770fa6a738034120c302',
  '0x4ddc2d193948926d02f9b1fe9e1daa0718270ed5',
  '0xc00e94cb662c3520282e6f5717214004a7f26888',
  '0x45804880de22913dafe09f4980848ece6ecbaf78',
  // '0x68749665ff8d2d112fa859aa293f07a622782f38',
  '0x3432b6a60d23ca0dfca7761b7ab56459d9c964d0',
  '0x5e8422345238f34275888049021821e8e08caa1f',
  '0xc669928185dbce49d2230cc9b0979be6dc797957',
  '0x6f259637dcd74c767781e37bc6133cd6a68aa161',
  '0x39aa39c021dfbae8fac545936693ac917d5e7563',
  '0x4691937a7508860f876c9c0a2a617e7d9e945d4b',
  '0x92d6c1e31e14520e676a687f0a93788b716beff5',
  '0xd7c49cee7e9188cca6ad8ff264c1da2e69d4cf3b',
  '0x05f4a42e251f2d52b8ed15e9fedaacfcef1fad27',
  '0xb62132e35a6c13ee1ee0f84dc5d40bad8d815206',
  '0x152649ea73beab28c5b49b26eb48f7ead6d4c898',
  '0x0d8775f648430679a709e98d2b0cb6250d2887ef',
  '0xf629cbd94d3791c9250152bd8dfbdf380e2a3b9c',
  '0x4e3fbd56cd56c3e72c1403e103b45db9da5b9d2b',
  '0x69af81e73a73b40adf4f3d4223cd9b1ece623074',
  '0x6810e776880c02933d47db1b9fc05908e5386b96',
  '0x5b7533812759b45c2b44c19e320ba2cd2681b542',
  '0xac3e018457b222d93114458476f3e3416abbe38f',
  '0xbbbbca6a901c926f240b89eacb641d8aec7aeafd',
  '0x3103df8f05c4d8af16fd22ae63e406b97fec6938',
  '0xe452e6ea2ddeb012e20db73bf5d3863a3ac8d77a',
  '0xe3c408bd53c31c085a1746af401a4042954ff740',
  '0x11eef04c884e24d9b7b4760e7476d06ddf797f36',
  '0x0316eb71485b0ab14103307bf65a021042c6d380',
  '0x5283d291dbcf85356a21ba090e6db59121208b44',
  '0x5d3a536e4d6dbd6114cc1ead35777bab948e3643',
  '0xc18360217d8f7ab5e7c516566761ea12ce7f9d72',
  '0xaea46a60368a7bd060eec7df8cba43b7ef41ad85',
  '0xb63b606ac810a52cca15e44bb630fd42d8d1d83d',
  '0xcf0c122c6b73ff809c693db761e7baebe62b6a2e',
  '0x8fc8f8269ebca376d046ce292dc7eac40c8d358a',
  '0x6c6ee5e31d828de241282b9606c8e98ea48526e2',
  '0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e',
  '0xc581b735a1688071a1746c968e0798d642ede491',
  '0x767fe9edc9e0df98e07454847909b5e959d7ca0e',
  '0x8ce9137d39326ad0cd6491fb5cc0cba0e089b6a9',
  '0x7dd9c5cba05e151c895fde1cf355c9a1d5da6429',
  '0xba100000625a3754423978a60c9317c58a424e3d',
  '0xe41d2489571d322189246dafa5ebde1f4699f498',
  '0x64aa3364f17a4d01c6f1751fd97c2bd3d7e7f1d5',
  '0x6fb3e0a217407efff7ca062d46c26e5d60a14d69',
  '0xa117000000f279d81a1d3cc75430faa017fa5a2e',
  '0x967da4048cd07ab37855c090aaf366e4ce1b9f48',
  '0xfa1a856cfa3409cfa145fa4e20eb270df3eb21ab',
  '0x39bb259f66e1c59d5abef88375979b4d20d98022',
  '0x799a4202c12ca952cb311598a024c80ed371a41e',
  '0xf650c3d88d12db855b8bf7d11be6c55a4e07dcc9',
];
// const TOKENS_ETHEREUM: string[] = [
//   '0x48c3399719b582dd63eb5aadf12a40b4c3f52fa2',
//   '0x6B175474E89094C44Da98b954EedeAC495271d0F',
//   '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0',
//   //USDT
//   '0xdAC17F958D2ee523a2206206994597C13D831ec7',
//   //Rocket Pool
//   '0xD33526068D116cE69F19A9ee46F0bd304F21A51f',
//   //Uni
//   '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984',
//   '0x6c3F90f043a72FA612cbac8115EE7e52BDe6E490',
// ];
const TOKENS_FANTOM_ARRAY: Array<string> = [
  '0x539bdE0d7Dbd336b79148AA742883198BBF60342',

  '0x543acd673960041eee1305500893260f1887b679',
  '0x0f7adb77b6334b9b5a3c4f78aaa073ea1d915bf6',
  '0x0def844ed26409c5c46dda124ec28fb064d90d27',
  '0xe3a486c1903ea794eed5d5fa0c9473c7d7708f40',
  '0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e',
  '0xde1e704dae0b4051e80dabb26ab6ad6c12262da0',
  '0x3129662808bec728a27ab6a6b9afd3cbaca8a43c',
  '0x18f7f88be24a1d1d0a4e61b6ebf564225398adb0',
  '0x049d68029688eabf473097a2fc38ef61633a3c7a',
  '0xdc301622e621166bd8e82f2ca0a26c13ad0be355',
  '0x7d016eec9c25232b01f23ef992d98ca97fc2af5a',
  '0x82f8cb20c14f134fe6ebf7ac3b903b2117aafa62',
  '0x7dd7eaf5872e260f35dc60a54e0f25193128c765',
  '0x696c1cc6bd689b764be36eecb019c2d9bea761ca',
  '0x66b5666b2b9ad101fc6d3784f995bbf461970ede',
  '0x5f0456f728e2d59028b4f5b8ad8c604100724c6a',
  '0x57976c467608983513c9355238dc6de1b1abbcca',
  '0xc5e7a99a20941cbf56e0d4de608332cdb792e23e',
  '0xb9d62c829fbf7eaff1eba4e50f3d0480b66c1748',
  '0x1d3918043d22de2d799a4d80f72efd50db90b5af',
  '0xeff11197247ac09e0239ec9f83f7de13c186caa6',
  '0xa71353bb71dda105d383b02fc2dd172c4d39ef8b',
  '0x8a41f13a4fae75ca88b1ee726ee9d52b148b0498',
  '0x2b7c5841997275d2d548ce9b19d54b655725ebfc',
  '0x584b521912d5f8cfddcd84083b475daf92fe2865',
  '0x0e1694483ebb3b74d3054e383840c6cf011e518e',
  '0x6c021ae822bea943b2e66552bde1d2696a53fbb7',
  '0x74e23df9110aa9ea0b6ff2faee01e740ca1c642e',
  '0x6fc9383486c163fa48becdec79d6058f984f62ca',
  '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
  '0x4a89338a2079a01edbf5027330eac10b615024e5',
  '0x953e94caf91a1e32337d0548b9274f337920edfa',
  '0xc5cd01e988cd0794e05ab80f2bcdbdf13ce08bd3',
  '0x2f085edec7113e54a1747bd9572b213e662c6165',
];

// const TOKENS_FANTOM: string[] = [
//   //MIM
//   '0x82f0B8B456c1A451378467398982d4834b6829c1',
//   '0xdc301622e621166BD8E82f2cA0A26c13Ad0BE355',
// ];
const TOKENS_AVALANCHE_ARRAY: Array<string> = [
  '0xc7198437980c041c805a1edcba50c1ce5db95118',
  '0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7',
  '0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e',
  '0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664',
  '0x50b7545627a5162f82a992c33b87adc75187b218',
  '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
  '0x02d980a0d7af3fb7cf7df8cb35d9edbcf355f665',
  '0x8ebaf22b6f053dffeaf46f4dd9efa95d89ba8580',
  '0xd586e7f844cea2f87f50152665bcbc2c279d8d70',
  '0x5947bb275c521040051d82396192181b413227a3',
  '0x19860ccb0a68fd4213ab9d8266f7bbf05a8dde98',
  '0x9c9e5fd8bbc25984b178fdce6117defa39d2db39',
  '0x1c20e891bab6b1727d14da358fae2984ed9b59eb',
  '0x8a0cac13c7da965a312f08ea4229c37869e85cb9',
  '0x63a72806098bd3d9520cc43356dd78afe5d386d9',
  '0x88128fd4b259552a9a1d457f435a6527aab72d42',
  '0xd24c2ad096400b6fbcd2ad8b24e7acbc21a1da64',
  '0xcf799767d366d789e8b446981c2d578e241fa25c',
  '0xbec243c995409e6520d7c41e404da5deba4b209b',
  '0x249848beca43ac405b8102ec90dd5f22ca513c06',
  '0xc3048e19e76cb9a3aa9d77d8c03c29fc906e2437',
  '0x62edc0692bd897d2295872a9ffcac5425011c661',
  '0x214db107654ff987ad859f34125307783fc8e387',
  '0xd501281565bf7789224523144fe5d98e8b28f267',
  '0x98443b96ea4b0858fdf3219cd13e98c7a4690588',
  '0x9eaac1b23d935365bd7b542fe22ceee2922f52dc',
  '0x20cf1b6e9d856321ed4686877cf4538f2c84b4de',
  '0x596fa47043f99a4e0f122243b841e55375cde0d2',
  '0x37b608519f91f70f2eeb0e5ed9af4061722e4f76',
  '0xfb98b335551a418cd0737375a2ea0ded62ea213b',
  '0x152b9d0fdc40c096757f570a51e494bd4b943e50',
  '0x2f6f07cdcf3588944bf4c42ac74ff24bf56e7590',
  '0x3bd2b1c7ed8d396dbb98ded3aebb41350a5b2339',
  '0x57319d41f71e81f3c65f2a47ca4e001ebafd4f33',
  '0x6e84a6216ea6dacc71ee8e6b0a5b7322eebc0fdd',
  '0x39fc9e94caeacb435842fadedecb783589f50f5f',
  '0x1f1e7c893855525b303f99bdf5c3c05be09ca251',
  '0x5fc17416925789e0852fbfcd81c490ca4abc51f9',
  '0x340fe1d898eccaad394e2ba0fc1f93d27c7b717a',
  '0x2147efff675e4a4ee1c2f918d181cdbd7a8e208f',
  '0xce1bffbd5374dac86a2893119683f4911a2f7814',
  '0xc891eb4cbdeff6e073e859e987815ed1505c2acd',
  '0x3cd790449cf7d187a143d4bd7f4654d4f2403e02',
  '0x3b55e45fd6bd7d4724f5c47e0d1bcaedd059263e',
  '0xe80772eaf6e2e18b651f160bc9158b2a5cafca65',
  '0xd6070ae98b8069de6b494332d1a1a81b6179d960',
  '0x8729438eb15e2c8b576fcc6aecda6a148776c0f5',
  '0x55b1a124c04a54eefdefe5fa2ef5f852fb5f2f26',
  '0xfcaf13227dcbfa2dc2b1928acfca03b85e2d25dd',
  '0xc7b5d72c836e718cda8888eaf03707faef675079',
  '0x3b9e3b5c616a1a038fdc190758bbe9bab6c7a857',
  '0xc17c30e98541188614df99239cabd40280810ca3',
  '0xde5ed76e7c05ec5e4572cfc88d1acea165109e44',
  '0x80b010450fdaf6a3f8df033ee296e92751d603b3',
  '0x3d9eab723df76808bb84c05b20de27a2e69ef293',
  '0xfcde4a87b8b6fa58326bb462882f1778158b02f1',
  '0x97cd1cfe2ed5712660bb6c14053c0ecb031bff7d',
  '0x0ebd9537a25f56713e34c45b38f421a1e7191469',
  '0xec3492a2508ddf4fdc0cd76f31f340b30d1793e6',
  '0xda5b232fb6e3e897d2f84797d19c53cf3f5ae938',
  '0x60781c2586d68229fde47564546784ab3faca982',
  '0xe5caef4af8780e59df925470b050fb23c43ca68c',
  '0x08d58f06ddfa9b99ae651f68232014be3914c5cd',
  '0xc763f8570a48c4c00c80b76107cbe744dda67b79',
  '0x961c8c0b1aad0c0b10a51fef6a867e3091bcef17',
  '0x0b53b5da7d0f275c31a6a182622bdf02474af253',
  '0xb84527d59b6ecb96f433029ecc890d4492c5dce1',
  '0xb2a85c5ecea99187a977ac34303b80acbddfa208',
  '0x544c42fbb96b39b21df61cf322b5edc285ee7429',
  '0x820802fa8a99901f52e39acd21177b0be6ee2974',
  '0x0cbd6fadcf8096cc9a43d90b45f65826102e3ece',
  '0x1b88d7ad51626044ec62ef9803ea264da4442f32',
  '0xeeeeeb57642040be42185f49c52f7e9b38f8eeee',
  '0xb0a6e056b587d0a85640b39b1cb44086f7a26a1e',
  '0x48f88a3fe843ccb0b5003e70b4192c1d7448bef0',
  '0xc11bf915db4b43714bc8d32bf83bf5ea53d40981',
  '0x4036f3d9c45a20f44f0b8b85dd6ca33005ff9654',
  '0x7d86f1eaff29f076576b2ff09ce3bcc7533fd2c5',
  '0x08287930ca952673b02d3b70eec2893dd7846743',
  '0xafc43610c7840b20b90caaf93759be5b54b291c9',
  '0x9e3ca00f2d4a9e5d4f0add0900de5f15050812cf',
  '0x9e037de681cafa6e661e6108ed9c2bd1aa567ecd',
  '0x6e7f5c0b9f4432716bdd0a77a3601291b9d9e985',
  '0x7b2b702706d9b361dfe3f00bd138c0cfda7fb2cf',
  '0x440abbf18c54b2782a4917b80a1746d3a2c2cce1',
  '0xa32608e873f9ddef944b24798db69d80bbb4d1ed',
  '0xb27c8941a7df8958a1778c0259f76d1f8b711c35',
  '0xafe3d2a31231230875dee1fa1eef14a412443d22',
  '0xbd100d061e120b2c67a24453cf6368e63f1be056',
  '0xd9d90f882cddd6063959a9d837b05cb748718a05',
  '0x637afeff75ca669ff92e4570b14d6399a658902f',
  '0xf891214fdcf9cdaa5fdc42369ee4f27f226adad6',
  '0xa384bc7cdc0a93e686da9e7b8c0807cd040f4e0b',
  '0xbf1230bb63bfd7f5d628ab7b543bcefa8a24b81b',
  '0xf693248f96fe03422fea95ac0afbbbc4a8fdd172',
  '0x846d50248baf8b7ceaa9d9b53bfd12d7d7fbb25a',
  '0xc38f41a296a4493ff429f1238e030924a1542e50',
  '0x00ee200df31b869a321b10400da10b561f3ee60d',
  '0xc69eba65e87889f0805db717af06797055a0ba07',
  '0x90fbe9dfe76f6ef971c7a297641dfa397099a13e',
];
const TOKENS_POLYGON_ARRAY: Array<string> = [
  '0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b',

  '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
  '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
  '0x3ba4c387f786bfee076a58914f5bd38d668b42c3',
  '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
  '0xdab529f40e671a1d4bf91361c21bf9f0c9712ab7',
  '0x0000000000000000000000000000000000001010',
  '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
  '0x2c89bbc92bd86f8075d1decc58c7f4e0107f286b',
  '0x6f8a06447ff6fcf75d803135a7de15ce88c1d4ec',
  '0xb33eaad8d922b1083446dc23f610c2567fb5180f',
  '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
  '0xb0897686c545045afc77cf20ec7a532e3120e0f1',
  '0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39',
  '0x06d02e9d62a13fc76bb229373fb3bbbd1101d2fc',
  '0x2e1ad108ff1d8c782fcbbb89aad783ac49586756',
  '0xc3c7d422809852031b44ab29eec9f1eff2a58756',
  '0xada58df0f643d959c2a47c9d4d4c1a4defe3f11c',
  '0x5fe2b58c013d7601147dcdd68c143a77499f5531',
  '0xd6df932a45c0f255f85145f286ea0b292b21c90b',
  '0x6f7c932e7684666c9fd1d44527765433e01ff61d',
  '0x0266f4f08d82372cf0fcbccc0ff74309089c74d1',
  '0x50b728d8d964fd00c2d0aad81718b71311fef68a',
  '0x45c32fa6df82ead1e2ef74d17b76547eddfaff89',
  '0xbbba073c31bf03b8acf7c28ef0738decf3695683',
  '0xb46e0ae620efd98516f49bb00263317096c114b2',
  '0xb7b31a6bc18e48888545ce79e83e06003be70930',
  '0xa1c57f48f0deb89f569dfbe6e2b7f46d33606fd4',
  '0xffa4d863c96e743a2e1513824ea006b8d0353c57',
  '0xc9c1c1c20b3658f8787cc2fd702267791f224ce1',
  '0x4e8dc2149eac3f3def36b1c281ea466338249371',
  '0x61299774020da444af134c82fa83e3810b309991',
  '0x172370d5cd63279efa6d502dab29171933a610af',
  '0x7205705771547cf79201111b4bd8aaf29467b9ec',
  '0x6f3b3286fd86d8b47ec737ceb3d0d354cc657b3e',
  '0xf1938ce12400f9a761084e7a80d37e732a4da056',
  '0x8505b9d2254a7ae468c0e9dd10ccea3a837aef5c',
  '0x553d3d295e0f695b9228246232edf400ed3560b5',
  '0xee327f889d5947c1dc1934bb208a1e792f953e96',
  '0x1a3acf6d19267e2d3e7f898f42803e90c9219062',
  '0xfad65eb62a97ff5ed91b23afd039956aaca6e93b',
  '0xc8a94a3d3d2dabc3c1caffffdca6a7543c3e3e65',
  '0x1b815d120b3ef02039ee11dc2d33de7aa4a8c603',
  '0x41b3966b4ff7b427969ddf5da3627d6aeae9a48e',
  '0x9c2c5fd7b07e95ee044ddeba0e97a665f142394f',
  '0x7ec26842f195c852fa843bb9f6d8b583a274a157',
  '0x2b9e7ccdf0f4e5b24757c1e1a80e311e34cb10c7',
  '0x3cef98bb43d732e2f285ee605a8158cde967d219',
  '0x5ffd62d3c3ee2e81c00a7b9079fb248e7df024a8',
  '0x6d1fdbb266fcc09a16a22016369210a15bb95761',
  '0x190eb8a183d22a4bdf278c6791b152228857c033',
  '0x84e1670f61347cdaed56dcc736fb990fbb47ddc1',
  '0xcb059c5573646047d6d88dddb87b745c18161d3b',
  '0x6abb753c1893194de4a83c6e8b4eadfc105fd5f5',
  '0x0c51f415cf478f8d08c246a6c6ee180c5dc3a012',
  '0x7583feddbcefa813dc18259940f76a02710a8905',
  '0x282d8efce846a88b159800bd4130ad77443fa1a1',
  '0xda537104d6a5edd53c6fbba9a898708e465260b6',
  '0x101a023270368c0d50bffb62780f4afd4ea79c35',
  '0x0b220b82f3ea3b7f6d9a1d8ab58930c064a2b5bf',
  '0x9a71012b13ca4d3d0cdc72a177df3ef03b0e76a3',
  '0xf6372cdb9c1d3674e83842e3800f2a62ac9f3c66',
  '0x5559edb74751a0ede9dea4dc23aee72cca6be3d5',
  '0x692597b009d13c4049a947cab2239b7d6517875f',
  '0xa8b1e0764f85f53dfe21760e8afe5446d82606ac',
  '0x0ef39e52704ad52e2882bbfa6781167e1b6c4510',
  '0xe5417af564e4bfda1c483642db72007871397896',
  '0xe111178a87a3bff0c8d18decba5798827539ae99',
  '0x0b3f868e0be5597d5db7feb59e1cadbb0fdda50a',
  '0x6e4e624106cb12e168e6533f8ec7c82263358940',
  '0xf8f9efc0db77d8881500bb06ff5d6abc3070e695',
  '0x67ce67ec4fcd4aca0fcb738dd080b2a21ff69d75',
  '0x43e4b063f96c33f0433863a927f5bad34bb4b03d',
  '0x2f6f07cdcf3588944bf4c42ac74ff24bf56e7590',
  '0x0621d647cecbfb64b79e44302c1933cb4f27054d',
  '0xf0059cc2b3e980065a906940fbce5f9db7ae40a7',
  '0x3962f4a0a0051dcce0be73a7e09cef5756736712',
  '0x3066818837c5e6ed6601bd5a91b0762877a6b731',
  '0x1c954e8fe737f99f68fa1ccda3e51ebdb291948c',
  '0xdf7837de1f2fa4631d716cf2502f8b230f1dcc32',
  '0x62414d03084eeb269e18c970a21f45d2967f0170',
  '0xbe662058e00849c3eef2ac9664f37fefdf2cdbfe',
  '0xe0339c80ffde91f3e20494df88d4206d86024cdf',
  '0x614389eaae0a6821dc49062d56bda3d9d45fa2ff',
  '0x0aab8dc887d34f00d50e19aee48371a941390d14',
  '0x77a6f2e9a9e44fd5d5c3f9be9e52831fc1c3c0a0',
  '0xb9638272ad6998708de56bbc0a290a1de534a578',
  '0xc4e82ba0fe6763cbe5e9cbca0ba7cbd6f91c6018',
  '0x77f56cf9365955486b12c4816992388ee8606f0e',
  '0x430ef9263e76dae63c84292c3409d61c598e9682',
  '0xd85d1e945766fea5eda9103f918bd915fbca63e6',
  '0x73580a2416a57f1c4b6391dba688a9e4f7dbece0',
  '0x07cc1cc3628cc1615120df781ef9fc8ec2feae09',
  '0xdb95f9188479575f3f718a245eca1b3bf74567ec',
  '0x42f37a1296b2981f7c3caced84c5096b2eb0c72c',
  '0xdc3326e71d45186f113a2f448984ca0e8d201995',
  '0x98f8669f6481ebb341b522fcd3663f79a3d1a6a7',
  '0xfa68fb4628dff1028cfec22b4162fccd0d45efb6',
  '0xb25e20de2f2ebb4cffd4d16a55c7b395e8a94762',
  '0x840195888db4d6a99ed9f73fcd3b225bb3cb1a79',
  '0xc26d47d5c33ac71ac5cf9f776d63ba292a4f7842',
];
const TOKENS_ARBITRUM_ARRAY: Array<string> = [
  '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
  '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
  '0xaf88d065e77c8cc2239327c5edb3a432268e5831',
  '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f',
  '0xfa7f8980b0f1e64a2062791cc3b0871572f1f7f0',
  '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
  '0xf97f4df75117a78c1a5a0dbb814af92458539fb4',
  '0x4d15a3a2286d883af0aa1b3f21367843fac63e07',
  '0x13ad51ed4f1b7e9dc168d8a00cb3f4ddd85efa60',
  '0x912ce59144191c1204e64559fe8253a0e49e6548',
  '0x9623063377ad1b27544c965ccd7342f7ea7e88c7',
  '0x17fc002b466eec40dae837fc4be5c67993ddbd6f',
  '0x680447595e8b7b3aa1b43beb9f6098c79ac2ab3f',
  '0x11cdb42b0eb46d95f990bedd4695a6e3fa034978',
  '0x1a4da80967373fd929961e976b4b53ceec063a15',
  '0x354a6da3fcde098f8389cad84b0182725c6c91de',
  '0xfc5a1a6eb076a2c7ad06ed22c90d7e710e35ad0a',
  '0x178412e79c25968a32e89b11f63b33f733770c2a',
  '0x9d2f299715d94d8a7e6f5eaa8e654e8c74a988a7',
  '0xcafcd85d8ca7ad1e1c6f82f651fa15e33aefd07b',
  '0x46d0ce7de6247b0a95f67b43b589b4041bae7fbe',
  '0xa0b862f60edef4452f25b4160f177db44deb6cf1',
  '0x82e3a8f066a6989666b031d916c43672085b1582',
  '0x040d1edc9569d4bab2d15287dc5a4f10f56a56b8',
  '0x6e6a3d8f1affac703b1aef1f43b8d2321be40043',
  '0x13780e6d5696dd91454f6d3bbc2616687fea43d0',
  '0x18c11fd286c5ec11c3b683caa813b77f5163a122',
  '0xd4d42f0b6def4ce0383636770ef773390d85c61a',
  '0x23ee2343b892b1bb63503a4fabc840e0e2c6810f',
  '0x080f6aed32fc474dd5717105dba5ea57268f46eb',
  '0x6694340fc020c5e6b96567843da2df01b2ce1eb6',
  '0x289ba1701c2f088cf0faf8b3705246331cb8a839',
  '0xd693ec944a85eeca4247ec1c3b130dca9b0c3b22',
  '0x319f865b287fcc10b30d8ce6144e8b6d1b476999',
  '0x371c7ec6d8039ff7933a2aa28eb827ffe1f52f07',
  '0xe4dddfe67e7164b0fe14e218d80dc4c08edc01cb',
  '0x3082cc23568ea640225c2467653db90e9250aaa0',
  '0x3a8b787f78d775aecfeea15706d4221b40f345ab',
  '0xc9cbf102c73fb77ec14f8b4c8bd88e050a6b2646',
  '0xfea7a6a0b346362bf88a9e4a88416b77a57d6c2a',
  '0x0c880f6761f1af8d9aa9c466984b80dab9a8c9e8',
  '0xda0a57b710768ae17941a9fa33f8b720c8bd9ddd',
  '0x6fe14d3cc2f7bddffba5cdb3bbe7467dd81ea101',
  '0xda661fa59320b808c5a6d23579fcfedf1fd3cf36',
  '0x3e6648c5a70a150a88bce65f4ad4d506fe15d2af',
  '0x6a7661795c374c0bfc635934efaddff3a7ee23b6',
  '0x1509706a6c66ca549ff0cb464de88231ddbe213b',
  '0x4e352cf164e64adcbad318c3a1e222e9eba4ce42',
  '0xa970af1a584579b618be4d69ad6f73459d112f95',
  '0x3f56e0c36d275367b8c502090edf38289b3dea0d',
  '0xbfa641051ba0a0ad1b0acf549a89536a0d76472e',
  '0x69eb4fa4a2fbd498c257c57ea8b7655a2559a581',
  '0x223738a747383d6f9f827d95964e4d8e8ac754ce',
  '0x9fb9a33956351cf4fa040f65a13b835a3c8764e3',
  '0x51fc0f6660482ea73330e414efd7808811a57fa2',
  '0x5298060a95205be6dd4abc21910a4bb23d6dcd8b',
  '0x1b01514a2b3cdef16fd3c680a818a0ab97da8a09',
  '0x3405e88af759992937b84e58f2fe691ef0eea320',
  '0x0385f851060c09a552f1a28ea3f612660256cbaa',
  '0x079504b86d38119f859c4194765029f692b7b7aa',
  '0xac9ac2c17cdfed4abc80a53c5553388575714d03',
  '0x753d224bcf9aafacd81558c32341416df61d3dac',
  '0xd58d345fd9c82262e087d2d0607624b410d88242',
  '0x39f91452a6e9994762876e1edd87be28a46d5c99',
  '0x3404149e9ee6f17fb41db1ce593ee48fbdcd9506',
  '0xaedf7656fbb47c5b97dd529ac1d0e807e051f2dd',
  '0x0d81e50bc677fa67341c44d7eaa9228dee64a4e1',
  '0xb64e280e9d1b5dbec4accedb2257a87b400db149',
  '0xfa5ed56a203466cbbc2430a43c66b9d8723528e7',
  '0xe85b662fe97e8562f4099d8a1d5a92d4b453bf30',
  '0x3b60ff35d3f7f62d636b067dd0dc0dfdad670e4e',
  '0x7ba4a00d54a07461d9db2aef539e91409943adc9',
  '0xf6553cd90bd48ff0dc17c60baeaaf1ca325fe3b0',
  '0xa7aa2921618e3d63da433829d448b58c9445a4c3',
  '0xae6aab43c4f3e0cea4ab83752c278f8debaba689',
  '0xaea8e2e7c97c5b7cd545d3b152f669bae29c4a63',
  '0xef888bca6ab6b1d26dbec977c455388ecd794794',
  '0x088cd8f5ef3652623c22d48b1605dcfe860cd704',
  '0xe6045890b20945d00e6f3c01878265c03c5435d3',
  '0xd91903d548f19c0fc0a6b9ed09d2f72b4dfe7144',
  '0x589d35656641d6ab57a545f08cf473ecd9b6d5f7',
  '0xadf5dd3e51bf28ab4f07e684ecf5d00691818790',
  '0x3d9907f9a368ad0a51be60f7da3b97cf940982d8',
  '0x99f40b01ba9c469193b360f72740e416b17ac332',
  '0xbdef0e9ef12e689f366fe494a7a7d0dad25d9286',
  '0x2ad62674a64e698c24831faf824973c360430140',
  '0x326c33fd1113c1f29b35b4407f3d6312a8518431',
  '0x031d35296154279dc1984dcd93e392b1f946737b',
  '0xf4d48ce3ee1ac3651998971541badbb9a14d7234',
  '0x55678cd083fcdc2947a0df635c93c838c89454a3',
  '0x10010078a54396f62c96df8532dc2b4847d47ed3',
  '0x53691596d1bce8cea565b84d4915e69e03d9c99d',
  '0xa58663faef461761e44066ea26c1fcddf2927b80',
  '0xde5ed76e7c05ec5e4572cfc88d1acea165109e44',
  '0x9e758b8a98a42d612b3d38b66a22074dc03d7370',
  '0x30424981429c8b944e9d44d5bd992ee874f1b188',
  '0x55704a0e9e2eb59e176c5b69655dbd3dcdcfc0f0',
  '0x10393c20975cf177a3513071bc110f7962cd67da',
  '0x84f5c2cfba754e76dd5ae4fb369cfc920425e12b',
  '0x68ead55c258d6fa5e46d67fc90f53211eab885be',
];

const TOKENS_ETHEREUM: Set<string> = new Set(TOKENS_ETHEREUM_ARRAY.map((token) => token.toLowerCase()));
const TOKENS_BSC: Set<string> = new Set(TOKENS_BSC_ARRAY.map((token) => token.toLowerCase()));
const TOKENS_FANTOM: Set<string> = new Set(TOKENS_FANTOM_ARRAY.map((token) => token.toLowerCase()));
const TOKENS_AVALANCHE: Set<string> = new Set(TOKENS_AVALANCHE_ARRAY.map((token) => token.toLowerCase()));
const TOKENS_POLYGON: Set<string> = new Set(TOKENS_POLYGON_ARRAY.map((token) => token.toLowerCase()));
const TOKENS_ARBITRUM: Set<string> = new Set(TOKENS_ARBITRUM_ARRAY.map((token) => token.toLowerCase()));

const defaultTokenList: { [key in CHAIN]: Set<string> } = {
  [CHAIN.ETHEREUM]: TOKENS_ETHEREUM,
  [CHAIN.BSC]: TOKENS_BSC,
  [CHAIN.FANTOM]: TOKENS_FANTOM,
  [CHAIN.AVALANCHE]: TOKENS_AVALANCHE,
  [CHAIN.POLYGON]: TOKENS_POLYGON,
  [CHAIN.ARBITRUM]: TOKENS_ARBITRUM,
};

const filters: { [key: string | number]: string[] } = {
  '0x38': bnbTokensFilter,
};
const whiteList: { [key: string | number]: string[] } = {
  '0x38': bnbTokensWhiteList,
};

export { filters, whiteList, defaultTokenList, stableCoinListBySymbol };
