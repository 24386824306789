import { CHAIN } from './chain';

export const openseaChains: { [key in CHAIN]: string | undefined } = {
  [CHAIN.ETHEREUM]: 'ethereum',
  [CHAIN.BSC]: 'bsc',
  [CHAIN.FANTOM]: undefined,
  [CHAIN.AVALANCHE]: 'avalanche',
  [CHAIN.POLYGON]: 'matic',
  [CHAIN.ARBITRUM]: 'arbitrum',
};
