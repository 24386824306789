export const info = {
  networth: {
    title: 'Net Worth',
    //test not add tooltip
    tooltip: 'Net worth = Total assets + LP Pools rewards (ETH)',
    // tooltip: 'Networth',
  },
  totalAssets: {
    title: 'Total Assets',
    tooltip: 'Total assets = NFT + token assets ',
  },
  debts: {
    title: 'Debts',
    tooltip: 'Total debts = AaveV3 + Compound borrowed (ETH)',
  },
  nftValue: {
    title: 'NFT Value',
    tooltip: 'NFT value = combined NFT USD value',
  },
};
