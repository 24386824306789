export type CreditColor = {
  title: string;
  color: string;
  min: number;
  max: number;
  textColor?: string;
};

const poor: CreditColor = {
  title: 'poor',
  color: '#EB4700',
  //'rgba(235, 71, 0, 1)', //'rgba(235, 0, 131, 1)',
  min: 300,
  max: 579,
  textColor: 'text-[#EB4700]',
};
const fair: CreditColor = {
  title: 'fair',
  color: '#EBA900',
  //'rgba(235, 169, 0, 1)', //'rgba(191, 0, 134, 1)',
  min: 580,
  max: 669,
  textColor: 'text-[#EBA900]',
};
const good: CreditColor = {
  title: 'good',
  color: '#E6EB00',
  //'rgba(230, 235, 0, 1)', //'rgba(122, 22, 125, 1)',
  min: 670,
  max: 739,
  textColor: 'text-[#E6EB00]',
};
const veryGood: CreditColor = {
  title: 'veryGood',
  color: '#3DEB00',
  //'rgba(61, 235, 0, 1)', //'rgba(0, 111, 189, 1)',
  min: 740,
  max: 799,
  textColor: 'text-[#3DEB00]',
};
const exceeptional: CreditColor = {
  title: 'exceeptional',
  color: '#00F0FF',
  //'rgba(0, 240, 255, 1)', //'rgba(0, 70, 149, 1)',
  min: 800,
  max: 850,
  textColor: 'text-[#00F0FF]',
};

export const creditColor: { [key: string]: CreditColor } = {
  [poor.min]: poor,
  [fair.min]: fair,
  [good.min]: good,
  [veryGood.min]: veryGood,
  [exceeptional.min]: exceeptional,
};
