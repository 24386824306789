import React, { useState } from 'react';

export const useIsOverflow = (
  ref: React.MutableRefObject<HTMLDivElement | undefined>,
  tags?: Array<string>,
  callback?: any,
) => {
  const offset = 10;
  const [isOverflow, setIsOverflow] = useState<boolean | undefined>(undefined);

  React.useLayoutEffect(() => {
    const { current } = ref;
    if (!current) {
      return;
    }

    const trigger = () => {
      const hasOverflow = current.scrollHeight > current.clientHeight + offset;

      setIsOverflow(hasOverflow);

      if (callback) {
        callback(hasOverflow);
      }
    };

    if (current) {
      if ('ResizeObserver' in window) {
        new ResizeObserver(trigger).observe(current);
        // new MutationObserver(trigger).observe(current);
      }

      trigger();
    }
  }, [callback, ref, JSON.stringify(tags)]);

  return isOverflow;
};
