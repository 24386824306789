import { Box } from '@chakra-ui/react';
import React from 'react';

// import zoomPlugin from 'chartjs-plugin-zoom';

function BlockInfo({ ...props }) {
  return (
    <Box p="5" w="100%" maxW="100%" rounded="8px" backgroundColor={'rgba(255, 255, 255, 0.1)'}>
      {props.children}
    </Box>
  );
}

export default BlockInfo;
