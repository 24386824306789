export interface AvatarInfo {
  name: string;
  image: string;
}

// Define the type for the DemoAvatar object
export interface DemoAvatarType {
  [address: string]: AvatarInfo;
}
export const DemoAvatar: DemoAvatarType = {
  '0xce90a7949bb78892f159f428d0dc23a8e3584d75': {
    name: 'Snoop Dogg',
    image:
      'https://dappradar.com/blog/static/eeef709d36e71f0e909cd53c31a6ebd9/bf65c/dappradar.com-celebrity-crypto-nft-wallets-rich-list-snoop-dogg-cryptopunk-7899.png',
  },
  '0x991091D5596484f3153aC8707c76Ec79f6D353ED': {
    name: 'Zach Hyman',
    image:
      'https://dappradar.com/blog/static/90b1160c91365b3ed6db73cb2baaedf2/1077f/dappradar.com-celebrity-crypto-nft-wallets-rich-list-zack-hyman-bored-ape-yacht-club-3563.png',
  },
  '0xa679c6154b8d4619af9f83f0bf9a13a680e01ecf': {
    name: 'Mark Cuban',
    image:
      'https://dappradar.com/blog/static/0e830369c23b9d6299d8d11b5a763825/1077f/dappradar.com-celebrity-crypto-nft-wallets-rich-list-mark-cuban-nfts.png',
  },
  '0xd6a984153acb6c9e2d788f08c2465a1358bb89a7': {
    name: 'Gary Vaynerchuk',
    image:
      'https://dappradar.com/blog/static/3efc8896f3cbc09546d477a44ec28459/1077f/dappradar.com-celebrity-crypto-nft-wallets-rich-list-gary-vaynerchuk-crypto-kitties-nft.png',
  },
  '0xa0eaf6b0df87132c9a28e450a43c1d906defb60b': {
    name: 'DJ Marshmellow',
    image:
      'https://dappradar.com/blog/static/a3f023fc4096f24eb9549de568732049/1077f/dappradar.com-celebrity-crypto-nft-wallets-rich-list-dj-marshmellow-otherdeed.png',
  },
  '0xff0bd4aa3496739d5667adc10e2b843dfab5712b': {
    name: 'Logan Paul',
    image:
      'https://dappradar.com/blog/static/3823bd8dd4bb994dd54af5c9ed64f1dd/1077f/dappradar.com-celebrity-crypto-nft-wallets-rich-list-logan-paul-nfts.png',
  },
  '0x0ed1e02164a2a9fad7a9f9b5b9e71694c3fad7f2': {
    name: 'Alexis Ohanian',
    image:
      'https://dappradar.com/blog/static/51bfb828d5df120bc6c8ad37b7d1119e/1077f/dappradar.com-celebrity-crypto-nft-wallets-rich-list-alexis-ohanian-nft.png',
  },
  '0xd8da6bf26964af9d7eed9e03e53415d37aa96045': {
    name: 'vitalik.eth',
    image: 'https://assets-global.website-files.com/63eeffaede17fe02b68b432c/65a94bab624fcc59aecb186c_image.gif',
  },
};
