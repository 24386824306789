import axios from 'axios';
import { blue_whale_api } from '../configs';
import { useQuery } from 'react-query';
import { json } from 'stream/consumers';

export type PnlDataType = {
  total_month: number;
  total_amount: number;
  pnl: Array<{
    date: string;
    value: number;
    label: string;
  }>;
};

export function useGetPnlData({ address, total_month = 6 }: { address: string; total_month?: number }) {
  function getData(_address: string) {
    return axios({
      method: 'post',
      url: `${blue_whale_api.pnl}`,
      data: {
        wallet: _address,
        total_month,
      },
    }).then(
      (res) => res.data as PnlDataType,
      // as { wallets: Array<SimalarWalletType> }
    );
  }
  const query = useQuery({
    queryKey: ['pnl', address],
    queryFn: async () => getData(address),
  });
  const { data, status } = query;
  // console.log('useGetPNLData', data);
  return { data, status };
}
