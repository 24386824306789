/* eslint-disable complexity */
import { useEffect, useRef, useState } from 'react';
import { Transaction } from './useGetTransactions';
import { useGetTokenBalancesNOut } from './useGetTokenBalancesN';
import { useGetNativeBalanceOut } from './useGetNativeBalance';
import { Status } from '../interfaces/types';
import { useDashBoard } from '../contexts/DashBoardContext';
import { calcBalance, calcTotalAsset } from '../utils';
import { getLastDayOfMonthBeforeToday, getNumber } from '../utils/helper';
import { MoralisNftTradeState, useGetNftDataOut } from './useGetNftData';
import { MoralisNft } from '../interfaces';
import { chainList } from '../configs';
import { ProtocolData } from '@/types/bluwhaleDataApi';

export type useGenerateUITagsParams = {
  // nativeDto?: useGetNativeBalanceOut;
  // tokenDto?: useGetTokenBalancesNOut;
  // nftDto?: useGetNftDataOut;
  // tsxDto?: Array<Transaction>;
  tagsDto?: Array<string>;
  protocolDto?: Array<any>;
  protocolDtoV2?: ProtocolData;
};
export function useGenerateUITags(params?: useGenerateUITagsParams) {
  const { walletAddress: address } = useDashBoard();

  const addressRef = useRef<string>();

  const [status, setStatus] = useState<Status>('idle');

  const [tagsDto, setTagsDto] = useState<string[]>();

  function init() {
    setStatus((pre) => 'loading');
    setTagsDto((pre) => undefined);
  }

  function excute(
    _tagsDto?: Array<string>,
    _protocolDtoV2?: ProtocolData,
    // _protocolDto?: Array<any>,
    _nativeDto?: useGetNativeBalanceOut,
    _tokenDto?: useGetTokenBalancesNOut,
    _nftDto?: useGetNftDataOut,
    _tsxDto?: Array<Transaction>,
  ) {
    const dafalutTags = [
      // { title: 'Analyzing... ', fn: _checkStatusIsLoading, isShow: true },
      // { title: 'NFT Trader ', fn: _validateNftTradedInThreeMonths, isShow: false },
      // { title: 'NFT Minter ', fn: _validateNftMinter, isShow: false },
      // { title: 'NFT Holder ', fn: _validateNftHolder, isShow: false },
      // { title: 'NFT Sweeper ', fn: _validateNftSweeper, isShow: false },
      // { title: 'NFT Whale ', fn: _validateNftWhale, isShow: false },
      // { title: 'stable coin holder', fn: _validateStableCoinHolder, isShow: false },
      // { title: 'erc20 flipper', fn: _validateBuyToSellInOneDay, isShow: false },
      // { title: 'liquidity Farmer', fn: _validateLiquidityFarmer, isShow: false },
      // { title: 'AGGRESSIVE ACTIVITY', fn: _validateAggressiveActive, isShow: false },
      // { title: 'Assets Millionare', fn: _validateAssetsMillionare, isShow: false },
      { title: 'Liquidity Provider', fn: _validateLiquidity, isShow: false },
      { title: 'Yield Farmer', fn: _validateYieldFarmer, isShow: false },
      { title: 'Dex Trader', fn: _validateDexTrader, isShow: false },
      { title: 'Heavy Borrower', fn: _validateHeavyBorrower, isShow: false },
    ];

    const rewardsBalance = Number(_protocolDtoV2?.totalRewardUsd ?? 0);
    const collectsBalance =
      Number(_protocolDtoV2?.totalPoolUsd ?? 0) +
      Number(_protocolDtoV2?.totalStakeUsd ?? 0) +
      Number(_protocolDtoV2?.totalSupplyUsd ?? 0);

    // const debtsBalance = calcBalance(['compound', 'aavev3'], 'borrowsTotalAmountsUsd', _protocolDto);
    const debtsBalance = Number(_protocolDtoV2?.totalBorrowUsd ?? 0);

    const nftBalanceUsd = getNumber(_nftDto?.totalChainNftBalanceUsd);
    const tokenBalanceUsd = getNumber(_tokenDto?.totalBalanceUsd);
    const nativeBalanceUsd = getNumber(_nativeDto?.totalBalanceUsd);
    const totalAssetFrom = [collectsBalance, nativeBalanceUsd, tokenBalanceUsd, nativeBalanceUsd, nftBalanceUsd];
    const totalAsset = calcTotalAsset(totalAssetFrom);

    const hasNftTrades = _nftDto?.hasNftTrades;
    const options = {
      nativeTotalBalance: Number(_nativeDto?.totalBalanceUsd ?? 0),
      tokenTotalBalance: Number(_tokenDto?.totalBalanceUsd ?? 0),
      currentStableList: _tokenDto?.balanceSumByStableCoin?.list,
      lastStableList: _tokenDto?.balanceSumByStableCoin?.list,
    };

    const nftDetail = _nftDto?.nftListByFlat?.map((nft) => nft.raw) as Array<MoralisNft>;

    const _tags = dafalutTags.map((tag) => {
      return { title: tag.title, isShow: tag.fn() };
    });
    //#region validate

    function __validateAssetsMillionare(balance: number, amounts = 1000000) {
      return balance >= amounts;
    }
    function __validateAssetsPercent(balance: number, amounts = 1000000) {
      return balance / amounts >= 0.5;
    }
    function __convertNftSweeper(nfts: MoralisNftTradeState) {
      const calcCollects: Record<string, { in: Set<string>; out: Set<string> }> = {};

      chainList.forEach((chain) => {
        const _nfts = nfts[chain.key];
        if (!_nfts) {
          return;
        }
        const { key } = chain;
        _nfts.forEach((nft) => {
          const isOut = nft.from_address?.toLowerCase() === address.toLowerCase();
          const collection = `${key}${nft.token_address}`;
          const _key = `${nft.token_id}`;

          // Initialize the collection if it doesn't exist
          if (!calcCollects[collection]) {
            calcCollects[collection] = { in: new Set(), out: new Set() };
          }

          // Add _key to the appropriate set
          const setToAddTo = isOut ? calcCollects[collection].out : calcCollects[collection].in;
          setToAddTo.add(_key);
        });
      });

      return calcCollects;
    }
    //#region  _validate
    function _validateNftTradedInThreeMonths(): boolean {
      return hasNftTrades ?? false;
    }
    function _validateStableCoinHolder(): boolean {
      const arr1 = Array.from(options?.currentStableList?.keys() ?? []);
      const arr2 = Array.from(options?.lastStableList?.keys() ?? []);
      if (arr1.length === 0 && arr2.length === 0) {
        return false;
      }
      const res = arr1.every((item) => arr2.includes(item));
      return res;
    }
    function _validateBuyToSellInOneDay(): boolean {
      return _tokenDto?.hasFlipper ?? false;
      // if (!_tsxDto) {
      //   return false;
      // }
      // return _tsxDto.filter((txs) => new Date(txs.date).getTime() >= new Date().setHours(0, 0, 0, 0)).length > 0
      //   ? true
      //   : false;
    }
    function _validateLiquidityFarmer(): boolean {
      return __validateAssetsMillionare(collectsBalance);
    }
    function _validateAggressiveActive(): boolean {
      if (!_tsxDto) {
        return false;
      }
      return _tsxDto.length > 5;
    }
    function _validateAssetsMillionare(): boolean {
      //Assets 總額超過1m 六條鏈總額
      // 包含native 跟ERC20
      if (!options) {
        return false;
      }
      if (options.nativeTotalBalance === undefined) {
        return false;
      }
      if (options.tokenTotalBalance === undefined) {
        return false;
      }
      const balance = options.nativeTotalBalance + options.tokenTotalBalance;
      return __validateAssetsMillionare(balance);
    }
    function _validateLiquidity(): boolean {
      //TODO
      // Crypto holders deposit 100K+ to liquidity pools
      // in order to provide liquidity to other users.
      if (collectsBalance === 0) {
        return false;
      }
      return __validateAssetsMillionare(collectsBalance, 100000);
    }
    function _validateYieldFarmer(): boolean {
      //Earn 100k+ extra financial rewards
      //(Balance + Claimable)  with crypto holdings
      // collects(uniswapv3 + aavev3 + compoundv2) + rewards(uniswapv3)

      if (collectsBalance === 0 && rewardsBalance === 0) {
        return false;
      }
      const balance = collectsBalance + rewardsBalance;
      return __validateAssetsMillionare(balance, 100000);
    }
    function _validateDexTrader(): boolean {
      //Made trades on decentralized exchanges.
      if (!rewardsBalance && !collectsBalance && !debtsBalance) {
        return false;
      }
      if (rewardsBalance === 0 && collectsBalance === 0 && debtsBalance === 0) {
        return false;
      }
      return true;
    }
    function _validateHeavyBorrower(): boolean {
      //Depts >50% of total assets
      if (debtsBalance === undefined) {
        return false;
      }

      return __validateAssetsPercent(debtsBalance, totalAsset);
    }
    function _validateNftMinter(): boolean {
      if (!nftDetail) {
        return false;
      }
      const index = nftDetail?.findIndex((nft) => nft.minter_address?.toLowerCase() === nft.owner_of?.toLowerCase());
      return index !== -1;
    }
    /**
     * @summary Holding over 50% of total NFTs more than 6 months
     * @returns
     */
    function _validateNftHolder(): boolean {
      const lastDays = getLastDayOfMonthBeforeToday(6);
      const lastDay = lastDays[5];
      const _length = nftDetail?.length;
      let sum = 0;
      for (let i = 0; i < nftDetail?.length; i++) {
        if (sum !== 0 && sum >= _length / 2) {
          return true;
        }
        const nft = nftDetail[i];
        if (new Date(nft.last_token_uri_sync) <= lastDay) {
          sum++;
        }
      }
      if (sum !== 0 && sum >= _length / 2) {
        return true;
      }
      return false;
    }
    /**
     * @summary five nfts which that address has 3 transfers in/out in different token_ids in a single day
     * @returns
     */
    function _validateNftSweeper(): boolean {
      const collectionsBasic = 3;
      const tradesBasic = 5;
      const nfts = _nftDto?.nftTrades;
      if (!nfts) {
        return false;
      }
      //counts each collection has 5 different token_id trades(in or out)
      //if same token_id has both in and out, the trades will be 2
      // the formula is trades = in.size + out.size
      //finally if counts > 3 will return true

      const calcCollects = __convertNftSweeper(nfts);
      let calc = 0;
      for (const key in calcCollects) {
        if (Object.prototype.hasOwnProperty.call(calcCollects, key)) {
          const tmp = calcCollects[key];
          const trades = tmp.in.size + tmp.out.size;
          if (trades > tradesBasic) {
            calc++;
          }
        }
      }
      // console.log('>>>>>>>>>>>>>>>>', calc, calcCollects);

      return calc >= collectionsBasic;
    }
    /**
     * @summary total amounts >= 1,000,000
     * @returns
     */
    function _validateNftWhale(): boolean {
      if (!_nftDto?.totalChainNftBalanceUsd) {
        return false;
      }
      return _nftDto?.totalChainNftBalanceUsd >= 1000000;
    }
    //#endregion

    //#endregio n

    const sampleTags = ['A', 'B', 'C'];
    const tags = _tagsDto?.filter((tag) => !sampleTags.includes(tag));

    const tagsStr = _tags
      .filter((tag) => tag.isShow)
      .map((tag) => tag.title)
      .concat(tags ? tags : []);
    //remove same tags
    const tagsStrTmp = tagsStr.map((tag) => tag.trim().toLowerCase());
    const tagsStrSet = new Set(tagsStrTmp);
    const tagsStrArr = Array.from(tagsStrSet);
    setTagsDto(tagsStrArr);
    setStatus((pre) => 'success');
  }

  useEffect(() => {
    if (address !== addressRef.current) {
      addressRef.current = address;
      setStatus((pre) => 'loading');
      setTagsDto((pre) => undefined);
    }
    if (!params) {
      return;
    }
    excute(params.tagsDto, params.protocolDtoV2);
  }, [params?.protocolDto, params?.protocolDtoV2, params?.tagsDto, address]);

  return { tagsDto, status, onInit: init, onExcute: excute };
}
