import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { event } from 'nextjs-google-analytics';
import { events } from '@/constants/analytics';
import {
  Box,
  Flex,
  Image,
  Grid,
  GridItem,
  Wrap,
  WrapItem,
  Text,
  Tag,
  Button,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useClipboard,
  ModalFooter,
  Input,
  Tooltip,
  Avatar,
} from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import { ChartCredit } from '.';
import { CreditData } from '../hooks/useGenerateUIChartCredit';
import { Status } from '../interfaces/types';
import { useDashBoard } from '../contexts/DashBoardContext';
import { ChartCreditParams } from './ChartCredit';
import { basicConfig } from '@/configs';
import { mockUser } from '@/mocks/mockData';
import { json } from 'stream/consumers';
import { useIsOverflow } from '../hooks/useIsOverflow';
import { claimUserRewards, getUserClaimStatus } from '../../../../../pages/api/userClaim';
import { useFetchUserClaimStatus } from '../../../../../redux/useFetchUserClaimStatus';
import { addUserToOutReachList, fetchUserInfo, sendMsgList } from '../../../../../pages/api/enterpriseapi';
import { useGetEnterpriseDetail } from '../../../../../redux/useGetEnterpriseDetail';
import { useClaimProfit } from '../../../../../redux/useClaimProfit';
import CountUp from 'react-countup';
import { debounce } from '@/utils/debounce';
import { DemoAvatar } from '@/constants/demoAvatar';
import { useOutReachList } from '../../../../../redux/useOutReachList';
import { useBluwhale } from '@/context/BluwhaleContext';
import MsgPop from '@/components/constomer/MsgPop/MsgPop';
import { useGetConsumerCompaigns } from '@/hooks/useGetConsumerCompaigns';
import { cutEllipsisTxtWithLength, getEllipsisTxt } from '@/utils/format';
import { getOtherWalletPoints, getSpecificConsumerPointsInfo } from '../../../../../pages/api/consumer_points';
import { Icon, InfoOutlineIcon } from '@chakra-ui/icons';
import { ConsumerPointsInfo } from '../../../../../types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { useFetchOccupiedSpots } from '@/hooks/useFetchOccupiedSpots';
import ConsumerBuySpot from '@/components/constomer/MsgPop/ConsumerBuySpot';

export type PlatformUserParams = {
  myAddress: string;
  tag: { tagsString?: string[]; status: Status };
  // credit: { data?: CreditData|ChartCreditParams; status: Status };
  credit: { data?: ChartCreditParams; status: Status };
  isDemo?: boolean;
  isHunted?: boolean;
  onFollowClick: (address: string) => void;
  hideFollow?: boolean;
  refetchOut?: () => void;
};

export type OtherInfoType = {
  display_name?: string;
  is_claimed?: boolean;
  photo_url?: string;
};
// eslint-disable-next-line complexity
function PlatformUser({
  myAddress,
  tag,
  credit,
  isDemo = false,
  onFollowClick,
  isHunted,
  hideFollow,
  refetchOut,
}: PlatformUserParams) {
  const toast = useToast();
  const router = useRouter();
  const { user, onLogout, axiosInstance } = useBluwhale();

  const { data: enterpriseDetails } = useGetEnterpriseDetail();

  const consumerInfo = useSelector((state: RootState) => state.consumerInfo);
  const { data: spots, status, refetch: refetchOccupiedSpots } = useFetchOccupiedSpots();
  const [isBuy, setIsBuy] = useState(true);

  //@ts-ignore
  const { owned_project } = enterpriseDetails || {};
  const { data: inbox } = useGetConsumerCompaigns();

  const { data: outReachUser, refetch: refetchOutReachUser } = useOutReachList();
  const { walletAddress: address, globalStatus } = useDashBoard();
  const tagIsLoading = !isDemo && (globalStatus === 'loading' || tag.status === 'loading');
  const tagIsComplete = globalStatus === 'idle' && tag.status === 'success';
  const tagIsError = globalStatus === 'idle' || tag.status === 'error';
  const avatarColor = address.replace('0x', '').substring(0, 6);

  const isMe = myAddress?.toLowerCase() === address?.toLowerCase();

  const { onCopy } = useClipboard(address);

  const originTags = isDemo ? mockUser.tag.tagsString : tag.tagsString;
  //@ts-ignore
  const tags = [...new Set(originTags)];
  // const tags = mockUser.tag.tagsString.concat(mockUser.tag.tagsString).concat(mockUser.tag.tagsString);
  const credits = isDemo ? mockUser.credit : credit;

  const { consumerPoints, consumerReferralCode, consumerReferralUser } = useSelector(
    (state: RootState) => state.consumerPoints,
  );
  // more tages btn
  const [isShowMoreTags, setIsShowMoreTags] = useState<boolean>(false);
  const [isMoreTagsBtn, setIsMoreTagsBtn] = useState<boolean>(false);
  const tagsRef = useRef<HTMLDivElement | undefined>(undefined);
  const isOverflow = useIsOverflow(tagsRef, tags);
  const [othersInfo, setOthersInfo] = useState<OtherInfoType | undefined>(undefined);
  const [claimedStatus, setClaimStatus] = useState<any | undefined>(undefined);

  const [otherUserPoints, setOtherUserPoints] = useState<ConsumerPointsInfo | undefined>(undefined);
  // send msg
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isBuyOpen, onOpen: onBuyOpen, onClose: onBuyClose } = useDisclosure();

  const addToOutReachList = async () => {
    const result = await addUserToOutReachList([address], 'Selected Individual');
    toast({
      description: `Successfully added to outreach list`,
      status: 'success',
      position: 'top',
    });
    refetchOut?.();
    refetchOutReachUser();
  };

  const claimReward = async () => {
    event(events.btn_claim_profile);
    if (user?.provider_type !== 'social') {
      const currentPath = router.asPath;
      router.push(
        `/consumer/link?from=${currentPath}&claimProfile=${myAddress}&rewardBlu=${claimedStatus?.rewardBlu}&rewardUsd=${claimedStatus?.rewardUsd}`,
      );
    } else {
      const result = await claimUserRewards(myAddress);
      toast({
        description: `Successfully claimed`,
        status: 'success',
        position: 'top',
      });
      getUserClaimedInfo();
    }
  };
  useEffect(() => {
    setIsMoreTagsBtn(false);
  }, [address]);
  useEffect(() => {
    if (isOverflow) {
      setIsMoreTagsBtn(true);
    }
  }, [isOverflow]);

  useEffect(() => {
    if (myAddress) {
      getUserClaimedInfo();
    }
  }, [myAddress]);

  useEffect(() => {
    const exist = spots?.find((item: any) => item?.address?.toLowerCase() === address?.toLowerCase());
    if (exist) {
      setIsBuy(false);
    } else {
      setIsBuy(true);
    }
  }, [spots, address]);
  const copyCode = async () => {
    await onCopy();
    toast.closeAll();
    toast({
      description: `Copied to clipboard`,
      status: 'success',
      position: 'top',
    });
  };

  const showClaim = isMe && claimedStatus?.claimed === false;

  //@ts-ignore
  const inOutReachList = outReachUser && outReachUser.some((item) => item?.address === address.toLowerCase());
  const bgImage = othersInfo?.photo_url
    ? othersInfo?.photo_url
    : address && DemoAvatar[address]?.image
    ? DemoAvatar[address].image
    : isDemo
    ? '/images/demo-avatar.png'
    : '/images/default_avatar.png';
  const projectName = othersInfo?.display_name
    ? othersInfo?.display_name?.toLowerCase() === address.toLowerCase()
      ? `${address.slice(0, 4)}...${address.slice(-4)}`
      : cutEllipsisTxtWithLength(othersInfo?.display_name, 20)
    : DemoAvatar[address]?.name
    ? DemoAvatar[address]?.name
    : `${address.slice(0, 4)}...${address.slice(-4)}`;

  const getUserInfo = async () => {
    try {
      const result: OtherInfoType = await fetchUserInfo(address);
      const replacedResult = {
        ...result,
        photo_url: result?.photo_url?.replace('_normal', ''),
      };
      setOthersInfo(replacedResult);
    } catch (e: any) {
      console.log(e);
    }
  };

  const getUserClaimedInfo = async () => {
    const result = await getUserClaimStatus(myAddress, axiosInstance);

    setClaimStatus(result);
  };

  const fetchPoints = async () => {
    //todo check user spots
    const result = await getOtherWalletPoints(address, axiosInstance);
    setOtherUserPoints(result);
  };

  useEffect(() => {
    if (address) {
      getUserInfo();
    }
    if (!isMe && user) {
      fetchPoints();
    }
  }, [address, isMe, user]);

  useEffect(() => {
    getUserInfo();
  }, [consumerInfo?.display_name, consumerInfo?.photo_url]);

  const openMsgBox = () => {
    if (claimedStatus?.claimed) {
      event(events.btn_msg_click, {
        target_user: `.${address}.`,
        spot:
          otherUserPoints?.avaiable_spots && otherUserPoints?.avaiable_spots > 0
            ? otherUserPoints?.avaiable_spots
            : otherUserPoints?.queued_users,
      });
      if (isBuy) {
        onBuyOpen();
      } else {
        onOpen();
      }
    } else if (!myAddress) {
      event(events.connectwallet_display, {
        source: 'btn_send_msg',
      });
      router.push('/consumer/login?type=web3');
      return;
    }
  };

  let targetUser;
  if (isBuy) {
    targetUser = {
      address,
      display_name: othersInfo?.display_name,
      photo_url: othersInfo?.photo_url,
    };
  }

  const sendEmptyMessage = async () => {
    if (user?.address) {
      await sendMsgList(user?.address, address, ' ', '', axiosInstance);
      await refetchOccupiedSpots();
    }
  };
  useEffect(() => {
    refetchOccupiedSpots();
  }, [address]);
  return (
    <Box>
      <Flex
        maxWidth="100%"
        gap={[2, null, 4]}
        alignSelf=""
        mb="16px"
        justifyContent={['space-between', null, '']}
        alignItems={'center'}
      >
        {othersInfo?.photo_url ? (
          <Image src={othersInfo?.photo_url} rounded={20} width={114} alignSelf={'flex-start'} />
        ) : (
          <Box
            position="relative"
            _before={{
              content: `""`,
              position: 'absolute',
              width: '100%',
              height: '100%',
              bgColor: bgImage ? 'transparent' : DemoAvatar[address]?.image ? `transparent` : `#${avatarColor}`,
              opacity: '0.5',
              rounded: 20,
            }}
            boxSize={114}
            rounded={20}
            bgImage={bgImage}
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgPosition="center center"
            bgSize="cover"
            bgBlendMode=""
            alignSelf={['flex-end', null, 'flex-start']}
          >
            {/* <Text>#{avatarColor}</Text> */}
          </Box>
        )}

        <Text
          display={['flex', 'none', 'none']}
          fontSize={['xl', null, '2xl']}
          overflow={'hidden'}
          whiteSpace={'nowrap'}
          textOverflow={'ellipsis'}
        >
          {isDemo ? basicConfig.demo.name : projectName}
        </Text>
        <Box
          display={['none', null, 'flex']}
          justifyItems="center"
          flexDir="column"
          flex={1}
          alignSelf={['center', null, 'center']}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent={['space-between', null, 'flex-start']}
            alignItems={'center'}
          >
            <Box display={'flex'} alignItems={'center'} gap={2}>
              {othersInfo?.is_claimed && (
                <Tooltip label="Claimed Profile">
                  <Image src={`/images/icon/claimed.png`} />
                </Tooltip>
              )}
              <Flex flexDir={'column'} pt={5}>
                <Flex>
                  <Text
                    fontSize={['xl', null, '2xl']}
                    overflow={'hidden'}
                    whiteSpace={'nowrap'}
                    textOverflow={'ellipsis'}
                  >
                    {isDemo ? basicConfig.demo.name : projectName}
                  </Text>
                  {!isMe && user?.user_type === 'consumer' ? (
                    <Button
                      bg="linear-gradient(177.37deg, rgba(255, 255, 255, 0.15) 1.99%, rgba(107, 107, 107, 0.15) 105.58%)"
                      justifyContent={'center'}
                      alignItems={'center'}
                      ml={2}
                      gap={2}
                      p={4}
                      rounded={'md'}
                      onClick={openMsgBox}
                      cursor={'pointer'}
                    >
                      <Image src={`/images/icon/send.png`} width={6} h={6} />
                      <Text color={'white'} fontWeight={'bold'} fontSize={18}>
                        {isBuy ? 'Buy Spot' : 'SEND'}
                      </Text>
                    </Button>
                  ) : (
                    <></>
                  )}
                  {/* NOTE: Hunting Button */}
                  {!isMe && !hideFollow ? (
                    <Button
                      display="flex"
                      alignItems="center"
                      fontSize="md"
                      fontWeight="bold"
                      ml="4"
                      py={3}
                      px={6}
                      h={10}
                      minW={20}
                      rounded={'full'}
                      bgColor={'#6235D0'}
                      bg={'#6235D0'}
                      color="#ffffff"
                      onClick={() => onFollowClick(address)}
                    >
                      <Text as="span" color="white" fontSize={'18'}>
                        {isHunted && myAddress ? 'UnFollow' : 'Follow'}
                      </Text>
                    </Button>
                  ) : (
                    <></>
                  )}
                  {showClaim ? (
                    <>
                      {' '}
                      <Button
                        display="flex"
                        alignItems="center"
                        fontSize="md"
                        fontWeight="bold"
                        ml="4"
                        rounded={6}
                        bg={'#6235D0'}
                        color="#fff"
                        onClick={claimReward}
                        px={4}
                      >
                        <Text textAlign={'center'}>Claim {inbox?.length}</Text>
                        <Image src={'/images/icon/mailanddot.png'} w={8} h={8} mx={2} />
                        <Text textAlign={'center'}> ({consumerPoints?.total_points} BLUAI)</Text>
                      </Button>
                      <Tooltip
                        placement="bottom"
                        label={<Image src="/images/consumer_overlay.png" />}
                        bg="transparent"
                        maxW={558}
                        maxH={326}
                      >
                        <div className="flex items-center mb-3 font-bold text-lg p-2">
                          <Icon as={InfoOutlineIcon} mx={1} color="#9ba2a9" fontSize="xs" />
                        </div>
                      </Tooltip>
                    </>
                  ) : (
                    <></>
                  )}

                  {!inOutReachList && owned_project && hideFollow ? (
                    <Button
                      display="flex"
                      alignItems="center"
                      fontSize="md"
                      fontWeight="bold"
                      ml="4"
                      py={2}
                      px={4}
                      rounded={6}
                      bg={'#6235D0'}
                      color="#fff"
                      gap={2}
                      onClick={addToOutReachList}
                    >
                      <Image src={'/images/icon/edit.png'} />
                      {/* Add Outreach List */}Add
                    </Button>
                  ) : (
                    <></>
                  )}
                  {inOutReachList && owned_project && hideFollow ? (
                    <Button
                      display="flex"
                      alignItems="center"
                      fontSize="md"
                      fontWeight="bold"
                      ml="4"
                      py={2}
                      px={4}
                      rounded={6}
                      bg={'#6235D0'}
                      color="#fff"
                      gap={2}
                    >
                      <Image src={'/images/icon/success-reward.png'} />
                      Added
                    </Button>
                  ) : (
                    <></>
                  )}
                </Flex>
                <Box>
                  {' '}
                  <Text opacity={0.4} display={'inline-block'}>
                    {address}
                  </Text>
                  <Image
                    className="cursor-pointer hover:bg-purple-600"
                    alt="copy"
                    src="/ic_copy.png"
                    width={4}
                    height={4}
                    display={'inline-block'}
                    onClick={copyCode}
                    opacity={0.4}
                  />
                </Box>
              </Flex>
            </Box>
          </Box>

          <Box
            ref={tagsRef as React.RefObject<HTMLDivElement>}
            className={`mt-2 transition-all ease-linear flex flex-wrap gap-2 overflow-hidden max-h-[1000px] `}
            h={['auto', null, isShowMoreTags ? 'auto' : 73]}
          >
            {tagIsLoading ? (
              <Tag
                className="inline-block"
                size="sm"
                key={'loading'}
                variant="outline"
                // color="#fff"
                color={`'#939393'`}
                borderRadius="full"
                backgroundColor="#252525"
                fontWeight="bold"
                textColor="#939393"
                px="4"
                py="2"
                alignSelf="flex-start"
              >
                Analyzing...{' '}
              </Tag>
            ) : (
              tags?.map((_tag, index) => (
                <Tag
                  size="sm"
                  key={_tag && index}
                  variant="outline"
                  // color="#fff"
                  color="#FFFFFF"
                  borderRadius="full"
                  border="1px solid  #82FCD3"
                  backgroundColor=""
                  fontWeight="bold"
                  textColor="#FFFFFF"
                  px="4"
                  py="2"
                  alignSelf="flex-start"
                >
                  {_tag.toUpperCase()}
                </Tag>
              ))
            )}
          </Box>
          {isMoreTagsBtn && (
            <div className="flex items-center pt-[10px]">
              <div className="flex-1 h-[1px] border-t-[1px] border-dashed border-opacity-50"></div>
              <div
                className="flex items-center pl-2 text-xs font-bold opacity-50 cursor-pointer"
                onClick={() => setIsShowMoreTags((prev) => !prev)}
              >
                {isShowMoreTags ? 'See Less' : 'See More'}
                <Image
                  className={`transition-all ease-linear opacity-50 ${isShowMoreTags ? 'rotate-0' : 'rotate-180'} ml-1`}
                  alt="is show more tags button"
                  src="/ic-more-tags.svg"
                  width={4}
                ></Image>
              </div>
            </div>
          )}
        </Box>
        <Box w={['180px', null, '200px']}>
          {/* @ts-ignore */}
          <ChartCredit params={credits.data} status={credits.status} isDemo={isDemo} />
        </Box>
      </Flex>

      {/* phone display */}
      <Box
        display={['flex', null, 'none']}
        justifyItems="center"
        flex={1}
        justifyContent={['flex-start', null, 'flex-start']}
        flexDir={'column'}
        gap={4}
      >
        <Box>
          {' '}
          <Text opacity={0.4} display={'inline-block'}>
            {address}
          </Text>
          <Image
            className="cursor-pointer hover:bg-purple-600"
            alt="copy"
            src="/ic_copy.png"
            width={4}
            height={4}
            display={'inline-block'}
            onClick={copyCode}
            opacity={0.4}
          />
        </Box>
        <Flex flexDir={'row'}>
          {/* NOTE: Hunting Button */}
          {!isMe && user?.user_type === 'consumer' ? (
            <Button
              bg="linear-gradient(177.37deg, rgba(255, 255, 255, 0.15) 1.99%, rgba(107, 107, 107, 0.15) 105.58%)"
              justifyContent={'center'}
              alignItems={'center'}
              ml={2}
              gap={2}
              p={4}
              rounded={'md'}
              onClick={openMsgBox}
              cursor={'pointer'}
            >
              <Image src={`/images/icon/send.png`} width={6} h={6} />
              <Text color={'white'} fontWeight={'bold'} fontSize={18}>
                {isBuy ? 'Buy Spot' : 'SEND'}
              </Text>
            </Button>
          ) : (
            <></>
          )}
          {/* NOTE: Hunting Button */}
          {!isMe && !hideFollow ? (
            <Button
              display="flex"
              alignItems="center"
              fontSize="md"
              fontWeight="bold"
              ml="4"
              py={3}
              px={6}
              h={10}
              minW={20}
              rounded={'full'}
              bgColor={'#6235D0'}
              bg={'#6235D0'}
              color="#ffffff"
              onClick={() => onFollowClick(address)}
            >
              <Text as="span" color="white" fontSize={'18'}>
                {isHunted && myAddress ? 'UnFollow' : 'Follow'}
              </Text>
            </Button>
          ) : (
            <></>
          )}
          {showClaim ? (
            <>
              {' '}
              <Button
                display="flex"
                alignItems="center"
                fontSize="md"
                fontWeight="bold"
                ml="4"
                rounded={6}
                bg={'#6235D0'}
                color="#fff"
                onClick={claimReward}
                px={4}
              >
                <Text textAlign={'center'}>Claim {inbox?.length}</Text>
                <Image src={'/images/icon/mailanddot.png'} w={8} h={8} mx={2} />
                <Text textAlign={'center'}> ({consumerPoints?.total_points} BLUAI)</Text>
              </Button>
              <Tooltip
                placement="bottom"
                label={<Image src="/images/consumer_overlay.png" />}
                bg="transparent"
                maxW={558}
                maxH={326}
              >
                <div className="flex items-center mb-3 font-bold text-lg p-2">
                  <Icon as={InfoOutlineIcon} mx={1} color="#9ba2a9" fontSize="xs" />
                </div>
              </Tooltip>
            </>
          ) : (
            <></>
          )}

          {!inOutReachList && owned_project && hideFollow ? (
            <Button
              display="flex"
              alignItems="center"
              fontSize="md"
              fontWeight="bold"
              ml="4"
              py={2}
              px={4}
              rounded={6}
              bg={'#6235D0'}
              color="#fff"
              gap={2}
              onClick={addToOutReachList}
            >
              <Image src={'/images/icon/edit.png'} />
              {/* Add Outreach List */}Add
            </Button>
          ) : (
            <></>
          )}
          {inOutReachList && owned_project && hideFollow ? (
            <Button
              display="flex"
              alignItems="center"
              fontSize="md"
              fontWeight="bold"
              ml="4"
              py={2}
              px={4}
              rounded={6}
              bg={'#6235D0'}
              color="#fff"
              gap={2}
            >
              <Image src={'/images/icon/success-reward.png'} />
              Added
            </Button>
          ) : (
            <></>
          )}
        </Flex>
      </Box>
      <Box display={['flex', null, 'none']} my={6}>
        <Box
          ref={tagsRef as React.RefObject<HTMLDivElement>}
          className={`mt-2 transition-all ease-linear flex flex-wrap gap-2 overflow-hidden max-h-[1000px] `}
          h={['auto', null, isShowMoreTags ? 'auto' : 73]}
        >
          {tagIsLoading ? (
            <Tag
              className="inline-block"
              size="sm"
              key={'loading'}
              variant="outline"
              // color="#fff"
              color={`'#939393'`}
              borderRadius="full"
              backgroundColor="#252525"
              fontWeight="bold"
              textColor="#939393"
              px="4"
              py="2"
              alignSelf="flex-start"
            >
              Analyzing...{' '}
            </Tag>
          ) : (
            tags?.map((_tag, index) => (
              <Tag
                size="sm"
                key={_tag && index}
                variant="outline"
                // color="#fff"
                color="#FFFFFF"
                borderRadius="full"
                border="1px solid  #82FCD3"
                backgroundColor=""
                fontWeight="bold"
                textColor="#FFFFFF"
                px="4"
                py="2"
                alignSelf="flex-start"
              >
                {_tag.toUpperCase()}
              </Tag>
            ))
          )}
        </Box>
        {isMoreTagsBtn && (
          <div className="flex items-center pt-[10px]">
            <div className="flex-1 h-[1px] border-t-[1px] border-dashed border-opacity-50"></div>
            <div
              className="flex items-center pl-2 text-xs font-bold opacity-50 cursor-pointer"
              onClick={() => setIsShowMoreTags((prev) => !prev)}
            >
              {isShowMoreTags ? 'See Less' : 'See More'}
              <Image
                className={`transition-all ease-linear opacity-50 ${isShowMoreTags ? 'rotate-0' : 'rotate-180'} ml-1`}
                alt="is show more tags button"
                src="/ic-more-tags.svg"
                width={'14px'}
              ></Image>
            </div>
          </div>
        )}
      </Box>
      {isOpen && (
        <MsgPop
          isOpen={isOpen}
          onClose={onClose}
          isDemo={isDemo}
          targetAddress={address}
          myAddress={myAddress}
          targetImage={'/images/demo-avatar.png'}
        />
      )}

      {isBuyOpen && (
        <ConsumerBuySpot
          showMessage={isBuyOpen}
          onShowMessageClose={onBuyClose}
          onShowMessageOpen={onBuyOpen}
          targetUser={targetUser}
          sendEmptyMessage={sendEmptyMessage}
        />
      )}
    </Box>
  );
}

export default PlatformUser;
