import { EvmChain } from 'moralis/common-evm-utils';
import { TokenType, WRAPPEDTOKEN } from './wrappedToken';

export type Chain = {
  key: CHAIN;
  chain: EvmChain;
  bluApiChain: BLUWHALE_CHAIN;
  wrappedToken: TokenType;
  icon: string;
  title: string;
};

export enum CHAIN {
  ETHEREUM = 'ETHEREUM',
  BSC = 'BSC',
  FANTOM = 'FANTOM',
  AVALANCHE = 'AVALANCHE',
  POLYGON = 'POLYGON',
  ARBITRUM = 'ARBITRUM',
}
/**
 *   | 'ethereum'
  | 'binance-smart-chain'
  | 'polygon-pos'
  | 'fantom'
  | 'arbitrum-one'
  | 'avalanche';
 */
export enum BLUWHALE_CHAIN {
  ETHEREUM = 'ethereum',
  BSC = 'binance-smart-chain',
  POLYGON = 'polygon-pos',
  FANTOM = 'fantom',
  ARBITRUM = 'arbitrum-one',
  AVALANCHE = 'avalanche',
}

const BSC: Chain = {
  key: CHAIN.BSC,
  title: 'BNB',
  chain: EvmChain.BSC,
  bluApiChain: BLUWHALE_CHAIN.BSC,
  wrappedToken: WRAPPEDTOKEN.BSC,
  icon: '/images/chain-bsc.svg',
};
const ETHEREUM: Chain = {
  key: CHAIN.ETHEREUM,
  title: 'Ethereum',
  chain: EvmChain.ETHEREUM,
  bluApiChain: BLUWHALE_CHAIN.ETHEREUM,
  wrappedToken: WRAPPEDTOKEN.ETHEREUM,
  icon: '/images/chain-eth.svg',
};
const FANTOM: Chain = {
  key: CHAIN.FANTOM,
  title: 'Fantom',
  chain: EvmChain.FANTOM,
  bluApiChain: BLUWHALE_CHAIN.FANTOM,
  wrappedToken: WRAPPEDTOKEN.FANTOM,
  icon: '/images/chain-fantom.svg',
};
const AVALANCHE: Chain = {
  key: CHAIN.AVALANCHE,
  title: 'Avalanche',
  chain: EvmChain.AVALANCHE,
  bluApiChain: BLUWHALE_CHAIN.AVALANCHE,
  wrappedToken: WRAPPEDTOKEN.AVALANCHE,
  icon: '/images/chain-avalanche.svg',
};
const POLYGON: Chain = {
  key: CHAIN.POLYGON,
  title: 'Polygon',
  chain: EvmChain.POLYGON,
  bluApiChain: BLUWHALE_CHAIN.POLYGON,
  wrappedToken: WRAPPEDTOKEN.POLYGON,
  icon: '/images/chain-polygon.svg',
};
const ARBITRUM: Chain = {
  key: CHAIN.ARBITRUM,
  title: 'Arbitrum',
  chain: EvmChain.ARBITRUM,
  bluApiChain: BLUWHALE_CHAIN.ARBITRUM,
  wrappedToken: WRAPPEDTOKEN.ARBITRUM,
  icon: '/images/chain-arbitrum.svg',
};
const supportChains: { [key in CHAIN]: Chain } = {
  [CHAIN.ETHEREUM]: ETHEREUM,
  [CHAIN.BSC]: BSC,
  [CHAIN.FANTOM]: FANTOM,
  [CHAIN.AVALANCHE]: AVALANCHE,
  [CHAIN.POLYGON]: POLYGON,
  [CHAIN.ARBITRUM]: ARBITRUM,
};

// const chainList: Chain[] = [BSC, ETHEREUM];
const chainList: Chain[] = [BSC, AVALANCHE, ETHEREUM, FANTOM, POLYGON, ARBITRUM];
export { chainList, supportChains };
