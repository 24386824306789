import { useEffect, useRef } from 'react';
import { useGetNativeBalance } from './useGetNativeBalance';
import { useGetNftData } from './useGetNftData';
import { useGetTagsData } from './useGetTagsData';
import { useGetTokenBalancesN } from './useGetTokenBalancesN';
import { useGetTokensInfo } from './useGetTokensInfo';
import { useGetTransactions } from './useGetTransactions';
import { useGetWalletList } from './useGetWalletList';
import { useDashBoard } from '../contexts/DashBoardContext';
import { chainList } from '../configs';
import { supportProtocols } from '../configs/protocol';
import useGetProtocolData from './useGetProtocolData';
import { useQuery } from 'react-query';
import { Status } from '../interfaces/types';
import { useGetAttributesData } from './useGetAttributesData';
import useGetProtocolDataV2 from './useGetProtocolDataV2';

export type useGetAggregateParams = {
  address: string;
  preStatus: Status;
};
export default function useGetAggregate({ address, preStatus = 'success' }: useGetAggregateParams) {
  /**
   * Default Token
   * Native
   * Tokens
   * NFT
   * Transactions
   * Protocol
   * Tag
   * Wallet
   */
  const isPnl = process.env.NEXT_PUBLIC_ISPNL;

  const { tokensInfoRef } = useDashBoard();
  const initRef = useRef<boolean>(false);

  const { onExcute: tokenInfoExcute } = useGetTokensInfo();
  const {
    onExcute: nativeExcute,
    status: nativeStatus,
    results: nativeDto,
    onInit: nativeInit,
  } = useGetNativeBalance();
  const {
    onExcute: tokenExcute,
    status: tokenStatus,
    results: tokenDto,
    onExcuteGetFlipper,
  } = useGetTokenBalancesN({});

  const {
    onExcuteCheck: nftExcuteCheck,
    results: nftDto,
    statusMeta: nftMetaStatus,
    statusPrice: nftPriceStatus,
    onExcuteOnlyMetaData,
    onExcuteFetchNftsPrice,
    onExcuteFetchTradesByDate,
    onInit: nftInit,
  } = useGetNftData({ address });
  const { onExcute: tsxExcute, status: tsxStatus, transactions: tsxDto } = useGetTransactions({});
  // const { data: tagsDto, status: tagsStatus } = useGetTagsData({ address });
  const { data: attributesDto, status: attributesStatus } = useGetAttributesData({ address });
  const { data: walletsDto, status: walletsStatus } = useGetWalletList({ address });
  // const { datas: protocolDto, status: protocolStatus } = useGetProtocolData({
  //   address,
  //   protocols: supportProtocols,
  // });
  const { data: protocolDtoV2, status: protocolStatusV2 } = useGetProtocolDataV2({
    address,
  });

  const queryTokenInfo = useQuery({
    // enabled: true,
    enabled: preStatus === 'success',
    queryKey: ['queryTokenInfo', address],
    queryFn: async () => tokenInfoExcute(),
  });
  tokensInfoRef.current = queryTokenInfo.data;
  const queryNative = useQuery({
    enabled: queryTokenInfo.status === 'success',
    queryKey: ['queryNative', address],
    queryFn: async () => nativeExcute(address),
  });
  const queryToken = useQuery({
    enabled: queryTokenInfo.status === 'success',
    queryKey: ['queryToken', address],
    queryFn: async () => tokenExcute(address),
  });
  const queryTsx = useQuery({
    // enabled: queryNftTrades.status === 'success',
    // enabled: queryToken.status === 'success',
    enabled: queryTokenInfo.status === 'success',
    queryKey: ['queryTsx', address],
    queryFn: async () => tsxExcute(address),
  });
  const queryNftMeta = useQuery({
    // enabled: queryToken.status === 'success' && queryNative.status === 'success',
    enabled: queryTsx.status === 'success' || queryTsx.status === 'error',
    queryKey: ['queryNftMeta', address],
    queryFn: async () => onExcuteOnlyMetaData(address),
  });
  const queryNftTrades = useQuery({
    enabled: queryNftMeta.status === 'success',
    queryKey: ['queryNftTradesByDate', address],
    queryFn: async () => onExcuteFetchTradesByDate(address, 1),
  });

  const queryNftHasTrade = useQuery({
    // enabled: queryTsx.status === 'success',
    enabled: queryNftTrades.status === 'success',
    queryKey: ['queryNftHasTrade', address],
    queryFn: async () => nftExcuteCheck(address, chainList),
  });
  const queryNetWorth = useQuery({
    enabled: isPnl !== 'true' && queryNftHasTrade.status === 'success',
    queryKey: ['queryNetWorth', address],
    queryFn: async () => tokenExcute(address, 6),
  });
  const queryNftPrice = useQuery({
    enabled:
      (isPnl !== 'true' && queryNetWorth.status === 'success') ||
      (isPnl === 'true' && queryNftHasTrade.status === 'success'),
    queryKey: ['queryNftPrice', address],
    queryFn: async () => onExcuteFetchNftsPrice(nftDto, tokensInfoRef.current),
  });

  async function excute(_address: string) {
    //******************tokensInfo****************
    const tokensInfoPromise = tokenInfoExcute();
    tokensInfoRef.current = await tokensInfoPromise;
    //******************tokensInfo****************

    //******************Native****************
    const nativeResultPromise = nativeExcute(_address);
    //******************Native****************

    //******************Token****************
    const tokenResultCurrentPromise = tokenExcute(_address);
    //******************Token****************

    const _nativeDto = await nativeResultPromise;
    const _tokenDto = await tokenResultCurrentPromise;

    //******************Nft Meta data****************
    const nftResultPromise = onExcuteOnlyMetaData(address);
    const _nftDto = await nftResultPromise;
    //******************Nft Meta data****************

    //******************Transaction****************
    const _tsxDto = await tsxExcute(_address, undefined, 30);
    //******************Transaction****************

    //******************NFT has trade****************
    const nftResultHasTradePromise = nftExcuteCheck(_address, chainList);
    const _nftHasTradeDto = await nftResultHasTradePromise;
    //******************NFT has trade****************

    if (isPnl !== 'true') {
      //******************History NetWorth Balances****************
      const _historyBalancesDto = await tokenExcute(_address, 6);
      //******************History NetWorth Balances****************
    }

    //******************Nft Price data****************
    const nftPriceResultPromise = onExcuteFetchNftsPrice(_nftDto, tokensInfoRef.current);
    //******************Nft Price data****************
  }

  async function excuteNative(_address: string) {
    //******************Native****************
    const nativeResultPromise = nativeExcute(_address);
    //******************Native****************
  }
  async function excuteToken(_address: string) {
    //******************Token****************
    const tokenResultCurrentPromise = tokenExcute(_address);
    //******************Token****************
  }

  async function excuteNft(_address: string) {
    //******************tokensInfo****************
    const tokensInfoPromise = tokenInfoExcute();
    tokensInfoRef.current = await tokensInfoPromise;
    //******************tokensInfo****************

    //******************Nft Meta data****************
    const nftResultPromise = onExcuteOnlyMetaData(address);
    const _nftDto = await nftResultPromise;
    //******************Nft Meta data****************

    //******************NFT has trade****************
    const nftResultHasTradePromise = nftExcuteCheck(_address, chainList);
    const _nftHasTradeDto = await nftResultHasTradePromise;
    //******************NFT has trade****************

    //******************Nft Price data****************
    const nftPriceResultPromise = onExcuteFetchNftsPrice(_nftDto, tokensInfoRef.current);
    //******************Nft Price data****************
  }
  async function excuteTsx(_address: string) {
    //******************Transaction****************
    const _tsxDto = await tsxExcute(_address, undefined, 30);
    //******************Transaction****************
  }

  useEffect(() => {
    if (!address) {
      return;
    }
    if (!initRef.current) {
      initRef.current = true;
      return;
    }
    console.log('');
    // excute(address);
    // excuteNative(address);
    // excuteToken(address);
    // excuteNft(address);
    // excuteTsx(address);
  }, [address]);

  return {
    nativeDto: queryNative.data,
    tokenDto: queryToken.data,
    nftDto,
    tsxDto: queryTsx.data,

    networthDto: queryNetWorth.data,
    networthStatus: queryNetWorth.status,

    // tagsDto,
    attributesDto,
    walletsDto,
    // protocolDto,

    nativeStatus: queryNative.status,
    tokenStatus: queryToken.status,
    // nftMetaStatus: queryNftMeta.status,
    nftMetaStatus,
    nftPriceStatus: queryNftPrice.status,
    tsxStatus: queryTsx.status,

    // tagsStatus,
    attributesStatus,
    walletsStatus,

    // protocolStatus,

    //protocolDtoV2
    protocolDtoV2,
    protocolStatusV2,
  };
}
